import React, {useEffect, useState, useRef} from "react";
import {Typography, Box, Grid, Dialog} from '@material-ui/core';
import successSvg from 'components/other-components/success.svg';

export default function OverlaySuccess(props) {
    const {overlayVis, setVis} = props;
    return (
        // <Grid onClick={() => setVis(false)} container justify='center' style={{ position: 'fixed', 'display': overlayVis ? 'block' : 'none', 'width': '100%', 'height': '100%', 'top': '0', 'left': '0', 'right': '0', 'bottom': '0', 'zIndex': '200'}}>
        //     <Grid container item style={{position: 'fixed', display: 'block', width:'25%', height:'25%', top:'50%', left:'50%', transform: 'translate(-20%,-50%)'}}>
        //         <Box boxShadow={3} style={{backgroundColor:'white'}}><Grid item xs={12} justify={'center'} alignItems={'center'} style={{textAlign:'center', padding:'3rem', paddingBottom:'0rem'}}>
        //             <img src={successSvg}/>
        //         </Grid>
        //         <Grid item xs={12} justify={'center'} alignItems={'center'} style={{textAlign:'center', padding:'2rem', paddingBottom:'3rem'}}>
        //             <Typography>{props.message}</Typography>
        //         </Grid></Box>
        //     </Grid>
        // </Grid>
        <Dialog open={overlayVis} onClose={() => setVis(false)} classes={{ paper: 'shadow-lg rounded' }}>
            <div className="text-center p-5">
                <img src={successSvg}/>
                <h4 className="font-weight-bold mt-4">{props.message}</h4>
            </div>
        </Dialog>
    );
}