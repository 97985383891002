import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch, connect } from 'react-redux';
import {
    Typography,
    Grid,
    Paper,
    TextField,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    Button
} from '@material-ui/core'
import 'antd/dist/antd.css';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { SyncAlt } from '@material-ui/icons';
import SelectStaff from 'components/old/Forms/Select/SelectStaff';
import axios from "axios";
import { APP_URL } from 'static';

function ProjectDesc() {
    const selectedProject = useSelector((state) => state.project.selectedProject);

    return (
        <Typography className='mb-2 font-size-sm' style={{ color: '#AFA4C8', fontWeight: 500}}>
            {selectedProject.name} - {selectedProject.description}
        </Typography>
    )
}

export default ProjectDesc;
