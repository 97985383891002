import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { InlineStyles, generalSpacing } from 'stylesCss';
import axios from 'axios';
import {
    InputLabel,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    Typography
} from '@material-ui/core';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { makeStyles } from "@material-ui/core/styles";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { column_configs } from 'Page/UI_Design/Dashboard/Highcharts/column_configs';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import { HighchartsComponent } from 'components/other-components/HighchartsComponent';
import CountUp from "react-countup";
import {BackEndRequest, defaultHeaders} from "../../../actions/APICalls";
import {ReportURL} from "../../../actions/Dashboard";

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function getSum(total, num) {
    return total + num;
}

function LabourPlantCountGraph(props) {
    const { from, to, selectedActivity } = props;
    const [labourPlantChart, setLabourPlantChart] = useState();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [selectValue, setSelectOption] = useState('Labour')
    // const selectedDay = useSelector((state) => state.project.selectedDay);
    const [mcTotal, setMCTotal] = useState(0);
    const [scTotal, setSCTotal] = useState(0);
    const borderSelectClasses = useBorderSelectStyles();

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };

    useEffect(() => {
        if(selectedProject.id) {
        let part_url;
        let countSum;
        if (selectValue == 'Labour') {
            part_url = 'labour_data';
            countSum = 'no';
        }
        else {
            part_url = 'plant_data';
            countSum = 'working_no';
        }
        let url = '/site/' + part_url + '/?date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-') + '&activity__project=' + selectedProject.id;
        if (selectedActivity) {
            url += '&activity=' + selectedActivity;
        }
        BackEndRequest('post', url, defaultHeaders, {groupValue: 'mc_sc__name', countSum: countSum}).then((resp) => {
        //axios.post(url, { groupValue: 'mc_sc__name', countSum: countSum }).then((resp) => {
            // axios.get(APP_URL + '/site/activity_summary/?date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-')).then((resp) => {
            if (resp.data.length > 0) {
                const categories = resp.data.map(function (x) { return x.mc_sc__name })
                const data = resp.data.map(function (x) { return { 'y': x[countSum + '__sum'], 'color': (selectValue == 'Labour' ? Highcharts.color('#24CBE5').brighten((Math.random() * 10 - 6) / 7).get() : Highcharts.color('#DAD0EC').brighten((Math.random() * 10 - 8) / 12).get()) } })
                const series = [{ name: selectValue, data: data }]
                let labourPlantConfig = column_configs(categories, series, '', '', ''); //Labour Plants Count, Date, Count
                labourPlantConfig.chart.height = 250
                setLabourPlantChart(labourPlantConfig);
                let mc_url = '/site/' + part_url + '/?mc_sc__short_code=MC&date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-') + '&activity__project=' + selectedProject.id
                if (selectedActivity) {
                    mc_url += '&activity=' + selectedActivity;
                }
                BackEndRequest('post', mc_url, defaultHeaders, {
                    groupValue: 'mc_sc__name',
                    countSum: countSum
                }).then((mc_resp) => {
                //axios.post(mc_url, { groupValue: 'mc_sc__name', countSum: countSum }).then((mc_resp) => {
                    let totalMCLabour = 0;
                    if (mc_resp.data.length > 0) {
                        totalMCLabour = (mc_resp.data.map(function (x) { return x[countSum + '__sum'] }).reduce(getSum));
                    }
                    const totalLabour = (resp.data.map(function (x) { return x[countSum + '__sum'] }).reduce(getSum));
                    setMCTotal(totalMCLabour)
                    setSCTotal(totalLabour - totalMCLabour)
                })
            } else {
                let labourPlantConfig = column_configs([], [], '', '', ''); //Labour Plants Count, Date, Count
                labourPlantConfig.chart.height = 250
                setLabourPlantChart(labourPlantConfig);
                setMCTotal(0)
                setSCTotal(0)
            }
        });
}
    }, [from, to, selectedProject, selectValue, selectedActivity]);

    return (
        <Card className='card-box w-100 border-0'>
            <div className='card-header border-0'>
                <div className="card-header--title">
                    <Typography className='pl-1 pt-1' color='primary' style={{fontWeight: 500}}>Contractors Summary</Typography>
                </div>
                <div className='card-header--actions'>
                    <FormControl size={'small'} variant={'outlined'}>
                        <Select
                            IconComponent={iconComponent}
                            className='text-left ml-3'
                            style={{ minWidth: '120px' }}
                            labelId={'SelectOpt'}
                            onChange={(e) => setSelectOption(e.target.value)}
                            value={selectValue}
                            disableUnderline
                        >
                            <MenuItem
                                key={'labour_opt'}
                                value='Labour'>Labour
                                </MenuItem>
                            <MenuItem
                                key={'plant_opt'}
                                value='Plant'>Plant
                                </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Grid container justify='center' alignItems='center' className='my-2'>
                <Grid item xs={6} className='text-center'>
                    <Typography className='' variant="h3" style={{ fontWeight: 600 }}><CountUp start={0} end={mcTotal} duration={2} /></Typography>
                    <Typography>Contractor</Typography>
                </Grid>
                <Grid item xs={6} className='text-center'>
                    <Typography className='' color='primary' variant="h3" style={{ fontWeight: 600 }}><CountUp start={0} end={scTotal} duration={2} /></Typography>
                    <Typography>Sub Contractor</Typography>
                </Grid>
            </Grid>
            <div className='p-3'>
                <HighchartsReact highcharts={Highcharts} options={labourPlantChart} />
            </div>
        </Card >
    );
}
export default LabourPlantCountGraph;
