import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import {
    Button,
    Grid,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box, Tabs, Tab
} from '@material-ui/core';

import LocationTable from 'Page/UI_Design/Presets/LocationTable';
import SubLocationTable from 'Page/UI_Design/Presets/SubLocationTable';
import { TabContext } from "@material-ui/lab";
import ReportViewPage1 from "../DailyReport/ReportViewPage1";
import ReportViewPage2 from "../DailyReport/ReportViewPage2";
import ActivityCategoryTable from 'Page/UI_Design/Presets/ActivityCategoryTable';
import LabourTradeTable from 'Page/UI_Design/Presets/LabourTradeTable';
import TabPanel from 'components/other-components/TabPanel';
import PlantTypeTable from "./PlantTypeTable";
import MaterialDescTable from "./MaterialDescTable";
import CompanyTable from "./CompanyTable";
import PositionTable from "./PositionTable";
import SubTradeTable from "./SubTradeTable";
import {loadAllPresets} from "actions/Presets";
import {makeStyles} from "@material-ui/core/styles";
import {APP_URL} from 'static.js';

const activeTabsColor = '#15B1D7';
const useStyles = makeStyles(theme => ({
    //   title: theme.styles.font.primary,
    //   frame: theme.styles.page.frame,
    //   content: theme.styles.page.content,
}))

function Index() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0)
    const locations = useSelector((state) => state.presets.locations);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const classes = useStyles();

    function changeTab(event, newValue) {
        setPage(newValue);
    }
    useEffect(() => {
        // dispatch(getAllPresets(selectedProject.id));
    }, [selectedProject]);

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <span className='font-size-lg font-weight-bold'>Presets</span>
            </Grid>
            <Grid item xs={12}>
                <TabContext value={'undefined'}>
                    <Tabs
                        value={page}
                        onChange={changeTab}
                        style={{ marginBottom: '1.5rem', marginTop: '-1.5rem' }}
                        indicatorColor='primary'
                        textColor='primary'
                    >
                        <Tab label="Location" style={{ minWidth: '80px' }} />
                        <Tab label="Activities" style={{ minWidth: '80px' }} />
                        <Tab label="Labour" style={{ minWidth: '80px' }} />
                        <Tab label="Plant" style={{ minWidth: '80px' }} />
                        <Tab label="Materials" style={{ minWidth: '80px' }} />
                        <Tab label="BK/SC" style={{ minWidth: '80px' }} />
                        <Tab label="Position" style={{minWidth:'80px'}}/>
                    </Tabs>
                    <TabPanel value={page} index={0}>
                        <Grid container direction='row' spacing={3}>
                            <Grid item xs={4}>
                                <LocationTable />
                            </Grid>
                            <Grid item xs={8}>
                                <SubLocationTable />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={page} index={1}>
                        <ActivityCategoryTable />
                    </TabPanel>
                    <TabPanel value={page} index={2}>
                        <Grid container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <LabourTradeTable />
                        </Grid>
                        <Grid item xs={8}>
                            <SubTradeTable />
                          </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={page} index={3}>
                        <Grid item xs={4}>
                            <PlantTypeTable />
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={page} index={4}>
                        <MaterialDescTable />
                    </TabPanel>
                    <TabPanel value={page} index={5}>
                        <CompanyTable />
                    </TabPanel>
                    <TabPanel value={page} index={6}>
                      <PositionTable />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>

    );
}

export default Index;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
