
function jsonBase(start_position, table_area, data) {
  return {
    'sheet_name': '3',
    'insert': {
      'start_position': start_position,
      'table_area': table_area,
      'data': data,
    },
  }
}

export function SiteSheet3Template(ActivityValues) {
  const startRow = 14;

  let maxRows;
  let i;
  let parsedValues = [];
  let dataRow = startRow;
  // let rowsPerSection = []
  let config = []
  for (i = 0; i < ActivityValues.length; i++) {
    maxRows = Math.max(1, ActivityValues[i].combined_section.length); //minimal 1 row
    let dataEndRow = dataRow + maxRows - 1; //for merging between rows
    // config.push(insertActivityValues(ActivityValues[i], dataRow, dataEndRow));
    config = config.concat(insertOtherValues(ActivityValues[i], ActivityValues[i].combined_section, dataRow, dataEndRow));
    dataRow += maxRows;
  }
  return config;
}

function mergeActivityCells(sR, eR, i) {
  let startRow = sR.toString();
  let endRow = eR.toString();
  let mergeConfig = []
  if (i == 0){
    mergeConfig = mergeConfig.concat([
      {'merge':'A'+startRow+':B'+endRow},
      {'merge':'C' + startRow+':D'+endRow},
      {'merge':'E' + startRow+':H'+startRow},
      {'merge':'I' + startRow+':J'+startRow}
    ])
  }else{
    mergeConfig = mergeConfig.concat([
      {'merge':'E' + startRow+':H'+startRow},
      {'merge':'I' + startRow+':J'+startRow}
    ])
  }
  return {'sheet_name':'3', 'mergeConfig':mergeConfig, 'sheet3borderConfig':{'row': parseInt(endRow)+1}}
}

function mergeOtherCells(row) {
  let startRow = row.toString();
  return [{'merge': 'K' + startRow + ':L' + startRow}]
  // K-L (Labour 'trade' cells)
}

function addTopBorderLine(endRow) {
  //add to top border in the next row
  return {'row': endRow+1}
}
//
// function insertActivityValues(data, startRow, dataEndRow) {
//   let rowData = [data.location, null, data.sub_location, null, data.activity_remarks, null, null, null, data.time_period]
//   let activity_insert_config = jsonBase(['A',startRow], [10,1], [rowData])
//   activity_insert_config.mergeConfig = mergeActivityCells(startRow, dataEndRow);
//   activity_insert_config.sheet3borderConfig = addTopBorderLine(dataEndRow);
//   return activity_insert_config
// }


function insertOtherValues(full_data, data, startRow, dataEndRow) {
  let configs = [];
  let rowData = [];
  // console.log(full_data);
  for (let i = 0; i < data.length; i++) {
    configs.push(mergeActivityCells(startRow + i, dataEndRow, i))
    if (data[i].details.activity_category) {
      if (i == 0) {
        rowData = [full_data.location.name, null, full_data.sub_location ? full_data.sub_location.name : '', null, (i+1)+'. '+data[i].details.activity_category.name + ':' + data[i].details.activity_remarks, null, null, null, full_data.time_start + '-' + full_data.time_end]
      } else {
        rowData = [null, null, null, null, (i+1)+'. '+data[i].details.activity_category.name + ':' + data[i].details.activity_remarks, null, null, null, null]
      }
      let activity_insert_config = jsonBase(['A', startRow + i], [10, 1], [rowData])
      // activity_insert_config.sheet3borderConfig = addTopBorderLine(dataEndRow);
      configs.push(activity_insert_config);
    }
    if (data[i].labours.trade) {
      rowData = [data[i].labours.trade.name, null, data[i].labours.no, data[i].labours.mc_sc.name]
      let labour_config = jsonBase(['K', startRow + i], [4, 1], [rowData])
      labour_config.mergeConfig = mergeOtherCells(startRow + i);
      configs.push(labour_config);
    }
    if (data[i].plants.plant) {
      rowData = [data[i].plants.plant.type, data[i].plants.unit_id, data[i].plants.working_no, data[i].plants.idling_no, data[i].plants.idling_code, data[i].plants.mc_sc.name]
      let plant_config = jsonBase(['O', startRow + i], [6, 1], [rowData])
      configs.push(plant_config);
    }
    if (data[i].materials.material) {
      rowData = [data[i].materials.material.description, data[i].materials.quantity, data[i].materials.mc_sc.name]
      let material_config = jsonBase(['U', startRow + i], [3, 1], [rowData])
      configs.push(material_config);
    }
  }
  return configs
}

  // function insertPlantValues(labourData startRow, EndRow):
  //   return 0
  //
  // function insertMaterialValues(labourData startRow, EndRow):
  //   return 0

//   return [
//     {
//     'sheet_name': '3',
//     'insert': {
//         'start_position': ['K',14],
//         'table_area': [12,40],
//         'data': arrayValues,
//      },
//     },
//   ]
