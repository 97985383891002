import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { APP_URL } from 'static';
import MaterialTableBase from 'Page/UI_Design/Presets/AdminMaterialTableCRUDBase';
import { CustomFilter, CustomToolBar } from "../../../components/other-components/MaterialTable/Components";
import { Box } from "@material-ui/core";
import { WrapperBox } from "../../../components/layout-components/Content";
import {getPresetField} from "../../../actions/Presets";

function ActivityCategoryTable() {
    const [activityCategories, setList] = useState();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [isLoading, setLoad] = useState(true);

  useEffect(() => {
    getPresetField(selectedProject.id, 'activity_categories')
    .then((resp) => setList(resp.data.activity_categories));
    setLoad(false)
  }, [selectedProject]);

    function beforeAdd(newData) {
        newData.project = selectedProject.id;
        return newData
    }

    return (
        <MaterialTableBase
            titleText='List of Activity Category'
            data={activityCategories}
            setList={setList}
            crudURL={APP_URL + '/site/presets/activity_category/'}
            beforeAdd={beforeAdd}
            beforeUpdate={(a) => a}
            CustomLoading={isLoading}
            allowFilter={false}
            columns={[
                { title: 'Name', field: 'name' },
                { title: 'Department',
                  field: 'division',
                  lookup: {'Archi':'Archi', 'BS':'BS'}
                },
            ]}
            components={{
                Container: props => <WrapperBox>{props.children}</WrapperBox>,
                FilterRow: props => <CustomFilter {...props} />,
                Header: props => (null),
                Toolbar: props => (
                    <CustomToolBar {...props} />
                ),
            }}
        />
    );
}

export default ActivityCategoryTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
