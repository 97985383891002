  // axios.defaults.headers.common['Authorization'] = 'Token '+JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || null) || null;
  // axios.interceptors.response.use(request => {
  //   // Edit request config
  //   return request;
  // }, error => {
  //   if (error.response ? error.response.status == 401: false){
  //     dispatch({'type':'LOGGED_IN', 'payload':false})
  //     window.location.reload();
  //   }
  //   console.log(error);
  //   return Promise.reject(error);
  //


  import axios from "axios";
  import {BackEndRequest, defaultHeaders} from "./APICalls";
  import {APP_URL} from "../static";
  import {useEffect} from "react";

  // export function LoginRequest (request_type, url_path, config, payload) {
  //   const loginData = {
  //       email: formData.login.values.email,
  //       password: formData.login.values.password
  //   }
  //   const resp_data = BackEndRequest('post', '/site/auth/token/login', defaultHeaders, loginData)
  //   dispatch({'type': 'LOGIN', 'payload':resp_data});
  //   dispatch({'type':'SELECTPROJECT','payload':{}});
  //   window.location.reload(false);
  // }

  export function Logout(dispatch){
    dispatch({'type':'LOGOUT'});
    dispatch({'type':'SELECTPROJECT','payload':{}});
    window.location.reload(false);
  }

  export function LoginRequest(dispatch, login_data){
    axios.post(APP_URL+'/site/auth/token/login', login_data, {headers: {'Authorization': ``}}).then((resp)=>{
      // dispatch({'type': 'LOGIN', 'payload':resp.data.auth_token});
      localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(resp.data.auth_token));
      localStorage.setItem('LOGGED_IN', JSON.stringify(true));
      localStorage.removeItem('PROJECT');
      dispatch({'type':'SELECTPROJECT','payload':{}});
      window.location.reload(false);
    }).catch((error)=>{
      if (error.response){
        dispatch({'type': 'ERRORMESSAGE', 'payload':error.response.data.non_field_errors[0]})
        console.log(error.response.data.non_field_errors[0]);
      }else{
        dispatch({'type': 'ERRORMESSAGE', 'payload':'Server Error'})
        console.log(error.response)
      }

    })
  }

  export async function LoadUserDetails(dispatch){
    const resp = await BackEndRequest('get','/site/user/', defaultHeaders, {})
    dispatch({'type': 'USER_DETAILS', 'payload': resp.data.full_name})
  }

  export async function GetProjectDetails(){
    const resp = await BackEndRequest('get','/site/project/', defaultHeaders, {})
    return resp
  }