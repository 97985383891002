import { InlineStyles } from 'stylesCss';
import { colorSet } from 'Page/UI_Design/Dashboard/Highcharts/colors';

export function pie_options(data, title, subject) {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 300
        },
        title: {
            text: title,
            align: 'left',
            x: 14,
            y: 28,
            style: {
                'color': InlineStyles.materialTables.title.color,
                'fontSize': InlineStyles.materialTables.title.fontSize,
                'fontWeight': InlineStyles.materialTables.title.fontWeight,
                'fontFamily': 'Roboto',
            }
        },
        tooltip: {
            /* pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>' */
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                colors: colorSet,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    distance: 10,
                    format: '<b>{point.name}</b> <span style="color:#15B1D7">({point.percentage:.1f}%)</span>',
                    // filter: {
                    //     property: 'percentage',
                    //     operator: '>',
                    //     value: 2
                    // },
                }
            }
        },
        series: [{
            innerSize: '50%',
            name: subject,
            colorByPoint: true,
            data: data
        }]
    }

}
