import React, { useEffect, useState } from "react";
import { Field, FieldArray, reduxForm } from 'redux-form';
import {
    IconButton,
    Button,
    Grid,
    Paper,
    TextField,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    Divider
} from '@material-ui/core'
import 'antd/dist/antd.css';

import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxRequired } from "components/form-components/validation";

import { ReduxNormalSelectField } from "../../../../components/form-components/form-field/ReduxNormalSelectField";
import { useSelector, useDispatch } from "react-redux";
import Add from '@material-ui/icons/Add';
import { isEmpty } from "../../js_utils/general";
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import { makeStyles } from "@material-ui/core/styles";
import ImageUpload3 from "../../../../components/other-components/ImageUpload3";
import { ReduxTimeField } from "components/form-components/form-field/ReduxTimeField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DatePicker, MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GeneralAddMore } from "components/other-components/Icons";
import {number, maxLength} from 'components/form-components/validation/fields';
import {ReduxAutoCompleteFieldByValue} from "../../../../components/form-components/form-field/ReduxAutoCompleteFieldByValue";
const required = value => value ? undefined : 'Required'
const useStyles = makeStyles(theme => ({
    // add: theme.styles.miniAddMore.add,
    // addButton: theme.styles.miniAddMore.addButton
}))

function renderImageComponent({ input, ...rest }) {
    return (
        <ImageUpload3 reduxChange={input.onChange} value={input.value}/>
    );
};

const maxLength100 = maxLength(100)

function ActivityFormDetails({ fields }) {
    const dispatch = useDispatch();
    const activityCategories = useSelector((state) => state.presets.activityCategories);
    const companies = useSelector((state) => state.presets.companies);
    const copyActivity = useSelector((state) => state.project.copyActivity);
    // const [imageStore, storeImawge] = useState([])
    const classes = useStyles();

    // Copy History Activity (Redux)
    useEffect(() => {
        if (!isEmpty(copyActivity)) {
            fields.push({
                activity_category: copyActivity.activity_category ? copyActivity.activity_category.id : null,
                activity_remarks: copyActivity.activity_remarks,
            });
            dispatch({ type: 'COPYACTIVITY', payload: {} });
            // renderStaffList()
        }
    }, [copyActivity]);
    // Copy History Activity

    return (
        <Grid item xs={9} container spacing={4}>
            {fields.map((activity, index) =>
                <Grid item xs={12} container direction='row' spacing={3} key={'activityRow' + index}>
                    <Grid item xs={10} lg={10} className=''>
                        <ReduxAutoCompleteFieldByValue
                            getOptionLabel={(option) => (option.name)}
                            multiple={false}
                            name={`${activity}.activity_category`}
                            label={"Activity " + (index + 1)}
                            validate={[]}
                            dataSet={activityCategories}
                            dataFieldName={'id'}
                        >
                        </ReduxAutoCompleteFieldByValue>
                    </Grid>
                    <Grid item xs={2} className='text-right'>
                        <IconButton
                            onClick={() => fields.remove(index)}
                        // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                        >
                            <RemoveCircle style={{ color: '#AFA4C8' }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={10} lg={10} className=''>
                        <ReduxTextField
                            name={`${activity}.activity_remarks`}
                            label={"Activity " + (index + 1) + ' Remarks'}
                            validate={[maxLength100]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={5} className=''>
                        <ReduxTimeField
                            name={`${activity}.time_start`}
                            label={"Time Start"}
                            validate={[]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={5} className=''>
                        <ReduxTimeField
                            name={`${activity}.time_end`}
                            label={"Time End"}
                            validate={[]}
                        />
                    </Grid>


                    <Grid item xs={10} lg={10} className=''>
                        <Field key={`${activity}.images`}
                            name={`${activity}.images`}
                            label={'Image'}
                            value={[]}
                            component={renderImageComponent}
                        >
                        </Field>
                    </Grid>
                    <Grid item xs={10}>
                        { index>=0 && index!=fields.length-1 ?
                            <div style={{ border: '1px #AFA4C8 dashed' }}></div>
                            :
                            <Divider className='' style={{ backgroundColor: '#AFA4C8' }} />
                        }
                    </Grid>
                </Grid>

            )}

            <Grid item xs={12} container spacing={3}>
                <Grid item xs={10}>
                    <GeneralAddMore onClick={() => fields.push({})} />
                </Grid>
            </Grid>
            {/* <Button onClick={() => fields.push({})} className={classes.addButton}><Add className={classes.add} />ADD MORE</Button> */}

        </Grid>
    )
}


export default ActivityFormDetails;