import React, {useEffect, useState} from "react";
import {HashLoader, BarLoader, BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader, ClipLoader, ClockLoader, DotLoader, FadeLoader, GridLoader, MoonLoader, PacmanLoader, PropagateLoader, PulseLoader, RingLoader, RiseLoader, RotateLoader, ScaleLoader, SkewLoader, SquareLoader, SyncLoader} from 'react-spinners';

export function TableLoader() {
    const [show,setShow] = useState(false);
    React.useEffect(() => {
        // setTimeout(() => {
            setShow(true)
        // }, 200)
    }, [])
    return (
        show?
            <React.Fragment>
                <div style={{opacity:'15%', backgroundColor:'#39344A', width:'100%', height:'100%', position:'absolute', zIndex:'99'}}>
                </div>
                <div className="d-flex align-items-center justify-content-center" style={{width: '300px', height: '160px', position:'absolute', left:'50%', top:'55%', transform:'translate(-50%, -60%)',   zIndex:'1000'}}>
                    <BeatLoader color={'var(--primary)'} loading={true}/>
                </div>
            </React.Fragment>
        :null
    );
}
