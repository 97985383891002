import React, {Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import {Field} from "redux-form";

const ReduxTextAreaFieldComponent = ({input, meta: {touched, error}, ...rest}) => (
    <TextField
        fullWidth
        error={touched && Boolean(error)}
        helperText={touched && error ? error : null}
        {...input}
        {...rest}
        autoComplete='off'
    />
);

export const ReduxTextAreaField = props => {
    return (
        <Field
            variant="outlined"
            component={ReduxTextAreaFieldComponent}
            multiline
            rows="4"
            {...props}
            name={props.name || props.input.name}
        />
    )
};