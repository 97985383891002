import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import {
    Button,
    Grid,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box
} from '@material-ui/core';
import { ContractorLookUp } from "../../../components/other-components/MaterialTable/MaterialTableLookUps";
import MaterialTable from "material-table";
import { InlineStyles } from 'stylesCss';
import { APP_URL } from 'static';
import { CustomToolBar } from "../../../components/other-components/MaterialTable/Components";
import { Typography } from '@material-ui/core';
import MaterialTableBase from 'Page/UI_Design/Presets/AdminMaterialTableCRUDBase';
import { WrapperBox } from 'components/layout-components/Content';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {getPresetField, loadLocations} from "../../../actions/Presets";

function LocationTable() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const locations = useSelector((state) => state.presets.locations);
    const tableRef = React.createRef();
    const selectedLocation = useSelector((state) => state.presets.selectedLocation);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const locations = useSelector((state) => state.presets.locations);
    
  useEffect(() => {
    loadLocations(dispatch, selectedProject.id);
    // getPresetField(selectedProject.id,'locations').then((resp) => setData(resp.data.locations));
  }, [selectedProject]);

    return (
        <MaterialTableBase
            title={<Typography color='primary' style={{ fontWeight: 500 }}>List of Locations</Typography>}
            onRowClick={((evt, selectedRow) => dispatch({ 'type': 'SELECTLOCATION', 'payload': { 'id': selectedRow.id, 'name': selectedRow.name, 'project': selectedRow.project} }))
            }
            columns={
                [
                    { title: 'Name', field: 'name' },
                    {
                        title: '', field: '>',
                        render: rowData => (
                            <span>{selectedLocation.id === rowData.id ? <span></span> : <KeyboardArrowRightIcon color='primary'/>}</span>
                        ),
                        cellStyle: {
                            textAlign: "right"
                        },
                    },
                ]}
            data={locations}
            components={{
                Container: props => <WrapperBox>{props.children}</WrapperBox>,
                Header: props => (null),
                Toolbar: props => (
                    <CustomToolBar {...props} />
                ),
            }}
            actions={
                [
                ]}
            editable={[]}
            options={{
                rowStyle: rowData => ({
                    // fontSize: InlineStyles.materialTables.row.fontSize,
                    backgroundColor: (selectedLocation.id === rowData.id) ? '#15B1D7' : '#FFF',
                    color: (selectedLocation.id === rowData.id) ? '#FFF' : 'black',
                    height: 57
                }),
                pageSize: 10,
                pageSizeOptions: [10],
            }}
        />
    );
}

export default LocationTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
