import React, {useEffect, useState, createRef} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
  Button,
  Grid,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box, Card, CardHeader, CardContent
} from '@material-ui/core';
import tableIcons from "../../../components/other-components/MaterialTable/TableIcons";
import MaterialTable,{MTableToolbar} from "material-table";
import {InlineStyles} from 'stylesCss';
import {APP_URL} from 'static.js';
import {Edit, DeleteOutline, Check, Clear} from "@material-ui/icons/";
import {loadLabourTrades, loadLocations, getSubTrade, updateLocation} from 'actions/Presets';
import MaterialTableBase from "./AdminMaterialTableCRUDBase";
import {CustomFilter} from "../../../components/other-components/MaterialTable/Components";
import {WrapperBox} from "../../../components/layout-components/Content";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {makeStyles} from "@material-ui/core/styles";
import {updateLabourTrade} from "actions/Presets";

const useStyles = makeStyles(theme => ({
  addIconColor: {color: theme.palette.highlights.main},
  searchField: {
        color: '#FFF',
        '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiIconButton-label': {
            color: '#FFF'
        }
    }
  }
))

function SubTradeTable() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedLabour = useSelector((state) => state.presets.selectedLabour);
  const [subTrade, setSubTrade] = useState([])
  const tableRef = React.createRef();
  const [editName, setEdit] = useState(false)
  const [deleteItem, toggleDelete] = useState(false)
  const nameEditor = createRef();
  const [editingValue, setValues] = useState('');
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const [isLoading, setLoad] = useState(true);
  useEffect(() => {
    if(selectedLabour.id) {
      getSubTrade(selectedLabour.id).then((resp) => setSubTrade(resp.data.results));
      setLoad(false);
    }
    setEdit(false);
    toggleDelete(false);
  }, [selectedLabour]);

  useEffect(() => {
    setSubTrade([]);
  }, [selectedProject]);

  function toggleEdit(bool){
    setValues(selectedLabour.name);
    setEdit(bool);
  }

  function updateName(){
    const newData = {'name':editingValue}
    updateLabourTrade(selectedLabour.id, newData).then(response => {
      dispatch({'type':'SELECTLABOUR', 'payload':response.data});
      loadLabourTrades(dispatch, selectedProject.id);
    })
  }

  function deleteTrade(bool){
    updateLabourTrade(selectedLabour.id,{'soft_deleted':bool}).then(response => {
      dispatch({'type':'SELECTLABOUR', 'payload':response.data});
      loadLabourTrades(dispatch, selectedProject.id);
    })
  }

    function beforeAdd(newData){
      newData.project = selectedLabour.project;
      newData.trade = selectedLabour.id;
      return newData
    }

    function beforeUpdate(newData){
      newData.project = selectedLabour.project;
      newData.trade = selectedLabour.id;
      return newData
    }
  return selectedLabour.id ? (
        <MaterialTableBase
          data={subTrade}
          setList={setSubTrade}
          crudURL={APP_URL+'/site/presets/sub_labour_trade/'}
          beforeAdd={beforeAdd}
          beforeUpdate={beforeUpdate}
          // CustomLoading={isLoading}
          allowFilter={false}
          title={<div style={{ color: '#FFF' }}>
            {editName ?
                <React.Fragment>
                <TextField autoFocus={true} style={InlineStyles.materialTables.title} value={editingValue} onChange={(e)=>setValues(e.target.value)}></TextField>
                  <span className='ml-2 p-2'><Check onClick={()=>updateName()}/></span>
                  <span className='p-2'><Clear onClick={()=>toggleEdit(false)}/></span>
                </React.Fragment>
            :
                <React.Fragment>
                {deleteItem ?
                    <React.Fragment>
                    <span><em>Are you sure you want to disable this Trade?</em></span>
                      <span className='ml-2 p-2'><Check onClick={()=>{
                        deleteTrade(true)
                        toggleDelete(false)
                      }}/></span>
                      <span className='p-2'><Clear onClick={()=>toggleDelete(false)}/></span>
                    </React.Fragment>
                :
                    <React.Fragment>
                      <Typography style={{ fontWeight: 500, color: 'white' }}>
                          {selectedLabour.name}
                          {selectedLabour.soft_deleted
                             ? <span className='ml-2 p-2' onClick={()=>deleteTrade(false)}><LockIcon/></span>
                             : <span className='ml-2 p-2' onClick={()=>toggleDelete(true)}><LockOpenIcon/></span>
                        }
                      {/*<span onClick={()=>toggleDelete(!deleteItem)}><DeleteOutline/></span>*/}
                      <span onClick={()=>toggleEdit(!editName)} className='p-2'><Edit /></span>
                      </Typography>
                    </React.Fragment>
                }
                </React.Fragment>
            }
          </div>}
          columns={[
            { title: 'Name', field: 'name'},
            { title: 'Code', field: 'code'},
          ]}
          components={{
          Container: props => <WrapperBox>{props.children}</WrapperBox>,
            FilterRow: props => <CustomFilter {...props}/>,
            Toolbar: props => (
                    <div style={{ backgroundColor: '#15B1D7', color: '#FFF' }}>
                        <MTableToolbar classes={{ searchField: classes.searchField }} {...props} />
                    </div>),
            Header: props => (null)
          }}
          options={{
                searchFieldStyle: {
                    color: '#FFF',
                },
                addRowPosition: 'first',
            actionsColumnIndex:-1
            }}
          blueHeader={true}
        />
    )
        :
        (<Card className='rounded-0 w-100 h-100'>
            <CardHeader style={{ backgroundColor: '#15B1D7', height: 64 }}>

            </CardHeader>
            <CardContent className='h-100 d-flex justify-content-center align-items-center'>
                <div>Select a trade to preview</div>
            </CardContent>
        </Card>);
}

export default SubTradeTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
