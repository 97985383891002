import React from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import {Field} from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from '@material-ui/core/Radio'


export const ReduxRadioButtonComponent = ({
                                       input,
                                       children,
                                       helperText,
                                       meta: {touched, error},
                                       ...rest
                                   }) => (
    <FormControl error={touched && Boolean(error)}>
        <FormHelperText>{helperText || rest.label}</FormHelperText>
        <RadioGroup
            {...input}
            {...rest}
        >
            {children}
        </RadioGroup>
    </FormControl>
);


export const ReduxRadioButton = props => {

    if (props.children) {
        return (
            <Field
                {...props}
                component={ReduxRadioButtonComponent}
            >
                {props.children}
            </Field>
        )
    } else {
        return (
            <Field
                {...props}
                row
                component={ReduxRadioButtonComponent}
            >
                <FormControlLabel
                    value={'1'}
                    labelPlacement="start"
                    control={<Radio color={'primary'}/>}
                    label="Yes"/>
                <FormControlLabel
                    value={'0'}
                    labelPlacement="start"
                    control={<Radio color={'primary'}/>}
                    label="No"/>
            </Field>
        );
    }
};