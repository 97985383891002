import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import { WrapperBox } from "../../../components/layout-components/Content";
import {
  Grid,
  Dialog,
  TextField,
  Paper,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box, FormControl, InputLabel, Card, CardHeader, CardContent, Typography, Button
} from '@material-ui/core';
import {GetReportList, LoadReport, GetReport} from "../../../actions/Report";
import SignatureCanvas from "components/other-components/canvas";
import SignatureBox from "../../../components/other-components/canvas";
import {downloadReport} from "./Reports_NGA/ReportDownload";
import {downloadFile} from "../js_utils/general";
import {Document, Page} from "react-pdf";
import {useParams} from "react-router";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {makeStyles} from "@material-ui/core/styles";
import {GeneralButton} from "../../../components/other-components/Icons";
import {BackEndRequest, defaultHeaders} from "../../../actions/APICalls";
import {SignDocument} from "../../../actions/Report";
import {Accordion, AccordionDetails} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  // title: theme.styles.font.primary,
  // frame: theme.styles.page.frame,
  // content: theme.styles.page.content,
  dataFields: { color: 'black', fontSize: '0.9rem' },
  labelFields: { color: 'gray', fontSize: '0.8rem' },
  arrow:{
    width: '100px',
    height: '100px',
    position:'absolute',
    // top:'40%',
    boxShadow: '-4px 4px 0 black',
    margin:'4rem',
    opacity:'0.7',
    visibility: 'hidden',
    zIndex:'100',
  },
  left:{
    left:'0',
    transform: 'rotate(45deg)',
  },
  right:{
    right:'0',
    transform: 'rotate(-135deg)',
  },
  pdf_viewer: {
    textAlign:'center',
    width:'100%',
    position:'relative',
    '&:hover': {
      '& $arrow': {
        visibility: 'visible',
      }
    },
  },
}))

function Endorsement() {
  const classes = useStyles();
  let query = useQuery();
  // const selectedReport = useSelector((state) => state.report.selectedReport);
  const [signature, setSignature] = useState(null)
  const [reportData, setReportData] = useState({})
  const [pdfBlob, setBlob] = useState(null)
  let { id } = useParams();
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  let width = window.innerWidth - 362;
  if (width < 800){
    width = 800;
  }

  useEffect(()=>{
    dispatch({type:'SETPAGELOADING', payload:true})
    GetReport(id).then((resp)=>{
      setReportData(resp.data)
      dispatch({type:'SETPAGELOADING', payload:false})

    })
  }, [])

  // useEffect(() => {
  //   LoadReport(dispatch, id)
  // },[])

  // useEffect(() => {
  //   if(selectedReport.id) {
  //     downloadReport(selectedReport, 'pdf').then((resp) => {
  //       console.log(resp.data)
  //       setBlob(resp.data)
  //       // downloadFile(resp.data, 'application/pdf', 'file.pdf')
  //     })
  //   }
  //   // downloadFile(resp.data, "application/vnd.ms-excel", 'file.xlsx')
  // },[selectedReport])
  //
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function submitSignature() {
    let sign_type = query.get('sign_type')
    if (['contractor','supervisor','senior'].includes(sign_type)){
      SignDocument(id + '?project=' + reportData.project, {'signature': signature, 'sign_type': sign_type})
    }
  }

  return (
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <span className='font-size-lg font-weight-bold'>Endorsement</span>
        </Grid>
        <Grid item lg={6} className='text-right'>
        </Grid>
        <Grid item xs={12} lg={12} container>
          <Card className={'w-100'}>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                <Typography color='primary' style={{ fontWeight: 500 }}>
                  <span style={{color:'#3b3e66'}}>PDF Report</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <iframe className={'w-100'} style={{height:'80vh'}} src={reportData.report_document}/>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>

        {true?null:
        <Grid item xs={12} container>
          <Card className={classes.pdf_viewer}>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                <Typography color='primary' style={{ fontWeight: 500 }}>
                  <span style={{color:'#3b3e66'}}>PDF Report</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/*<div className={`${classes.arrow} ${classes.left}`} onClick={()=>{setNumPages(numPages-1)}}></div>
                <div className={`${classes.arrow} ${classes.right}`} onClick={()=>{setNumPages(numPages+1)}}></div>*/}
                <div className="Example__container__document">
                  <Document
                      file={pdfBlob}
                      onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {
                      Array.from(
                          new Array(numPages),
                          (el, index) => (
                              <Page
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                  scale={1}
                              />
                          ),
                      )
                    }
                  </Document>
                </div>
              </AccordionDetails>
            </Accordion>
          </Card>
        </Grid>
        }
        <Grid item xs={12} lg={6}>
          <Card className='h-100'>
            <CardHeader subheader="Project Details"/>
            <CardContent>
            <Grid container direction='row' spacing={3}>
              <Grid item xs={12}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Project</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Report Date</InputLabel>
                <Typography className={classes.dataFields}>{reportData.date}</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Submitted Time</InputLabel>
                <Typography className={classes.dataFields}>{reportData.warning_signal}</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Submitted By</InputLabel>
                <Typography className={classes.dataFields}>{reportData.weather_am}</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Total Activities</InputLabel>
                <Typography className={classes.dataFields}>0</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Total Labour</InputLabel>
                <Typography className={classes.dataFields}>0</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Total Plants</InputLabel>
                <Typography className={classes.dataFields}>0</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Supervisor</InputLabel>
                <Typography className={classes.dataFields}>NA</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Senior</InputLabel>
                <Typography className={classes.dataFields}>NA</Typography>
              </Grid>
              <Grid item xs={4}>
                <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Contractor</InputLabel>
                <Typography className={classes.dataFields}>NA</Typography>
              </Grid>
            </Grid>

          </CardContent></Card>
        </Grid>

        <Grid item xs={12} lg={6} container>{/*<style={{visibility:numPages?'visible':'hidden'}}>*/}
            <Card className={'w-100'}>
              <CardHeader subheader="Sign Report"/>
              <CardContent style={{justifyContent:'center', display:'flex'}}>
                <SignatureCanvas setImage={setSignature} h={200} w={600}/>
              </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} container justify={'space-between'}> {/*style={{visibility:numPages?'visible':'hidden'}}>*/}
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6} style={{textAlign:'right'}}>
            <GeneralButton type='button' label={'Submit'} onClick={()=>submitSignature()}></GeneralButton>
          </Grid>
        </Grid>
      </Grid>
  );
}
export default Endorsement;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
