
import React, {useEffect, useState, useContext} from 'react';
import {Page, Text, View, Document, StyleSheet, ReactPDF, PDFViewer, Font} from '@react-pdf/renderer';
import {useDispatch, useSelector} from "react-redux";
// import { Document, Page, Text, View} from 'react-pdf';
import moment from "moment";
// import {PDFContext} from "../../components/old_code_reference/PDFContext";

// Font.register({ family: 'Roboto', fonts: [
// { src: '/Users/bimteam/PycharmProjects/attendance-react/src/Page/PDFDoc/Fonts/Roboto/Roboto-Light.tff'},
// { src: '/Users/bimteam/PycharmProjects/attendance-react/src/Page/PDFDoc/Fonts/Roboto/Roboto-Black.tff', fontWeight: 'bold' },
// { src: '/Users/bimteam/PycharmProjects/attendance-react/src/Page/PDFDoc/Fonts/Roboto/Roboto-Italic.tff', fontStyle: 'italic' },
// { src: '/Users/bimteam/PycharmProjects/attendance-react/src/Page/PDFDoc/Fonts/Roboto/Roboto-BlackItalic.tff', fontStyle: 'italic', fontWeight: 'bold' },
// ]});

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingTop: 35,
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
  },
  basic: {
    familyFont: 'Roboto',
    fontWeight: 'bold',
    // margin: 5,
    // marginLeft: 35,
    fontSize: 12,
    paddingVertical: 2,
  },
  section: {
    paddingBottom: 20,
  },
  logoSection: {
    fontSize: 18,
    flexGrow: 1,
  },
  contactSection: {
    paddingHorizontal: 35,
    flexGrow: 1,
    color: 'grey',
    fontSize: 8,
    alignContent: 'right',
    flexDirection: 'row',
  },
  contact1: {
    paddingHorizontal: 10,
  },
  contact2: {
    paddingHorizontal: 10,
  },
  header: {
    width: 495,
    marginBottom: 50,
    flexDirection: 'row',
    textAlign: 'left',
  },
  footer: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

function Template1BView() {
  // const [state, setState] = useContext(PDFContext);
  // const fieldNames = ['fieldOurRef', 'fieldYourRef', 'fieldOurAddress', 'fieldToPerson', 'fieldContractNo', 'fieldContractDesc', 'fieldSubject', 'fieldContent', 'fieldFromPerson', 'fieldfromPersonPost', 'fieldfromPersonCo', 'fieldRemarks']
  // const fieldValues = useSelector((state) => state.pdf.fields);
  // const [state, setState] = useContext(PDFContext);
  // console.log(state);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <View style={styles.logoSection}><Text>AECOM LOGO</Text></View>
          <View style={styles.contactSection}>
            <View style={styles.contact1}>
              <Text>address and stuff</Text>
              <Text>address and stuff</Text>
              <Text>address and stuff</Text>
              <Text>address and stuff</Text>
            </View>
            <View style={styles.contact2}>
              <Text>Tele:1232131</Text>
            </View>
          </View>
        </View>
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Our Ref: {state.value.fieldOurRef}</Text>*/}
        {/*  <Text style={styles.basic}>Your Ref: {state.value.fieldYourRef}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Date: {moment(new Date()).format("DD/MM/YYYY")}</Text>*/}
        {/*  <Text style={styles.basic}>Some Address {fieldValues.fieldOurAddress}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Attention: {fieldValues.fieldToPerson}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Dear Sir,</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Contract No. {fieldValues.fieldContractNo}</Text>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldContractDesc}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={[styles.basic, {textDecoration: "underline"} ,{fontWeight:'bold'}]}>{fieldValues.fieldSubject}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldContent}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>Yours faithfully,</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldFromPerson}</Text>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldfromPersonPost}</Text>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldfromPersonCo}</Text>*/}
        {/*</View>*/}
        {/*<View style={styles.section}>*/}
        {/*  <Text style={styles.basic}>{fieldValues.fieldRemarks}</Text>*/}
        {/*</View>*/}
      </Page>
    </Document>
  )
}
// Create Document Component
export default Template1BView;
