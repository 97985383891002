import React, { Fragment, useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { change, Field, initialize, reduxForm, untouch } from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

export const ReduxSelectFieldComponent = ({
    id,
    input,
    label,
    meta: { touched, error, form },
    children,
    ...rest
}) => {

    const dispatch = useDispatch();


    return (
        <FormControl fullWidth error={touched && Boolean(error)} variant="outlined" style={{ minWidth: 120 }}>
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={id}
                label={label}
                onChange={value => input.onChange(value)}
                IconComponent={KeyboardArrowDown}
                value={input.value}
                {...input}
                {...rest}
            >
                {children}
            </Select>
        </FormControl>
    )
};

export const RenderSelectField = props => {
    const { dataSet, form, ...rest } = props;
    const [value, setValue] = useState(dataSet[0]['code'] || dataSet[0]['id'] || props.value);
    const dispatch = useDispatch();
    const currentForm = form;
    let subSet = null;
    if (dataSet[0]['sub_set']) {
        subSet = dataSet[0]['sub_set'][0]['code'] || dataSet[0]['sub_set'][0]['id'];
    }

    useEffect(() => {
        const checkSub = dataSet.find((q) => (q.code === value || q.id === value))['sub_set'];
        if (checkSub) {
            const v = checkSub[0]['code'] || checkSub[0]['id'];
            dispatch(change(currentForm, 'sub_' + props.name, v));
        }
        dispatch(change(currentForm, props.name, value));
    }, [value]);

    if (subSet) {
        const subDataSet = dataSet.find((q) => (q.code === value || q.id === value))['sub_set'];
        return (
            <Grid container spacing={1}>
                <Grid item xs>
                    <Field
                        {...rest}
                        component={ReduxSelectFieldComponent}
                        key={1}
                    // value={value || props.value}
                    >
                        {dataSet.map((item, key) => (
                            <MenuItem
                                key={key}
                                onClick={() => {
                                    setValue(item.id || item.code)
                                }}
                                value={item.code || item.id}
                            >
                                {item.code || null} {item.label || item.name}
                            </MenuItem>
                        ))}
                    </Field>
                </Grid>
                <Grid item xs>
                    <RenderSelectField
                        label={'Sub ' + props.label}
                        key={2}
                        name={'sub_' + props.name}
                        validate={props.validate}
                        dataSet={subDataSet} />
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Field
                {...rest}
                component={ReduxSelectFieldComponent}
            >
                {props.dataSet.map((item, key) => (
                    <MenuItem
                        key={key}
                        onChange={() => {
                            setValue(item.id || item.code)
                        }}
                        value={item.code || item.id}
                    >
                        {item.code || null} {item.label || item.name}
                    </MenuItem>
                ))}
            </Field>
        );
    }
};

export const ReduxNormalSelectField = props => {
    if (props.children) {
        return (
            <Field
                {...props}
                name={props.name || props.input.name}
                component={ReduxSelectFieldComponent}
            >
                {props.children}
            </Field>
        );
    } else {
        return (
            <Fragment>
                <RenderSelectField
                    {...props}
                />
            </Fragment>
        );
    }
};
