import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { InlineStyles, generalSpacing } from 'stylesCss';
import axios from 'axios';
import {
    InputLabel,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    Typography
} from '@material-ui/core';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { dateToString } from 'Page/UI_Design/js_utils/general';
import { makeStyles } from "@material-ui/core/styles";

import { bar_options } from 'Page/UI_Design/Dashboard/Highcharts/bar_configs';
import { column_options } from 'Page/UI_Design/Dashboard/Highcharts/column_configs';
import { pie_options } from 'Page/UI_Design/Dashboard/Highcharts/pie_configs';
import { process_bar_data, process_line_data, process_pie_data } from "./Highcharts/get_chart_data";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HighchartsComponent } from 'components/other-components/HighchartsComponent';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import NewSelect from 'Page/UI_Design/Dashboard/NewSelect';
import CountUp from 'react-countup';
import {colorSet, colorSet2} from "./Highcharts/colors";
import {BackEndRequest, defaultHeaders} from "../../../actions/APICalls";
import {ReportURL} from "../../../actions/Dashboard";

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function getSum(total, num) {
    return total + num;
}

// moves the menu below the select input
const menuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

function LabourPlantPieGraph(props) {
    const { to, from, selectedActivity } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();
    const classes = useStyles();
    const [attendanceCatPieData, setPieData] = useState(pie_options([], '', 'Employees'));
    const [sumOfLabours, setLabourSum] = useState(0);
    const [selectValue, setSelectOption] = useState('Labour')
    // const [attendanceSum, setAttendanceSum] = useState(0);
    // const [attendanceAverage, setAttendanceAverage] = useState(0);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const selectDayFrom = useSelector((state) => state.project.selectDayFrom);
    const selectDayTo = useSelector((state) => state.project.selectDayTo);
    const borderSelectClasses = useBorderSelectStyles();

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };

    useEffect(() => {
        if (selectValue == 'Labour') {
            let url = '/site/labour_data/?date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-') + '&activity__project=' + selectedProject.id
            if (selectedActivity) {
                url += '&activity=' + selectedActivity;
            }
            BackEndRequest('post', url, defaultHeaders, {
                    groupValue: 'trade__name',
                    countSum: 'no'
                }).then((resp) => {
                if (resp.data.length > 0) {
                    const pieData = resp.data.map(function (x) {
                        return { 'name': x.trade__name, 'y': x.no__sum }
                    })
                    const labourSum = (resp.data.map(function (x) {
                        return x.no__sum
                    }).reduce(getSum));
                    setLabourSum(labourSum)
                    const pie_config = pie_options(pieData, '', 'Employees')
                    pie_config.plotOptions.pie.colors = colorSet
                    pie_config.plotOptions.pie.dataLabels.format = '<b>{point.name}</b> <span style="color:#15B1D7">({point.percentage:.1f}%)</span>'
                    setPieData(pie_config)
                }
            })
        } else {
            let url = '/site/plant_data/?date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-') + '&activity__project=' + selectedProject.id
            if (selectedActivity) {
                url += '&activity=' + selectedActivity;
            }
            BackEndRequest('post', url, defaultHeaders, {
                    groupValue: 'plant__type',
                    countSum: 'working_no'
                }).then((resp) => {
                if (resp.data.length > 0) {
                    const pieData = resp.data.map(function (x) {
                        return { 'name': x.plant__type, 'y': x.working_no__sum }
                    })
                    const labourSum = (resp.data.map(function (x) {
                        return x.working_no__sum
                    }).reduce(getSum));
                    setLabourSum(labourSum)
                    const pie_config = pie_options(pieData, '', 'Plants')
                    pie_config.plotOptions.pie.colors = colorSet2
                    pie_config.plotOptions.pie.dataLabels.format = '<b>{point.name}</b> <span style="color:#AFA4C8">({point.percentage:.1f}%)</span>'
                    setPieData(pie_config)
                }
            })
        }

    }, [from, to, selectedProject, selectedActivity, selectValue]);

    // useEffect(() => {
    //   const getBasicSummary = () => {//async () => {
    //     axios.post(APP_URL+'/site/labour_costs/?date__lte='+dateToString(to,'-')+'&date__gte='+dateToString(from,'-'), {groupValue: 'trade__name', countSum:'no'}).then((resp) => {
    //     // axios.get(APP_URL+'/site/summary/?date__lte='+dateToString(to,'-')+'&date__gte='+dateToString(from,'-')).then((resp) => {
    //       let attendancePieConfig = pie_options('Attendance', 'Employees')
    //
    //       setPieData(process_pie_data(attendancePieConfig, resp.data, 'Employees'));
    //
    //       let sum = (resp.data.basic_data.staff__trade__name.reduce((a, b) => a + b.count, 0));
    //       setAttendanceSum(sum);
    //       setAttendanceAverage(Math.round(sum / Math.round(Math.abs((from - to) / (24 * 60 * 60 * 1000))+1)));
    //     });
    //   }
    //   getBasicSummary();
    // }, [from, to, selectedProject]);

    return (
        // <Grid container>
        //     <Grid container justify={'space-between'}>
        //         <Grid item xs={6}>
        //             <Card style={{ boxShadow: 'none' }}>
        //                 <CardHeader
        //                     style={{ padding: generalSpacing.padding, paddingBottom: '0.8rem', paddingRight: '0' }}
        //                     title={
        //                         <Typography style={{
        //                             'color': InlineStyles.materialTables.title.color,
        //                             'fontSize': InlineStyles.materialTables.title.fontSize,
        //                             'fontWeight': InlineStyles.materialTables.title.fontWeight,
        //                             'fontFamily': 'Roboto',
        //                         }}>
        //                             Labourer and Plant Summary
        //                         </Typography>
        //                     }
        //                 />
        //             </Card>
        //         </Grid>
        //         <Grid item xs={6} className='text-right' style={generalSpacing}>
        //             <FormControl size={'small'} variant={'outlined'}>
        //                 <Select
        //                     IconComponent={iconComponent}
        //                     className='text-left ml-3'
        //                     style={{ minWidth: '200px' }}
        //                     labelId={'SelectOpt'}
        //                     onChange={(e) => setSelectOption(e.target.value)}
        //                     value={selectValue}
        //                     disableUnderline
        //                 >
        //                     <MenuItem
        //                         key={'labour_opt'}
        //                         value='Labour'>Labour
        //                 </MenuItem>
        //                     <MenuItem
        //                         key={'plant_opt'}
        //                         value='Plant'>Plant
        //                 </MenuItem>
        //                 </Select>
        //             </FormControl>
        //         </Grid>
        //     </Grid>
        //     <Grid item xs={9} style={{ padding: '1rem' }}>
        //         <HighchartsComponent options={attendanceCatPieData} />
        //     </Grid>
        //     <Grid container item xs={3}>
        //         <Grid item xs={12} style={{ marginTop: '8rem', marginLeft: '-1rem', textAlign: 'center' }}>
        //             
        //         </Grid>
        //     </Grid>
        // </Grid>
        <Card className='card-box w-100 h-100 border-0'>
            <div className='card-header border-0'>
                <div className="card-header--title">
                    <Typography className='pl-1 pt-1' color='primary' style={{ fontWeight: 500 }}>Labourer and Plant Summary</Typography>
                </div>
                <div className='card-header--actions'>
                    <FormControl size={'small'} variant={'outlined'}>
                        <Select
                            IconComponent={iconComponent}
                            className='text-left ml-3'
                            style={{ minWidth: '200px' }}
                            labelId={'SelectOpt'}
                            onChange={(e) => setSelectOption(e.target.value)}
                            value={selectValue}
                            disableUnderline
                        >
                            <MenuItem
                                key={'labour_opt'}
                                value='Labour'>Labour
                        </MenuItem>
                            <MenuItem
                                key={'plant_opt'}
                                value='Plant'>Plant
                        </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            {/* <div className='d-flex flex-row px-4'>
                <div className='d-flex '>
                    <HighchartsComponent options={attendanceCatPieData} />
                </div>

                <div className='text-center d-flex flex-column justify-content-center'>
                    <span style={{ fontSize: '70px', fontWeight: '600', fontFamily: 'Roboto', color: selectValue == 'Labour' ? InlineStyles.materialTables.title.color : '#39344A' }}><CountUp start={0} end={sumOfLabours} duration={2} /></span><span style={{ fontSize: '40px', fontFamily: 'Roboto', color: InlineStyles.materialTables.title.color }}></span>
                    <Typography className='' variant="h3" color={selectValue == 'Labour' ? 'primary' : 'secondary'} style={{ fontWeight: 600 }}><CountUp start={0} end={sumOfLabours} duration={2} /></Typography>
                    <Typography style={{ whiteSpace: 'nowrap' }}>Sum of {selectValue}</Typography>
                </div>
            </div> */}
            <Grid container alignItems={'center'} style={{height:'85%'}} justify='center'>
                <Grid item xs={9} className={'p-3'}>
                    <HighchartsComponent options={attendanceCatPieData} />
                </Grid>
                <Grid item xs={3} container direction='row' alignItems='center' justify='center'>
                    <Grid item xs={12} className='text-center'>
                        <Typography className='' variant="h3" color={selectValue == 'Labour' ? 'primary' : 'secondary'} style={{ fontWeight: 600 }}><CountUp start={0} end={sumOfLabours} duration={2} /></Typography>
                        <Typography style={{ whiteSpace: 'nowrap' }}>Sum of {selectValue}</Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Card >
    );
}

export default LabourPlantPieGraph;
