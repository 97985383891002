import React, { useRef } from "react";
import MaterialTable from 'material-table';
import { AppBar, Tab, Tabs, Button, Popover, Grid, Tooltip, Paper, Select, MenuItem, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@material-ui/core'
import { TabPanel, TabContext } from '@material-ui/lab';
import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Info from '@material-ui/icons/Info';
import ActivityFormLaboursRow from "../ActivityForm/ActivityFormLaboursRow";

const useStyles = makeStyles((theme) => ({
    tab: {
        backgroundColor: theme.palette.background.paper,
    },
}))

function IdlingCodePopUp(props) {
    const { rowData, selectType } = props;
    // const {selectedRow} = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [type, setType] = React.useState(selectType);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const codes = ['a - Breakdown', 'f - Sunny / Public Holiday', 'b - Standby', 'g - Assemble / Deassemble', 'c - Not Required', 'h - Task Completed', 'd - No Operator', 'l - Bad Weather', 'e - Maintenance', 'j - Others']
    return (
        <div>
            <span onClick={handleClick}><Info style={{ fill: "black" }} /></span>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ marginTop: '-10px' }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >

                <Paper style={{ padding: '25px 35px', width: '420px', backgroundColor: '#39344A' }}>
                    <Grid container justify={'center'} spacing={1}>
                        {codes.map((code, index) =>

                            <Grid item xs={6}>
                                <span style={{ fontSize: '0.85rem', color: 'white', fontWeight: '600' }}>{code}</span>
                            </Grid>

                        )}
                    </Grid>
                </Paper>
            </Popover>
        </div>
    );
}

export default IdlingCodePopUp;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
