import React, { useEffect, useLayoutEffect, useRef, useState, createRef } from "react";
import { change, Field, FieldArray, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { IconButton, Button, Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Hidden, Divider } from '@material-ui/core'
import 'antd/dist/antd.css';

import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxRequired } from "components/form-components/validation";
import {ReduxAnotherAutoCompleteTextField} from "Page/UI_Design/Activity/ActivityForm/LabourFields/ReduxAnotherAutoCompleteTextField";
import { ReduxAutoCompleteFieldByValue} from "../../../../components/form-components/form-field/ReduxAutoCompleteFieldByValue";
import { ReduxNormalSelectField } from "../../../../components/form-components/form-field/ReduxNormalSelectField";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { RemoveCircle } from "@material-ui/icons";
import TradeSelectField from 'Page/UI_Design/Activity/ActivityForm/LabourFields/TradeSelectField';
import { APP_URL } from 'static';
import { required, number, maxLength } from 'components/form-components/validation/fields';

const useStyles = makeStyles(theme => ({
    // add: theme.styles.miniAddMore.add,
    // addButton: theme.styles.miniAddMore.addButton,
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 800,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
    },
}));

function ActivityFormLaboursRow(props) {
    const { fields, labour, index } = props
    const labourTrades = useSelector((state) => state.presets.labourTrades);
    const companies = useSelector((state) => state.presets.companies);
    const formData = useSelector((state) => state.form);
    const [staffSelectList, setSelect] = useState([]);
    const classes = useStyles();
    const [disabled, setDisabled] = useState(formData.report.values.labours[index].staff.length != 0);


    function renderStaffList(index) {
        const laboursData = formData.report.values.labours
        let report_date = formData.report.values.date
        if (report_date instanceof Date) {
            report_date = dateToString(report_date, '-');
        }
        let allSelected = []
        for (let i = 0; i < laboursData.length; i++) {
            const selectedStaff = laboursData[i].staff;
            if (selectedStaff) {
                if (index != i) {
                    allSelected = allSelected.concat(selectedStaff.map((selected) => {
                        return selected.id;
                    }));
                }
            }
        }
        const trade_id = formData.report.values.labours[index].trade;
        // const url = APP_URL+'/site/staffselect/?page_size=20&trade=' + trade_id;
        const url = APP_URL + '/site/staffselect/?page_size=20&trade=' + trade_id + '&attendance__date=' + report_date + '&date=' + report_date;
        axios.get(url).then((resp) => setSelect(
            () => {
                const stafflistdata = resp.data.results
                const filterSelectedStaff = stafflistdata.filter(function (el) {
                    return !allSelected.includes(el.id);
                });
                return filterSelectedStaff
            }
        ));
    }

    return (
        <Grid item xs={12} container direction='row' spacing={3} key={index + 'labourRowFragment'}>
            <Grid item xs={10} lg={7}>
                <TradeSelectField labourTrades={labourTrades} disabled={disabled} index={index} labour={labour} />
            </Grid>
            <Hidden lgUp>
                <Grid item xs={2} className='text-right'>
                    <IconButton
                        onClick={() => fields.remove(index)}
                    // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                    >
                        <RemoveCircle style={{ color: '#AFA4C8' }} />
                    </IconButton>
                </Grid>
            </Hidden>
            <Grid item xs={10} lg={3} >
                 <ReduxTextField
                    name={`${labour}.no`}
                    label="Labour No.*"
                    validate={[ReduxRequired, number]}
                />
            </Grid>
            <Hidden mdDown>
                <Grid item xs={2} className='text-right'>
                    <IconButton
                        onClick={() => fields.remove(index)}
                    // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                    >
                        <RemoveCircle style={{ color: '#AFA4C8' }} />
                    </IconButton>
                </Grid>
            </Hidden>
            <Grid item xs={10} lg={5}>
                <ReduxAnotherAutoCompleteTextField label={"Labour"} onChange={(event, value) => {
                    setDisabled(value.length != 0);
                    props.change('report', `${labour}.no`, value.length);
                }} labour={labour} multiple={true} name={`${labour}.staff`} onOpen={() => renderStaffList(index)} dataSet={staffSelectList} optionLabel={'eng_name'} valueField={'id'} defaultValue={formData.report.values.labours[index].staff} />
            </Grid>
            <Grid item xs={10} lg={5}>
              <ReduxAutoCompleteFieldByValue
                 getOptionLabel={(option) => (option.name)}
                 multiple={false}
                 name={`${labour}.mc_sc`}
                 label={"BK/SC"}
                 validate={[]}
                 dataSet={companies}
                 dataFieldName={'id'}
              >
              </ReduxAutoCompleteFieldByValue>
            </Grid>
            <Grid item xs={10}>
                {index >= 0 && index != fields.length - 1 ?
                    <div style={{ border: '1px #AFA4C8 dashed' }}></div>
                    :
                    <Divider className='' style={{ backgroundColor: '#AFA4C8' }} />
                }
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
    change
};

ActivityFormLaboursRow = connect(mapStateToProps,
    mapDispatchToProps
)(ActivityFormLaboursRow)


export default ActivityFormLaboursRow;