import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom'
import ActivityDetailsPopUp from 'Page/UI_Design/Activity/ActivityList/ActivityDetailsPopUp'
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import PropTypes from "prop-types";
import {InlineStyles} from 'stylesCss';
import {CustomFilter, CustomToolBar} from "../../../../components/other-components/MaterialTable/Components";
import {Box, Card, TablePagination, Tooltip, Typography} from '@material-ui/core';
import ImageIcon from "@material-ui/icons/Image";
import ActivityImageShowcase from 'Page/UI_Design/Activity/ActivityList/ActivityImageShowcase';
import { TableLoader } from 'components/other-components/TableLoader';
import { WrapperBox } from "../../../../components/layout-components/Content";
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
function ActivityViewTable(props) {
    const { dataSet, isLoading } = props;
    const navigate = useNavigate();
    // const selectedActivity = useSelector((state) => state.project.selectedActivity);
    // const dispatch = useDispatch();
    return (
        <div style={{ position: 'relative' }}>
            {isLoading ?
                <TableLoader />
                : null}
            <MaterialTable
                localization={{ body:{ emptyDataSourceMessage:<span>{isLoading?null:'No records to display'}</span>}}}
                icons={tableIcons}
                title={<Typography color='primary' style={{ fontWeight: 500 }}>List of Activities</Typography>}
                columns={
                    [
                        {
                            title: 'Location',
                            field: 'location.name',
                            render: rowData => (
                                <span>{rowData.location.name}</span>
                            ),
                        },
                        {
                            title: 'Sub Location',
                            field: 'sub_location.name',
                            render: rowData => (
                                <span>{rowData.sub_location ? rowData.sub_location.name : ''}</span>
                            ),
                        },
                        { title: 'Date', field: 'date' },
                        {
                            title: 'Time',
                            // field: 'details.activity_category.name',
                            customFilterAndSearch: (term, rowData) => {
                                let match = false;
                                rowData.details.map((e, i) => {
                                    if (e.activity_category) {
                                        match = e.time_start?.indexOf(term) > -1 || e.time_end?.indexOf(term) > -1;
                                    } else {
                                        return;
                                    }
                                });
                                return match;
                            },
                            render: rowData => (
                                <Grid container>
                                    <Grid item xs={10}>
                                        {rowData.details.map((e, i) => (
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', padding: 4 }}>
                                                <div>
                                                    <span>{e.time_start + ' - ' + e.time_end}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </Grid>

                                </Grid>
                            ),
                        },
                        {
                            title: 'Main Activities',
                            // field: 'details.activity_category.name',
                            customFilterAndSearch: (term, rowData) => {
                                let match = false;
                                rowData.details.map((e, i) => {
                                    if (e.activity_category) {
                                        match = e.activity_category.name.indexOf(term) > -1;
                                    } else {
                                        return;
                                    }
                                });
                                return match;
                            },
                            render: rowData => (
                                <Grid container>
                                    <Grid item xs={10}>
                                        {rowData.details.map((e, i) => (
                                            <Tooltip
                                                title={
                                                    <div>
                                                        <div>
                                                            {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 300, padding:4}}>
                                                    <span>
                                                        {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                                    </span>
                                                </div>
                                            </Tooltip>
                                        ))}
                                    </Grid>
                                    <Grid item xs={2} className='d-flex flex-row align-items-center text-center'>
                                        <div className='m-auto'>
                                            {rowData.details.length ?
                                                <ActivityImageShowcase
                                                    details={rowData.details}
                                                    location={rowData.location.name}
                                                    subLocation={rowData.sub_location ? rowData.sub_location.name : ''}
                                                    startTime={rowData.time_start} endTime={rowData.time_end} date={rowData.date}
                                                    labours={rowData.labours.reduce((a, b) => a + b.no, 0)}
                                                    plants={rowData.plants.reduce((a, b) => a + b.working_no, 0)}
                                                    materials={rowData.materials.length}
                                                />
                                                : null}
                                        </div>
                                    </Grid>
                                </Grid>
                            ),
                            minWidth: 500
                        },
                        // { title: 'Time Start', field: 'time_start' },
                        // { title: 'Time End', field: 'time_end' },
                        {
                            title: 'Labours',
                            // field: 'labours',
                            render: rowData => (
                                <ActivityDetailsPopUp rowData={rowData} selectType={0} />
                            ),
                        },
                        {
                            title: 'Plants',
                            // field: 'plants',
                            render: rowData => (
                                <ActivityDetailsPopUp rowData={rowData} selectType={1} />
                            ),
                        },
                        {
                            title: 'Materials',
                            // field: 'materials',
                            render: rowData => (
                                <ActivityDetailsPopUp rowData={rowData} selectType={2} />
                            ),
                        },
                        {
                            title: 'Edit',
                            // field: 'report.updated_by.email',
                            customFilterAndSearch: (term, rowData) => {
                                let match = false;
                                if (rowData.report.updated_by) {
                                    match = rowData.report.updated_by.email.indexOf(term) > -1;
                                } else {
                                    match = 'Anonymous'.indexOf(term) > -1;
                                }
                                return match;
                            },
                            render: rowData => (
                                rowData.report.status != 'created' && rowData.report.status != 'pending'?
                                    <span> completed by {rowData.report.updated_by ? rowData.report.updated_by.full_name : 'Anonymous'}</span>
                                    :
                                    <div className='d-flex flex-row align-items-center'>
                                        <EditIcon color='primary' className='pr-1' onClick={() => navigate('/app/activity_form/' + rowData.id)} />
                                        <span>drafted by {rowData.updated_by ? rowData.updated_by.full_name : 'Anonymous'}</span>
                                    </div>
                            ),
                        },
                    ]}
                components={{
                    Container: props => <WrapperBox>{props.children}</WrapperBox>,

                }
                }
                options={{
                    detailPanelColumnAlignment: 'right',
                    pageSize: 10,
                    pageSizeOptions: [10],
                    rowStyle: {
                        // fontSize: InlineStyles.materialTables.row.fontSize,
                        whiteSpace: 'nowrap'
                    },
                    headerStyle: {
                        whiteSpace: 'nowrap'
                    },

                }}
                data={dataSet}
                actions={
                    [
                        {
                            icon: tableIcons.Add, //props => <GeneralButton label='New Activity'/>,
                            tooltip: 'New Activity',
                            isFreeAction: true,
                            onClick: (event) => navigate('/app/activity_form/none')
                        }
                    ]}
            />
        </div >
    );
}

export default ActivityViewTable;

ActivityViewTable.propTypes = {
    dataSet: PropTypes.array.isRequired
};

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
