import React, { useState, useEffect } from 'react';
import { CSVReader } from 'react-papaparse';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom'
import {Box, Grid, Tooltip, Paper, Select, MenuItem, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@material-ui/core'
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import {InlineStyles} from 'stylesCss';
import {makeStyles} from "@material-ui/core/styles";
import {GeneralButton} from 'components/other-components/Icons';
import axios from "axios";
import {APP_URL} from 'static.js';
import {StaffLookUpByReg} from "components/other-components/MaterialTable/MaterialTableLookUps";
import {StaffLookUp} from "components/other-components/MaterialTable/MaterialTableLookUps";
import { v4 as uuidv4 } from 'uuid';
import {GetStaffList} from "../../../actions/Staff";

const useStyles = makeStyles(theme => ({
//   title: theme.styles.font.primary,
//   frame: theme.styles.page.frame,
//   content: theme.styles.page.content,
}))

const buttonRef = React.createRef()

export default function CSVReader2() {
  const [validDataSet, setValidatedData] = useState([]);
  const [invalidDataSet, setInvalidData] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [lookUp, setLookUp] = useState([])
  const [staffRegNoList, setRegNoList] = useState([]);
  const tableRef = React.createRef();
  const navigate = useNavigate();
  const classes = useStyles();
  const selectedProject = useSelector((state) => state.project.selectedProject);

  const handleOnDrop = (data) => {
    console.log('---------------------------');
    parseDataValidation(data)
    console.log('---------------------------');
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    setValidatedData([]);
    setInvalidData([]);
    console.log('---------------------------');
  };

    useEffect(() => {
        // let url = APP_URL+'/site/staff/?page_size=10000&project='+selectedProject.id;
        // axios.get(url).then((resp) => {
        //     setStaffList(resp.data.results);
        //     setRegNoList(resp.data.results.map(function (a, i){ return a.reg_no }));
        // });
      StaffLookUpByReg(selectedProject.id).then((list) => {
        // console.log(list)
        setLookUp(list)
      })
      GetStaffList('?page_size=10000&project='+selectedProject.id).then((resp)=>{
        setStaffList(resp.data.results);
      })
        setValidatedData([]);
        setInvalidData([]);
    }, [selectedProject]);

    function importAttendanceData(e){
        let dataSet = []
        const uuid = uuidv4();

        validDataSet.map(function(a, i){
            const staff_id = staffList.filter(staff => staff.reg_no == a.data[0])[0].id
            dataSet.push({staff_id:staff_id, date:a.data[1], in_time:a.data[2] || null, out_time:a.data[3] || null, holiday_remarks:a.data[4], import_batch:uuid})
        })

        let url = APP_URL+'/site/bulk_create/';
        const data = {data_set:dataSet};
        axios.post(url, data).then((resp) => {
            buttonRef.current.removeFile(e)
            setValidatedData([]);
            setInvalidData([]);
        });
    }

    function reParseValidation(e){
        let newValid = [];
        const dataDelete = [...invalidDataSet];
        invalidDataSet.map(function (a, i) {
            const invalidRecord = validateRecord(a, i);
            if (!invalidRecord){
                newValid.push(a);
                dataDelete.splice(i, 1);
            }
        })
        setInvalidData(dataDelete);
        setValidatedData(validDataSet.concat(newValid));
    }

    function validateRecord(record, index){
        if(record.data.length<5){
            record['error'] = ('invalid record');
            record['line'] = index+1;
            return record
        }
        else {
          let find = staffList.filter(staff => staff.reg_no == record.data[0])
          if (find.length <= 0){
          // if(!lookUp.includes(record.data[0])){
            record['error']=('staff id is not on system');
            record['line']=(index+1);
            return record
          }
          else{
            return false
          }
        }
    }

    function parseDataValidation(dataSet){
        let validData=[];
        let invalidData=[];
        dataSet.map(function (a, i) {
            const invalidRecord = validateRecord(a, i);
            if (invalidRecord){
                invalidData.push(invalidRecord);
            }
            else{
                validData.push(a);
            }
        });
        setValidatedData(validData);
        setInvalidData(invalidData);
    }

    function InvalidTable(){
        return (
            <MaterialTable
              icons={tableIcons}
              title=<div style={InlineStyles.materialTables.title}>Error</div>
              tableRef={tableRef}

              columns={[
                { title: 'Record', field: 'line'},
                { title: 'Staff', field: 'data.0',
                    render: rowData => (
                        <span>{rowData.data[0]}</span>
                    ),
                    lookup: lookUp,
                    customFilterAndSearch: (value, rowData) => {return rowData.data[0].includes(value)}
                },
                {
                  title: 'Date',
                  field: 'data.1',
                },
                {
                  title: 'Start Time',
                  field: 'data.2',
                },

                { title: 'End Time', field: 'data.3' },
                { title: 'Holiday Remarks', field: 'data.4' },
                { title: 'Error', field: 'error' },
              ]}
              components={{
                Container: props => <div>{props.children}</div>
              }}
              options={{
                rowStyle: rowData => ({
                  backgroundColor: '#FFFF99',
                  fontSize: InlineStyles.materialTables.row.fontSize,
                })
              }}
              data = {invalidDataSet}
              editable = {{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...invalidDataSet];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setInvalidData([...dataUpdate]);
                      resolve();
                    }, 1000)
                  }),
                onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...invalidDataSet];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setInvalidData([...dataDelete]);
                      resolve();
                    }, 1000)
                  }),
              }}
              actions={[
                {
                    icon: props => <div style={{marginRight:'-0.5rem'}}><GeneralButton label='Update' /></div>,
                  tooltip: 'Update',
                  isFreeAction: true,
                  onClick: reParseValidation
                }
              ]}
            />
        )
    }

    function ValidTable(){
        return (
            <MaterialTable
              icons={tableIcons}
              title=<div style={InlineStyles.materialTables.title}>Valid Data Preview</div>
              tableRef={tableRef}

              columns={[
                { title: 'Staff', field: 'data.0'},
                {
                  title: 'Date',
                  field: 'data.1',
                },
                {
                  title: 'Start Time',
                  field: 'data.2',
                },

                { title: 'End Time', field: 'data.3' },
                { title: 'Holiday Remarks', field: 'data.4' },
              ]}
              components={{
                Container: props => <div>{props.children}</div>
              }}
              data = {validDataSet}
              actions={[
                {
                  icon: props => <div style={{marginRight:'-0.5rem'}}><GeneralButton label='upload' /></div>,
                  tooltip: 'Upload',
                  isFreeAction: true,
                  onClick: importAttendanceData
                }
              ]}
            />
        )
    }

    return (
      <div className={classes.frame}>
      <Grid container direction='row' spacing={1} className={classes.content}>
        <Grid item xs={5}>
          <span className={classes.title}>Attendance Import</span>
        </Grid>
      </Grid>
      <Grid container direction='row' spacing={3}>
        <Grid item xs={12}>
            <h5>Click and Drag Upload</h5>
            <CSVReader
              ref={buttonRef}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          </Grid>
        </Grid>

          <Grid container direction='row' spacing={0} style={{marginTop:'50px'}}>
            <Grid item xs={12}>
                <Box boxShadow={3} m={0} p={0} style={{height:'100%', backgroundColor:'white'}}>
                  <InvalidTable />
                </Box>
            </Grid>
          </Grid>
          <br/>
          <Grid container direction='row' spacing={0}>
            <Grid item xs={12}>
                <Box boxShadow={3} m={0} p={0} style={{height:'100%', backgroundColor:'white'}}>
                  <ValidTable />
                </Box>
            </Grid>
          </Grid>
      </div>
    );
}