import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {Outlet, useLocation} from 'react-router-dom';
// import navItems from "../../routes/navItems";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PropTypes from "prop-types";
// import useRouter from "../../../utils/useRouter";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import axios from "axios";
import {APP_URL} from "../../../static";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

export default function SysBreadcrumbs(props) {
    const { children } = props;
    const location = useLocation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    console.log(selectedProject)
    // const t = navItems.find((e) => {
    //     return e.content ? e.content.find((i) => (location.pathname.includes(i.to))) : location.pathname.includes(e.to)
    // });
    // <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} className="mb-3"></Breadcrumbs>

    return (
        <div
            // className={classes.wrapper}
            style={{ minWidth: '980px' }}
        >
            {/* <div className={classes.contentContainer}>
                <div className={classes.content}> */}
                {selectedProject.id?
                    <Outlet />
                :null}
                {/* </div>
            </div> */}
        </div>
    );
}


SysBreadcrumbs.propTypes = {
    children: PropTypes.element
};
