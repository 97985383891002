import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { InlineStyles } from 'stylesCss';
import axios from 'axios';
import {
    InputLabel,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    Typography,
    Card
} from '@material-ui/core';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import ActivityViewTableSelectable from 'Page/UI_Design/Dashboard/ActivityViewTableSelectable';
import DateRangePicker from 'components/date-components/DateRangePicker';
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { makeStyles } from "@material-ui/core/styles";
import { APP_URL } from 'static.js';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { loadLocations } from "actions/Presets";
import { WrapperBox } from "../../../components/layout-components/Content";
import { bar_options } from 'Page/UI_Design/Dashboard/Highcharts/bar_configs';
import { chart_options } from 'Page/UI_Design/Dashboard/Highcharts/chart_configs';
import { column_configs } from 'Page/UI_Design/Dashboard/Highcharts/column_configs';
import { scatter_options } from 'Page/UI_Design/Dashboard/Highcharts/scatter_configs';
import { pie_options } from 'Page/UI_Design/Dashboard/Highcharts/pie_configs';
import { process_bar_data, process_line_data, process_pie_data } from "./Highcharts/get_chart_data";
import LabourPlantCountGraph from 'Page/UI_Design/Dashboard/LabourPlantCountGraph';
import MainGraph from 'Page/UI_Design/Dashboard/MainGraph';
import LabourPlantPieGraph from "./LabourPlantPieGraph";
import { GetActivityList } from "../../../actions/Activity";
import { GetDataSummary } from "../../../actions/Dashboard";
import TwoBoxesDateRangePicker from 'components/date-components/TwoBoxesDateRangePicker';

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function chart_data_labour_parser(record) {
    console.log(record.date)
    return [Date.parse(record.date), record.labours || 0];
}
function chart_data_plant_parser(record) {
    return [Date.parse(record.date), record.plants || 0];
}

function Index() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();
    const classes = useStyles();

    const [attendanceCatBarData, setBarData] = useState();
    // const [entry_type_scatter_options, setScatterOptions] = useState();
    // const [attendanceCatPieData, setPieData] = useState(pie_options('Attendance', 'Employees'));
    const [attendanceCatColumnData, setColumnData] = useState();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const to = useSelector((state) => state.project.selectDateTo);
    const from = useSelector((state) => state.project.selectDateFrom);
    function setTo(day) {
        dispatch({ 'type': 'NAVDATETO', 'payload': day });
    }
    function setFrom(day) {
        dispatch({ 'type': 'NAVDATEFROM', 'payload': day });
    }
    // const [from, setFrom] = useState( selectDateFrom || new Date());
    // const [to, setTo] = useState( selectDateTo || new Date());
    const locationList = useSelector((state) => state.presets.locations);
    const [location, setLocation] = useState({});
    const [activityList, setList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);
    const [activityTableSize, setTableSize] = useState(8);

    // useEffect(() => {
    //   setFrom(selectDateFrom || new Date());
    //   setTo(selectDateTo || new Date());
    // }, [from, selectDateFrom, selectedProject]);

    useEffect(() => {
        setSelectedRow(null);
    }, [from, to, selectedProject]);

    useEffect(() => {
        setLoading(true);
        let activityParams = '?';
        activityParams += 'date__gte=' + dateToString(from || new Date(), '-');
        activityParams += '&date__lte=' + dateToString(to || new Date(), '-');
        activityParams += '&project=' + selectedProject.id;
        activityParams += '&page_size=500';
        GetActivityList(activityParams).then((resp) => {
            setList(resp.data.results);
            setLoading(false);
        })

        // let summaryParams = '?date__lte='+dateToString(to,'-')+'&date__gte='+dateToString(from,'-')
        // GetDataSummary(summaryParams).then((resp)=>{
        //   let attendanceColumnConfig = column_configs('Attendance', 'Date', 'Workers')
        //   // let scatterConfig = scatter_options('Attendance', 'Date', 'Workers')
        //   let attendanceBarConfig = bar_options('Attendance','Work Category','workers')
        //   let attendancePieConfig = pie_options('Attendance', 'Employees')
        //   setBarData(process_bar_data(attendanceBarConfig, resp.data));
        //   // setPieData(process_pie_data(attendancePieConfig, resp.data, 'Employees'));
        //   setColumnData(process_line_data(attendanceColumnConfig, resp.data, 'date', 'count'));
        // })
    }, [from, to, selectedProject]);

    useEffect(() => {
        if (selectedProject.id) {
            loadLocations(dispatch, selectedProject.id);
        }
    }, [selectedProject]);


    return (
        <Grid container spacing={3} justify={'space-between'}>
            <Grid item lg={3}>
                <span className='font-size-lg font-weight-bold'>Overview</span>
            </Grid>
            <Grid item lg={9} spacing={3} container direction='row'>
                {false ? null :
                    <Grid item lg={4} className='pl-3'>
                        <FormControl className='w-100' variant="outlined">
                            <InputLabel id="locationLabel">Location</InputLabel>
                            <Select
                                className='text-left'
                                labelId="locationLabel"
                                label='Location'
                                id="location"
                                value={''}
                            IconComponent={KeyboardArrowDown}
                            >
                                {locationList.map((item, key) => (
                                    <MenuItem
                                        key={'location' + key}
                                        onClick={() => {
                                            setLocation(item.id)
                                        }}
                                        value={item.id}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {true ? null :
                    <Grid item lg={4} className='pl-3'>
                        <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
                            <InputLabel shrink id="locationInputLabel">
                                Shift
                            </InputLabel>
                            <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="shiftLabel"
                                id="shift"
                            >
                                <MenuItem value="morning">
                                    <span>Morning Shift</span>
                                </MenuItem>
                                <MenuItem value="night">
                                    <span>Night Shift</span>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                <Grid item lg={8} >
                    <TwoBoxesDateRangePicker to={to} from={from} handleFromChange={setFrom} handleToChange={setTo} />
                </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='stretch' spacing={3}>
                <Grid key='entry_line_chart' item xs={12} lg={8} lg={activityTableSize}>
                    <Card className='card-box w-100 h-100 border-0' style={{ overflow: 'auto', position: 'relative'}}>
                        <ActivityViewTableSelectable dataSet={activityList} isLoading={isLoading} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
                    </Card>
                </Grid>
                <Grid container key={'cat_bar_chart'} item xs={12} lg={4} style={{ minHeight: '440px' }}>
                    <LabourPlantCountGraph from={from} to={to} selectedActivity={selectedRow} />
                </Grid>
                <Grid key={'column_chart'} item xs={12} lg={6}>
                    {false ? <HighchartsReact highcharts={Highcharts} options={attendanceCatBarData} /> : null}
                    <MainGraph to={to} from={from} selectedActivity={selectedRow} />
                </Grid>
                <Grid key={'cat_pie_chart'} item xs={12} lg={6}>
                    <LabourPlantPieGraph to={to} from={from} selectedActivity={selectedRow} />
                </Grid>
            </Grid>
        </Grid>

    );
}

export default Index;
//        {[0, 1, 2].map((value) => (
//          <Grid key={value} item xs={4}>
//            <Paper><div>Total Count {diaryystaff_total}</div></Paper>
//            <Diary1Table data={diarystaff}/>
//          </Grid>
//        ))}
