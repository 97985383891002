import React from "react";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Field } from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import { Grid, Typography, FormLabel, Radio } from "@material-ui/core";
import { ReduxTextFieldComponent } from 'components/form-components/form-field/ReduxTextField';
import { ReduxRadioButtonComponent } from 'components/form-components/form-field/ReduxRadioButton';

export const RadioSiteStatus = props => {
    return (
        <React.Fragment>
            <Field
                name='radio_status'
                id='radio_status'
                key='radio_status'
                {...props}
                row
                component={ReduxRadioButtonComponent}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value={'active'}
                            labelPlacement="end"
                            control={<Radio color={'primary'} />}
                            label="Active" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value={'maintenance_period'}
                            labelPlacement="end"
                            control={<Radio color={'primary'} />}
                            label="Under maintenance period (Expiry date:" />
                        <Field
                            component={ReduxTextFieldComponent}
                            {...props}
                            fullWidth={false}
                            name={'maintenance_value'}
                        />
                        <span style={{ fontSize: '1rem', fontFamily: "Roboto", fontWeight: '400', lineHeight: '1.5', letterSpacing: '0.00938em', marginLeft: '10px' }}>)</span>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value={'work_suspended'}
                            labelPlacement="end"
                            control={<Radio color={'primary'} />}
                            label="Work suspended" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value={'work_completed'}
                            labelPlacement="end"
                            control={<Radio color={'primary'} />}
                            label="Work completed with maintenance period over / without maintenance period" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value={'vacant'}
                            labelPlacement="end"
                            control={<Radio color={'primary'} />}
                            label="Vacant" />
                    </Grid>
                </Grid>
            </Field>
        </React.Fragment>
    );
};