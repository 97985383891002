import {BackEndRequest, defaultHeaders} from "./APICalls";

export async function GetNotificationsList(dispatch, params){
  const resp = await BackEndRequest('get','/site/notification/list/'+params, defaultHeaders, {})
  dispatch({'type': 'NOTIFICATIONS', 'payload': resp.data.results})
}

export async function GetNotificationsNo(dispatch, id){
  const resp = await BackEndRequest('get','/site/notification/list/?project='+id+'&page_size=1&read=false', defaultHeaders, {})
  console.log(resp)
  dispatch({'type': 'NOTIFICATIONSNO', 'payload': resp.data.count})
}

export async function ReadNotification(dispatch, notification){
  if (!notification.read){
    const resp = await BackEndRequest('patch','/site/notification/list/'+notification.id+'/', defaultHeaders, {'read':true})
    dispatch({'type': 'NOTIFICATIONSREADONE', 'payload': resp.data.count})
  }
}