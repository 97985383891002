import React, { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import {
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    Card
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { APP_URL } from 'static';
import Attendance_FE_TABLE from 'Page/UI_Design/Attendance/Attendance_FE_TABLE';
import { makeStyles } from "@material-ui/core/styles";
import DateInputPicker from 'components/date-components/DatePicker';
import TwoBoxesDateRangePicker from "../../../components/date-components/TwoBoxesDateRangePicker";
const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))
function Index() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const to = useSelector((state) => state.project.selectDateTo);
    const from = useSelector((state) => state.project.selectDateFrom);
    // const [selectedDate, handleDateChange] = useState(selectedDay);
    function setTo(day) {
        dispatch({ 'type': 'NAVDATETO', 'payload': day });
    }
    function setFrom(day) {
        dispatch({ 'type': 'NAVDATEFROM', 'payload': day });
    }
    // dateTo, dateFrom
    const selectedProject = useSelector((state) => state.project.selectedProject);
    return (
        < Grid container spacing={3} justify={'space-between'}>
            <Grid item lg={3}>
                <span className='font-size-lg font-weight-bold'>Attendance</span>
            </Grid>
            <Grid item lg={9} spacing={3} container direction='row'>
                <Grid item lg={4} className='pl-3'>
                </Grid>
                <Grid item lg={8}>
                    <TwoBoxesDateRangePicker to={to} from={from} handleFromChange={setFrom} handleToChange={setTo} />
                </Grid>
            </Grid>
            <Grid item lg={12}>
                <Card><Attendance_FE_TABLE from={from} to={to} selectedProject={selectedProject} /></Card>
            </Grid>
        </Grid >
    )
}
export default Index;
