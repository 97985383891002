import React, { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import {
  CompanyLookUp,
  StaffLookUp,
  LabourTradeLookUp,
  PositionLookUp, SubTradeLookUp
} from "../../../components/other-components/MaterialTable/MaterialTableLookUps";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { InlineStyles } from 'stylesCss';
import { APP_URL } from 'static.js';
import PropTypes from "prop-types";
import { dateToString } from "Page/UI_Design/js_utils/general";
import { GetAttendanceList } from "../../../actions/Attendance";
import MaterialTableBase from 'components/other-components/MaterialTable/MaterialTableCRUDBase';
import { GetStaffList } from "../../../actions/Staff";

function AttendanceTable(props) {
  const {to, from, selectedProject} = props
  const [attendanceList, setList] = useState([]);
  const [isLoading, setLoad] = useState(true);
  const [tradesList, setTradesList] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [staffList, setStaffList] = useState();

  function beforeAdd(newData) {
    newData.staff_id = newData.staff.id;
    newData.project_id = selectedProject.id;
    return newData
  }

  function beforeUpdate(newData) {
    newData.staff_id = newData.staff.id;
    newData.project_id = selectedProject.id;
    return newData
  }

  useEffect(() => {
    setLoad(true);
    const params = '?date__gte=' + dateToString(from || new Date(), '-') + '&date__lte=' + dateToString(to || new Date(), '-') + '&staff__project=' + selectedProject.id;
    GetAttendanceList(params).then((resp) => {
      setList(resp.data.results);
      setLoad(false);
    })
  }, [to, from, selectedProject]);

  useEffect(() => {
    SubTradeLookUp(selectedProject.id).then((list) => {
      setTradesList(list)
    })
    CompanyLookUp(selectedProject.id).then((list) => {
      setCompaniesList(list)
    })
    StaffLookUp(selectedProject.id)
        .then((list) => {
          setStaffList(list)
        });
  }, [selectedProject]);

  return (
      <MaterialTableBase
          titleText='List of Attendance'
          data={attendanceList}
          setList={setList}
          crudURL={APP_URL + '/site/attendance/'}
          beforeAdd={beforeAdd}
          beforeUpdate={beforeUpdate}
          CustomLoading={isLoading}
          allowFilter={true}
          columns={[
            {
              title: 'Contractor',
              field: 'staff.company',
              lookup: companiesList,
              editable: 'never',
            },
            {
              title: 'Staff',
              field: 'staff.id',
              lookup: staffList,
              // editable: 'never',
            },
            {title: 'name', field: 'staff.ch_name', editable: 'never',},
            {
              title: 'Trade', field: 'staff.sub_trade',
              lookup: tradesList,
              editable: 'never',
            },
            {title: 'Site', field: 'on_site'},
            {title: 'Date', field: 'date'},
            {title: 'Time In', field: 'in_time'},
            {title: 'Time Out', field: 'out_time'},
            {title: 'Holiday Remarks', field: 'holiday_remarks'},
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            pageSizeOptions: [10],
            filtering: true,
            rowStyle: rowData => ({
              //fontSize: InlineStyles.materialTables.row.fontSize,
              color: (!rowData.import_batch) ? 'red' : 'black'
            }),
            addRowPosition: 'first',
          }}
      />
  )
}

export default AttendanceTable;

AttendanceTable.propTypes = {
    to: PropTypes.instanceOf(Date).isRequired,
    from: PropTypes.instanceOf(Date).isRequired
};
