const Template1PreviewData = {
  fieldOurRef: '{KNP/(ND/2018/01)/C05/100/}',
  fieldYourRef: '{Your Ref}',
  fieldOurAddress: '{CRBC-Build King Joint Venture}',
  fieldToPerson: '{To Person}',
  fieldContractNo: '{ND/2018/01}',
  fieldContractDesc: '{Site Formation and Infrastructure Works for Police Facilities in Kong Nga Po}',
  fieldSubject: '{Alternative HOKLAS Laboratory for Mass Determination and Loading Test of Manhole Covers Gratings and Kerb Overflow WEirs (AQuality TestConsultant Ltd.)}',
  fieldContent: '{I refer to your above referenced submission dated 20 May 2020 proposing the Laboratory, AQuality TestConsult Ltd. for testing manhole covers, gratings and kerb overflow weirs.' +
    '\n\n' +
    'Pursuant to GS Clause 1.40, your proposal of using AQuality Test Consult Ltd. is accepted.}',
  fieldFromPerson: '{From Person}',
  fieldfromPersonPost: '{From Persons\' Post}',
  fieldfromPersonCo: '{From Person\'s Company}',
  fieldRemarks: '{c.c. The Project Manager for the contract (CE/E1, CEDD) -Attn.: Mr.Sunny SP LO}'
};

export default Template1PreviewData;
