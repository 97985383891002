import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    Typography,

} from '@material-ui/core';
import ActivityForm from './ActivityForm';
import ActivityHistory from "./ActivityHistory";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    //   title: theme.styles.font.primary,
    //   frame: theme.styles.page.frame,
    //   content: theme.styles.page.content,
}))

function Index() {
    const classes = useStyles();
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <span className='font-size-lg font-weight-bold'>Add Activity</span>
            </Grid>
            <Grid item xs={8}>
                <ActivityForm />
            </Grid>
            <Grid item xs={4}>
                <ActivityHistory />
            </Grid>
        </Grid>

    );
}
export default Index;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
