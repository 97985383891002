import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SiteSheet1TemplateStaff, SiteSheet1TemplateSupervisor, SiteSheet1TemplateLabour, SiteSheet1TemplatePlant } from 'Page/UI_Design/DailyReport/ExcelTemplates/SiteSheet1Template';
import SiteSheet2Template from 'Page/UI_Design/DailyReport/ExcelTemplates/SiteSheet2Template';
import {SiteSheet3Template} from 'Page/UI_Design/DailyReport/ExcelTemplates/SiteSheet3Template';
import {LabourSummary, PlantSummary, CountSummary, GetReport} from "../../../../actions/Report";
import {BackEndRequest, BackEndRequestArrayBuffer, defaultHeaders} from "../../../../actions/APICalls";
import {GetActivityList} from "../../../../actions/Activity";

export async function loadExcelData(selectedReport){
    const date_params = '?activity__date=' + selectedReport.date
    return await Promise.all([
      CountSummary('?date=' + selectedReport.date + '&filter=staff__position__supervisor&value=1&exclude=1'),
      CountSummary('?date=' + selectedReport.date + '&filter=staff__position__supervisor&value=1&count=staff__position__name'),
      PlantSummary(date_params),
      LabourSummary(date_params),
      GetActivityList('?date=' + selectedReport.date+'&review_status=approved')
    ]).then(async (resp_array) => {
      const attendanceCount = resp_array[0].data
      const supervisorCount = resp_array[1].data
      const plantsCount = resp_array[2].data
      const laboursCount = resp_array[3].data
      const list = resp_array[4].data.results
      let combined_section = [];
      for (var i = 0; i < list.length; i++) {
        let c = true;
        const back_up = JSON.parse(JSON.stringify(list[i]));
        while (c) {
          let labours = list[i].labours.shift() || {};
          let plants = list[i].plants.shift() || {};
          let materials = list[i].materials.shift() || {};
          let details = list[i].details.shift() || {};

          c = Object.keys(labours).length != 0 || Object.keys(plants).length != 0 || Object.keys(materials).length != 0 || Object.keys(details).length != 0
          if (c) {
            combined_section.push({'labours': labours, 'plants': plants, 'materials': materials, 'details': details});
          }
        }
        list[i] = back_up;
        list[i]['combined_section'] = combined_section
        combined_section = []
      }
      const attPayload = SiteSheet1TemplateStaff(attendanceCount, attendanceCount.reduce(function (total, element) {
        return total + element.count;
      }, 0))
      const supPayload = SiteSheet1TemplateSupervisor(supervisorCount, supervisorCount.reduce(function (total, element) {
        return total + element.count;
      }, 0))
      const labPayload = SiteSheet1TemplateLabour(laboursCount, laboursCount.reduce(function (total, element) {
        return total + element.count;
      }, 0))
      const plantPayload = SiteSheet1TemplatePlant(plantsCount, plantsCount.reduce(function (total, element) {
        return total + element.count;
      }, 0))
      const sheet2Payload = SiteSheet2Template(selectedReport)
      const sheet3Payload = SiteSheet3Template(list)
      // const sheet1Headings = SiteSheetHeadings(selectedReport, selectedProject, '1')
      // const sheet2Headings = SiteSheetHeadings(selectedReport, selectedProject, '2') Does not match with page1
      // const sheet3Headings = SiteSheetHeadings(selectedReport, selectedProject, '3') Does not match with page1
      return attPayload.concat(supPayload).concat(labPayload).concat(plantPayload).concat(sheet2Payload).concat(sheet3Payload)//.concat(sheet1Headings).concat(sheet2Headings),//.concat(sheet3Headings),
  })
}

export async function downloadReport(selectedReport, fileType){
  if(selectedReport.id) {
      const multidata = await loadExcelData(selectedReport)
      const payload = {
        'multidata': multidata,
        'template': 'excel/Site.xlsx',
        'file_type': fileType
      }
      return await BackEndRequestArrayBuffer('post', '/site/excel/', defaultHeaders, payload)
  } else{
    return null //no report selected
  }
}
//"application/vnd.ms-excel"
//'file.xlsx'
// export function downloadFile(blobData, fileType, fileName){
//     var newBlob = new Blob([blobData], {type: fileType});
//     const url = window.URL.createObjectURL(newBlob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', fileName);
//     document.body.appendChild(link);
//     link.click();
// }