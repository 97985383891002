import React from "react";
import Highcharts from "highcharts/highstock";


export const HighchartsComponent = ({ options, ...props }) => {
  const containerRef = React.useRef(null);
  const chartRef = React.useRef(null);

  React.useEffect(() => {
    chartRef.current = Highcharts.chart(containerRef.current, options);
  }, [options]);

  React.useEffect(() => {
    console.log("update options");

    /* I need to remove all series before the update If I want the chart to be update correctly
    comment or uncomment this while loop to see the difference. */

    // while (chartRef.current.series.length) {
    //   chartRef.current.series[0].remove(true);
    // }

    chartRef.current.update(options, true, true);
  }, [chartRef]);

  return <div ref={containerRef} {...props} />;
};