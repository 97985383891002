import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom'
import ActivityDetailsPopUp from 'Page/UI_Design/Activity/ActivityList/ActivityDetailsPopUp'
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import PropTypes from "prop-types";
import { InlineStyles } from 'stylesCss';
import { CustomToolBar } from "../../../components/other-components/MaterialTable/Components";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { TableLoader } from "../../../components/other-components/TableLoader";
import { CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Divide } from "react-feather";

function ActivityViewTableSelectable(props) {
    const { selectedRow, setSelectedRow } = props;
    const { dataSet, isLoading } = props;
    const tableRef = React.createRef();
    const navigate = useNavigate();
    // const selectedActivity = useSelector((state) => state.project.selectedActivity);
    // const dispatch = useDispatch();

    return (
        <div style={{ position: 'relative'}}>
            {isLoading ?
                <TableLoader />
                : null}
            <MaterialTable
                icons={tableIcons}
                localization={{ body:{ emptyDataSourceMessage:<span>{isLoading?null:'No records to display'}</span>}}}
                title={<Typography color='primary' style={{ fontWeight: 500 }}>List of Activities</Typography>}
                tableRef={tableRef}
                columns={
                    [
                        { title: 'Date', field: 'date' },
                        {
                            title: 'Location',
                            field: 'location',
                            render: rowData => (
                                <span>{rowData.location.name}</span>
                            ),
                        },
                        {
                            title: 'Sub Location',
                            field: 'sub_location',
                            render: rowData => (
                                <span>{rowData.sub_location ? rowData.sub_location.name : ''}</span>
                            ),
                        },
                        {
                            title: 'Main Activities',
                            field: 'details',
                            render: rowData => (
                                <Tooltip
                                    title={
                                        <div>
                                            {rowData.details.map((e, i) => (
                                                <div>{i + 1}. {e.activity_category ? e.activity_category.name : ''}:{e.activity_remarks}</div>
                                            ))}
                                        </div>
                                    }
                                >
                                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 200 }}>
                                        {rowData.details.map((e, i) => (
                                            <span>{i + 1}. {e.activity_category ? e.activity_category.name : ''}:{e.activity_remarks}</span>
                                        ))}
                                    </div>
                                </Tooltip>
                            ),
                        },
                        // { title: 'Time Start', field: 'time_start' },
                        // { title: 'Time End', field: 'time_end' },
                        // <span>{rowData.labours.length}</span>
                        // <span>{rowData.plants.reduce((a, b) => a + b.working_no, 0)}</span>
                        // <span>{rowData.materials.length}</span>
                        {
                            title: 'Labours',
                            field: 'labours',
                            render: rowData => (
                                // <ActivityDetailsPopUp rowData={rowData} selectType={0} />
                                <div className='text-center' style={{ paddingRight: 26 }}>{rowData.labours.reduce((a, b) => a + b.no, 0)}</div>
                            ),
                        },
                        {
                            title: 'Plants',
                            field: 'plants',
                            render: rowData => (
                                // <ActivityDetailsPopUp rowData={rowData} selectType={1} />
                                <div className='text-center' style={{ paddingRight: 26 }}>{rowData.plants.reduce((a, b) => a + b.working_no, 0)}</div>
                            ),
                        },
                        {
                            title: 'Materials',
                            field: 'materials',
                            render: rowData => (
                                // <ActivityDetailsPopUp rowData={rowData} selectType={2} />
                                <div className='text-center' style={{ paddingRight: 26 }}>{rowData.materials.length}</div>
                            ),
                        },
                    ]}
                components={{
                    Container: props => <div>{props.children}</div>,
                    Toolbar: props => (
                        <CustomToolBar {...props} />
                    ),
                }
                }
                onRowClick={((evt, sR) => { selectedRow == sR.id ? setSelectedRow(null) : setSelectedRow(sR.id) })}
                options={{
                    rowStyle: rowData => ({
                        fontSize: InlineStyles.materialTables.row.fontSize,
                        backgroundColor: (selectedRow === rowData.id) ? '#F2FFFE' : '#FFF'
                    }),
                    headerStyle: {
                        whiteSpace: 'nowrap'
                    },
                    detailPanelColumnAlignment: 'right',
                    pageSize: 5,
                    pageSizeOptions: [5],
                }}
                data={dataSet}
                actions={
                    [
                        {
                            icon: tableIcons.Add, //props => <GeneralButton label='New Activity'/>,
                            tooltip: 'New Activity',
                            isFreeAction: true,
                            onClick: (event) => navigate('/app/activity_form/none')
                        }
                    ]}
            />
        </div>
    )
}

export default ActivityViewTableSelectable;

ActivityViewTableSelectable.propTypes = {
    dataSet: PropTypes.array.isRequired
};

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
