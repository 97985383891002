import React, {useEffect, useState, setState} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
  PDFViewer,
  ReactPDF,
  Font,
  BlobProvider,
  PDFDownloadLink,
  Image
} from '@react-pdf/renderer';
import {Page as VPage, pdfjs, Document as VDoc} from 'react-pdf';

import {TextField, FormControl, Input} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {UpdateField, PreviewPDF, ClosePreview, AddAttachment} from "../../../actions/old/PDFActions";
import Template1 from "Page/old/PDFDoc/Template1"
import Template1PreviewData from 'Page/old/PDFDoc/Template1Data';
import Template1BView from "./Template1BView";
import Template1Blob from "./Template1Blob";
import ImageDoc from "./ImageDoc";
import {dataURLtoFile, downloadBlobFile} from 'Page/old/PDFDoc/utils'
//import CKEditor from 'ckeditor4-react';
// import CKEditor from 'ckeditor4-react';
import {APP_URL} from 'static.js';
// import { Document as VDoc } from 'react-pdf/dist/esm/entry.webpack';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// import hummus from "hummus";
// import Printer, { print } from 'react-pdf-print'

// import {isArrayBuffer} from "react-pdf/dist/umd/shared/utils";
// CKEditor.editorUrl = 'components/ckeditor/ckeditor.js';

function PDF() {
  const reduxDispatch = useDispatch();
  const fields = ['fieldOurRef', 'fieldYourRef', 'fieldOurAddress', 'fieldToPerson', 'fieldContractNo', 'fieldContractDesc', 'fieldSubject', 'fieldContent', 'fieldFromPerson', 'fieldfromPersonPost', 'fieldfromPersonCo', 'fieldRemarks']
  const attachments = useSelector((state) => state.pdf.attachments);
  // ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
  const previewPDF = useSelector((state) => state.pdf.previewPDF);

  //pdf viewer by file
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
  },[])

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  //pdf viewer by file

  function updatePDFField(e){
    reduxDispatch(UpdateField({'field':e.target.name, 'value':e.target.value}));
  }
  function loadPreview(){
    reduxDispatch(ClosePreview());
    reduxDispatch(PreviewPDF());
  }
  function addAttachments(es){
    if (es) {
      try {
        let e = es.target.files[0];
        var reader = new FileReader();
        reader.onload = function () {
          let formdata = new FormData();
          //Save to GSP
          formdata.append('file', dataURLtoFile(reader.result), 'asd.jpg');
          axios.post(APP_URL+'/filestorage/', formdata,{ headers: {"Content-Type": "multipart/form-data"}})
            .catch((error) => (console.log(error.response)))
            .then((resp) => reduxDispatch(AddAttachment(resp.data.file))
          );
          //Save to GSP
          // Save to local storage
          console.log(reader.result);
          console.log(dataURLtoFile(reader.result));

          localStorage.setItem("attachment"+toString(attachments.length),reader.result);
          // toSinglePDF(reader.results);

        // Save to local storage
        };
        reader.readAsDataURL(e);
      }catch(e){
        console.log('failed to read attachment..');
        console.log(e);
      }
    }
  }

  return (
    <div>
      <div style={{width:"50%", display:"inline-block"}}>
        <Input id="assets" name="assets" type="file" name="attachment" onChange={addAttachments} inputProps={{ multiple: true }} />

        {/*{attachments ? attachments.map((attachment, i) => (*/}
        {/*  <img key={'attachment'+toString(i)} src={attachment} crossOrigin={'anonymous'}/>*/}
        {/*)) : <div>no image?</div> }*/}
        {fields ? fields.map((fieldName) => (
          <FormControl fullWidth key={"key_"+fieldName}>
            <TextField
              multiline
              required
              InputLabelProps={{
                shrink: true,
                required: true
              }}
              id={fieldName}
              defaultValue={Template1PreviewData[fieldName]}
              key={fieldName}
              label={fieldName}
              name={fieldName}
              onChange={updatePDFField}
              margin="normal"
            />
          </FormControl>
        )) : <div></div>}

      </div>
      <div style={{width:"45%", display:"inline-block"}}>
        <button onClick={() => loadPreview()}>Preview PDF</button>
        {previewPDF ? (
          // <PDFViewer width="600" height="800">
          <div>
            <Template1 />
          </div>
          // </ PDFViewer>
        ):<div></div>}
        {/*<PDFDownloadLink document={Template1Blob()} fileName="somename.pdf">*/}
          {/*{({ blob, url, loading, error }) => (loading ? <Icon fitted color="orange" name="spinner"/> : <Icon fitted color="orange" name="print"/>)}*/}
          {/*{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}*/}
        {/*</PDFDownloadLink>*/}

        {/*<VDoc*/}
        {/*  file={'./src/Page/PDFDoc/somename.pdf'}*/}
        {/*>*/}
        {/*  <VPage pageNumber={1} />*/}
        {/*</VDoc>)*/}

        <BlobProvider document={ImageDoc()}>
          {({ blob, url, loading, error }) => {
            if (blob) {
              let formdata = new FormData();
              formdata.append('file', blob, 'asd.pdf');
              axios.request({url: APP_URL+'/pdf/',data:formdata, method: 'POST', responseType: 'blob'})
                .then((response) => {
                    var newBlob = new Blob([response.data], {type: "application/pdf"});
  //                  downloadBlobFile(newBlob, 'file.pdf')
                    return (newBlob);
                  }
                )
            }
            return(
              <div>
              <VDoc
                file={blob}
              >
                <VPage pageNumber={1} />
              </VDoc>
              </div>
          )
          }}
        </BlobProvider>
      </div>
    </div>
  )
}
// Create Document Component
export default PDF;


//          <PDFViewer><Template1BView/></PDFViewer>
