import React, {Fragment, useState} from 'react';
import {
    Avatar,
    Box,
    Menu,
    Button,
    List,
    ListItem,
    Divider,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {Logout} from "../../../actions/Auth";
// import SampleAvatar from 'components/Avatar.js';
// import {logout} from "../../../actions/auth";

const useStyles = makeStyles(theme => ({
    menuOverride: {
        borderRadius: '0px 0px 4px 4px',
    },
    popUpMenu: {
        marginLeft: '2rem'
    },
    popUpMenuContent: {
        right: 20,
        left: 'auto',
        overflow: 'hidden',
    },
    popUpMenuList: {
        paddingBottom:0,
        textAlign: "left",
        alignItems: "center",
        // display: "flex",
        flexDirection: "column",
    },
    popUpMenuItem: {
        width: '100%'
    },
    popUpMenuItemIcon: {
        minWidth: 40
    },
    menuDivider: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    userProfile: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    userName: {
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1),
        fontWeight: "bold"
    },
    userTitle: {
        color: theme.palette.text.secondary,
    },
}));

export default function HeaderUserBox() {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const full_name = useSelector(state => state.auth.full_name);
    const dispatch = useDispatch();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const logoutAction = () => {
    //     dispatch(logout());
    // };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Avatar src={'/static/images/avatars/avatar_6.png'}/>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                anchorOrigin={{ vertical: 68, horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleClose}
                classes={{paper:classes.menuOverride}}
                >
                <div className={classes.popUpMenuContent}>
                    <List className={classes.popUpMenuList}>
                        <div className={classes.userProfile}>
                            <div className={classes.userName}>
                                {full_name}
                            </div>
                            <div className={classes.userTitle}>
                                System Developer
                            </div>
                        </div>
                        <Divider className={classes.menuDivider}/>

                        <ListItem className={classes.popUpMenuItem} divider button dense>
                            <ListItemIcon className={classes.popUpMenuItemIcon}>
                                <AccountCircleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="My Account" />
                        </ListItem>
                        <ListItem className={classes.popUpMenuItem} divider button dense>
                            <ListItemIcon className={classes.popUpMenuItemIcon}>
                                <SettingsIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Setting" />
                        </ListItem>
                        <ListItem className={classes.popUpMenuItem} divider button dense onClick={()=>{
                            Logout(dispatch)
                        }}>
                            <ListItemIcon className={classes.popUpMenuItemIcon}>
                                <ExitToAppIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>
                </div>
            </Menu>
        </div>
    );
}
