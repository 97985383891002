import React, {Fragment, useEffect, useState} from "react";
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, change, FieldArray, reduxForm, formValueSelector, propTypes } from 'redux-form';
import {Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@material-ui/core'
import 'antd/dist/antd.css';
import {RenderSelectField, ReduxSelectFieldComponent} from 'components/form-components/form-field/ReduxNormalSelectField';
function LabourCatSelect(props) {
  const {name, label, labour} = props;
  const workCategories = useSelector((state) => state.presets.workCategories);
  return (
    <Field
      name={name}
      label={label}
      component={ReduxSelectFieldComponent}
      // onChange={()=>props.change('report',`${labour}.staff`, [])}
    >
    {workCategories.map((e, i) => (
      <MenuItem key={i} value={e.id}>{e.name}</MenuItem>
    ))}
    </Field>
  )
}

function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
  change
};

LabourCatSelect = connect(mapStateToProps,
  mapDispatchToProps
)(LabourCatSelect)

export default LabourCatSelect;
