import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import {Button, Grid, TextField, Paper, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@material-ui/core';
import MaterialTable from 'material-table';
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import {CompanyLookUp, PositionLookUp, LabourTradeLookUp, SubTradeLookUp} from "../../../components/other-components/MaterialTable/MaterialTableLookUps";
import {Field} from "redux-form";
import {InlineStyles} from 'stylesCss';
import {APP_URL} from 'static.js';
import {CustomFilter, CustomToolBar} from "../../../components/other-components/MaterialTable/Components";
import MaterialTableBase from 'components/other-components/MaterialTable/MaterialTableCRUDBase';
import {GetStaffList} from "../../../actions/Staff";
import {getSubTrade} from "../../../actions/Presets";

function StaffTable(props) {
    const [staffList, setList] = useState();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [isLoading, setLoad] = useState(true);
    const dispatch = useDispatch();
    const [tradesList, setTradesList] = useState([])
    const [positionsList, setPositionsList] = useState([])
    const [companiesList, setCompaniesList] = useState([])

    useEffect(() => {
      GetStaffList('?page_size=10000&project='+selectedProject.id)
        .then((resp) => {
          setList(resp.data.results)
          setLoad(false);
        });
      SubTradeLookUp(selectedProject.id).then((list)=>{
        setTradesList(list)
      })
      PositionLookUp(selectedProject.id).then((list)=>{
        setPositionsList(list)
      })
      CompanyLookUp(selectedProject.id).then((list)=>{
        setCompaniesList(list)
      })
    }, [selectedProject]);

  function beforeAdd(newData){
      newData.project = selectedProject.id;
      return newData
  }
  return (
    <MaterialTableBase
        titleText='List of Staff'
        data={staffList}
        setList={setList}
        crudURL={APP_URL+'/site/staff/'}
        beforeAdd={beforeAdd}
        beforeUpdate={(a)=>a}
        CustomLoading={isLoading}
        allowFilter={true}
      columns={[
        { title: 'Staff No.', field: 'reg_no'},
        {
          title: 'Contractor',
          field: 'company',
          lookup: companiesList,
        },
        { title: 'PIN', field: 'pin' },
        { title: 'Name (Eng)', field: 'eng_name' },
        { title: 'Name (Ch)', field: 'ch_name' },
        { title: 'Monthly Salary', field: 'salary',
            render: rowData => (<span>${rowData.salary}</span>)
        },
        {
          title: 'Labour Trade',
          field: 'sub_trade',
          lookup: tradesList,
        },
        {
          title: 'Position',
          field: 'position',
          lookup: positionsList,
        },
      ]}
    />
  );
}

export default StaffTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
