import React, { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import MaterialTable, { MTableToolbar, MTableFilterRow } from 'material-table';
import { CustomToolBar, CustomFilter } from 'components/other-components/MaterialTable/Components';
import tableIcons, {whiteTableIcons} from 'components/other-components/MaterialTable/TableIcons';
import { ContractorLookUp, StaffLookUp, LabourTradeLookUp } from "../../../components/other-components/MaterialTable/MaterialTableLookUps";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { InlineStyles } from 'stylesCss';
import { APP_URL } from 'static';
import PropTypes from "prop-types";
import { dateToString } from "Page/UI_Design/js_utils/general";
import { Box, TablePagination, Typography } from '@material-ui/core';
import OverlaySuccess from "../../../components/other-components/OverlaySuccess";
import { TableLoader } from "../../../components/other-components/TableLoader";
import { WrapperBox } from "../../../components/layout-components/Content";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {BackEndRequestFullURL, defaultHeaders} from "../../../actions/APICalls";
import MaterialTableBase from 'components/other-components/MaterialTable/MaterialTableCRUDBase';

function RedLockIcon() { return (<LockIcon style={{ color: '#B00020' }} />) }

function AdminMaterialTableCRUDBase(props) {
    const { titleText, data, setList, crudURL, beforeAdd, beforeUpdate, allowFilter, CustomLoading, blueHeader } = props
    const dispatch = useDispatch()
    const tableRef = React.createRef();
    const [overlayVis, setVis] = useState(false);
    const [successMsg, setMsg] = useState('');

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.dataManager.changePageSize(10)
        }
    }, [tableRef.current])

    return (
        <div>
            {CustomLoading ?
                <TableLoader />
                : null}
            <OverlaySuccess message={successMsg} setVis={setVis} overlayVis={overlayVis} />
            <MaterialTable
                icons={blueHeader ? whiteTableIcons : tableIcons}
                tableRef={tableRef}
                title={<Typography color='primary' style={{ fontWeight: 500 }}>{titleText}</Typography>}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    filtering: { allowFilter },
                    rowStyle: rowData => ({
                        // fontSize: InlineStyles.materialTables.row.fontSize,
                        backgroundColor: rowData.soft_deleted ? '#EEE' : '#FFF'
                    }),
                    sorting: true
                }
                }
                components={{
                    Container: props => <WrapperBox>{props.children}</WrapperBox>,
                    FilterRow: props => <CustomFilter {...props} />,
                    Toolbar: props => (
                        <CustomToolBar {...props} />
                    ),
                    Pagination: props => (
                        <TablePagination
                            {...props}
                        />
                    )
                }}
                actions={
                    [allowFilter ?
                        {
                            icon: tableIcons.Filter,
                            tooltip: 'Filter',
                            isFreeAction: true,
                            onClick: () => { dispatch({ 'type': 'TOGGLEFILTER' }); },
                        } : null,
                    (rowData) => {
                        return rowData.soft_deleted
                            ? {
                                icon: RedLockIcon, disable: false, onClick: () => {
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            console.log(rowData)
                                            const index = rowData.id;
                                            BackEndRequestFullURL('patch', crudURL+index+'/', defaultHeaders, {'soft_deleted':false}).then(response => {
                                                const dataUpdate = [...data];
                                                const index = rowData.tableData.id;
                                                dataUpdate[index] = response.data;
                                                setList([...dataUpdate]);
                                                setVis(true)
                                                setMsg('Record unlocked and will be visible to users')
                                            })
                                                .catch(error => {
                                                    console.log(error)
                                                    console.log(error.response);
                                                });
                                            resolve();
                                        }, 1000)
                                    })
                                }
                            }
                            : {
                                icon: LockOpenIcon, disable: false, onClick: () => {
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            console.log(rowData)
                                            const index = rowData.id;
                                            BackEndRequestFullURL('patch', crudURL+index+'/', defaultHeaders, {'soft_deleted':true}).then(response => {
                                                const dataUpdate = [...data];
                                                const index = rowData.tableData.id;
                                                dataUpdate[index] = response.data;
                                                setList([...dataUpdate]);
                                                setVis(true)
                                                setMsg('Record locked and will no longer be visible to users')
                                            })
                                                .catch(error => {
                                                    console.log(error)
                                                    console.log(error.response);
                                                });
                                            resolve();
                                        }, 1000)
                                    })
                                }
                            }
                    }
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                // newData.staff_id = newData.staff.id;
                                // newData.project_id = selectedProject.id;
                                console.log(newData)
                                newData = beforeAdd(newData)
                                // console.log(newData)
                                BackEndRequestFullURL('post', crudURL, defaultHeaders, newData).then(response => {
                                    setList([...data, response.data]);
                                    setVis(true)
                                    setMsg('Added Successfully')
                                })
                                    .catch(error => {
                                        console.log(error)
                                        console.log(error.response);
                                    });
                                // setData([...data, newData]);
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const index = oldData.id;
                                // newData.staff_id = newData.staff.id;
                                newData = beforeUpdate(newData)
                                // console.log(newData)
                                BackEndRequestFullURL('put', crudURL+index+'/', defaultHeaders, newData).then(response => {
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = response.data;
                                    setList([...dataUpdate]);
                                    setVis(true)
                                    setMsg('Updated Successfully')
                                })
                                    .catch(error => {
                                        console.log(error)
                                        console.log(error.response);
                                    });
                                resolve();
                            }, 1000)
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //       setTimeout(() => {
                    //         const index = oldData.id;
                    //         axios.delete(crudURL+index+'/', {}).then(response => {
                    //           console.log(response);
                    //           const dataDelete = [...data];
                    //           const index = oldData.tableData.id;
                    //           dataDelete.splice(index, 1);
                    //           setList([...dataDelete]);
                    //           setVis(true)
                    //           setMsg('Deleted Successfully')
                    //         })
                    //             .catch(error => {
                    //               console.log(error)
                    //               console.log(error.response);
                    //             });
                    //         resolve()
                    //       }, 1000)
                    //     }),
                }}
                {...props}
            />
        </div>
    )
}

export default AdminMaterialTableCRUDBase;
