import React, { useState, useEffect, Fragment, useCallback } from 'react';
import PropTypes from "prop-types";
import { fade, makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import MainDrawer from "../MainDrawer";
import { useDispatch, useSelector } from "react-redux";
import SysBreadcrumbs from "components/layout-components/SysBreadcrumbs";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import HeaderUserBox from "components/layout-components/HeaderUserBox";
import ReminderBadge from "components/layout-components/HeaderBadge/ReminderBadge";
import LoginView from 'Page/UI_Design/Login/LoginView';
import { motion } from "framer-motion";
import clsx from 'clsx';
import axios from "axios";
import LogoPng from "../../components/siteDiary_blue_text@2x.png";
import { Outlet } from 'react-router';
import {LoadUserDetails} from "../../actions/Auth";
import {GetNotificationsList, GetNotificationsNo} from "../../actions/Notifications";
import '../../fontawesome';
import {ClipLoader,FadeLoader,  PulseLoader} from "react-spinners";
import { CircularProgress} from '@material-ui/core';
const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    logo_div: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    logo: {
        width: drawerWidth,
        height:'64px', 
        padding: '11px 16px'
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        fontSize: '1rem'
    },
    appBar: {
        // [theme.breakpoints.up('md')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     marginLeft: drawerWidth,
        // },
        backgroundColor: theme.palette.background.paper,
        backgroundSize: "cover",
        paddingRight: "0!important",
        height: 64,
        zIndex: 1300,
    },

    menuButton: {
        marginRight: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    allProjectButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    currentProjectCode: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.dark,
        // borderRight: '1px solid lightgray',
        borderRight: 0,
        color: theme.palette.primary.contrastText
    },
    content: {
        flexGrow: 1,
        minHeight: '100vh',
        // padding: theme.spacing(3),
        overflow: 'auto'
    },
    pageContent: {
        padding: theme.spacing(3),
        flex: 'auto',
        minWidth: 980,
    },
    langSelector: {
        textAlign: 'center',
        minWidth: '70px',
    },
    divisionSelector: {
        width: "100%",
        maxWidth: "180px",
        textTransform: "uppercase"
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(1),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function BKMainBar(props) {
    const {
        children
    } = props;
    const dispatch = useDispatch();
    const loggedIn = JSON.parse(localStorage.getItem('LOGGED_IN'))||false;


    const [mobileOpen, setMobileOpen] = useState(false);
    const classes = useStyles();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const actionLoading = useSelector((state) => state.nav.actionLoading);
    // const isAuth = useSelector(state => state.auth.isAuth);
    const pageLoading = useSelector((state) => state.nav.pageLoading);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    };

    useEffect(() => {
        if (loggedIn) {
            LoadUserDetails(dispatch);
        }
    }, []);

    useEffect(() => {
        if (loggedIn && selectedProject.id) {
            const params = '?project='+selectedProject.id+'&page_size=5'
            GetNotificationsList(dispatch, params);
            GetNotificationsNo(dispatch, selectedProject.id);
            try {
                const interval = setInterval(async () => {
                    GetNotificationsNo(dispatch, selectedProject.id);
                }, 30000);
                return () => clearInterval(interval);
            } catch (e) {
                console.log(e);
            }
        }
    }, [selectedProject]);

    // const loggedIn = useSelector((state) => state.auth.loggedIn);

    if (!loggedIn) {
        return (<LoginView />)
    }
    else {
        return (
            <div className={classes.root}>
                <div style={{visibility:pageLoading?'visible':'hidden'}}>
                    <div style={{opacity:'30%', backgroundColor:'#39344A', width:'100%', height:'100%', position:'fixed', zIndex:'99'}}>
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{width: '300px', height: '160px', position:'absolute', left:'60%', top:'50%', transform:'translate(-50%, -60%)',   zIndex:'1000'}}>
                        <CircularProgress color='primary' className='m-auto' />
                    </div>
                </div>
                <div style={{visibility:actionLoading?'visible':'hidden'}}>
                    <div style={{opacity:'40%', backgroundColor:'#39344A', width:'100%', height:'100%', position:'fixed', zIndex:'99999'}}>
                    </div>
                    <div className="d-flex align-items-center justify-content-center" style={{width: '300px', height: '160px', position:'absolute', left:'50%', top:'50%', transform:'translate(-50%, -60%)',   zIndex:'1000'}}>
                        <PulseLoader color={'var(--primary)'} loading={true}/>
                    </div>
                </div>
                <AppBar position="fixed" color="default" className={classes.appBar} key={'AppBar'}>
                    <Toolbar className='pl-0'>
                        <div className={classes.logo_div}>
                            <img src={LogoPng} className={classes.logo} />
                        </div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>

                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>

                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                        >
                            <ReminderBadge />
                            <HeaderUserBox />
                        </Grid>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} key={'AppDrawer'}>
                    <Hidden mdUp implementation="css">
                        <Drawer
                            // container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            ModalProps={{
                                keepMounted: true // Better open performance on mobile.
                            }}
                        >
                            <MainDrawer />
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper
                            }}
                            variant="permanent"
                            open
                        >
                            <MainDrawer />
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={`${classes.content} d-flex flex-column justify-content-between`}>
                    <div className={classes.toolbar} />
                    <div className={classes.pageContent}>
                        {/* <SysBreadcrumbs /> */}
                        {selectedProject.id?
                            <Outlet />
                        :null}
                    </div>
                </main>
            </div>
        );
    }
};

BKMainBar.propTypes = {
    children: PropTypes.element.isRequired
};
