import React, { useEffect, useState } from "react";
import { Field, FieldArray, reduxForm } from 'redux-form';
import { IconButton, Button, Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Divider, Hidden } from '@material-ui/core'
import 'antd/dist/antd.css';

import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxRequired } from "components/form-components/validation";
import { ReduxNormalSelectField } from "../../../../components/form-components/form-field/ReduxNormalSelectField";
import { ReduxAutoCompleteFieldByValue} from "../../../../components/form-components/form-field/ReduxAutoCompleteFieldByValue";
import { useSelector } from "react-redux";
import Add from "@material-ui/icons/Add";
import { RemoveCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { GeneralAddMore } from "components/other-components/Icons";
import { required, number, maxLength } from 'components/form-components/validation/fields';

const useStyles = makeStyles(theme => ({
    //   add: theme.styles.miniAddMore.add,
    //   addButton: theme.styles.miniAddMore.addButton
}))

const maxLength100 = maxLength(100)

function ActivityFormMaterials({ fields }) {
    const materials = useSelector((state) => state.presets.materials);
    const companies = useSelector((state) => state.presets.companies);
    const classes = useStyles();
    return (
        <Grid item xs={9} container spacing={4}>
            {fields.map((material, index) =>
                <Grid item xs={12} container direction='row' spacing={3} key={'materialRowGrid' + index}>
                    <Grid item xs={10} lg={7}>
                        <ReduxAutoCompleteFieldByValue
                            name={`${material}.material`}
                            label={"Material*"}
                            validate={[ReduxRequired]}
                            dataSet={materials}
                            getOptionLabel={(option) => (option.description)}
                            dataFieldName={'id'}
                        >
                        </ReduxAutoCompleteFieldByValue>
                    </Grid>
                    <Hidden lgUp>
                        <Grid item xs={2} className='text-right'>
                            <IconButton
                                onClick={() => fields.remove(index)}
                            // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                            >
                                <RemoveCircle style={{ color: '#AFA4C8' }} />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} lg={3}>
                        <ReduxTextField
                            name={`${material}.quantity`}
                            label="Quantity*"
                            validate={[ReduxRequired, number]}
                        />

                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={2} className='text-right'>
                            <IconButton
                                onClick={() => fields.remove(index)}
                            // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                            >
                                <RemoveCircle style={{ color: '#AFA4C8' }} />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} lg={5}>
                        <ReduxTextField
                            name={`${material}.description`}
                            label="Description"
                            validate={[maxLength100]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={5}>
                        <ReduxAutoCompleteFieldByValue
                             getOptionLabel={(option) => (option.name)}
                             multiple={false}
                             name={`${material}.mc_sc`}
                             label={"BK/SC"}
                             validate={[]}
                             dataSet={companies}
                             dataFieldName={'id'}
                        >
                        </ReduxAutoCompleteFieldByValue>
                    </Grid>
                    <Grid item xs={10}>
                        { index>=0 && index!=fields.length-1 ? 
                            <div style={{ border: '1px #AFA4C8 dashed' }}></div>
                            :
                            <Divider className='' style={{ backgroundColor: '#AFA4C8' }} />
                        }
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={10}>
                    <GeneralAddMore onClick={()=>fields.push({})} />
                </Grid>
            </Grid>
            {/* <Button onClick={() => fields.push({})} className={classes.addButton}><Add className={classes.add} />ADD MORE</Button> */}

        </Grid>
    )
}

export default ActivityFormMaterials;