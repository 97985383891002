const initialState = {
  notifications:[],
  unreadCount:0
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NOTIFICATIONS':
      return {...state, notifications: action.payload};
    case 'NOTIFICATIONSNO':
      return {...state, unreadCount: action.payload};
    case 'NOTIFICATIONSREADONE':
      return {...state, unreadCount: state.unreadCount - 1};
    default:
      return state;
  }
};

export default NotificationReducer;
