import React from 'react';

function CustomDatePickerOverlay({ classNames, selectedDay, children, ...props }) {
    return (
        <div
            className={classNames.overlayWrapper}
            style={{
                zIndex: 100,
                marginLeft: -400,
                // position: 'absolute'
            }}
            {...props}
        >
            <div className={classNames.overlay}>
                {children}
            </div>
        </div>
    );
}

export default CustomDatePickerOverlay;