import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { Field } from "redux-form";
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
    label: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        right: 22, //do not overlap icon
        bottom: 0 //maximize container height so label wont be cut horizontaly
    },

    labelShrinked: {
        right: 'unset' //show complete label if shrinked
    }
}));

export const ReduxTextFieldComponent = ({ input, meta: { touched, error }, ...rest }) => {
    const classes = useStyle();
    return (
        <TextField
            fullWidth
            error={touched && Boolean(error)}
            helperText={touched && error ? error : null}
            {...input}
            {...rest}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    shrink: classes.labelShrinked,
                }
            }}
        // autoComplete='off'
        />
    )
};

export const ReduxTextField = props => {
    return (
        <Field
            variant="outlined"
            component={ReduxTextFieldComponent}
            {...props}
            name={props.name || props.input.name}
        />
    )
};
