import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
// import system_logo from '../../../images/logo.png';
import UserProfile from "components/layout-components/UserProfile";
import Logo from 'components/Logo';
import SidebarMenu from "components/layout-components/SidebarMenu";
import LogoPng from "../../components/siteDiary_blue_text@2x.png";
import { Divider, Typography } from '@material-ui/core';
import DateNav from 'components/layout-components/SidebarMenu/NavDate';
import BottomMenu from 'components/layout-components/SidebarMenu/bottomMenu';
import ProjectSwitch from 'components/layout-components/SidebarMenu/ProjectSwitch';
import ProjectDesc from 'components/layout-components/SidebarMenu/ProjectDesc';

const useStyles = makeStyles(theme => ({
    drawer_content: {
        display: 'flex !important',
        flexDirection: 'column !important',
        overflow: "auto",
        height: '100%',
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
        '&::-webkit-scrollbar': {
            width: "0",
            background: "transparent"
        }
    },
    toolbar: theme.mixins.toolbar,
}));


export default function MainDrawer(props) {
    const classes = useStyles();
    return (
        <div className={classes.drawer_content}>
            <div style={{ backgroundColor: '#fff', justify: 'center', textAlign: 'left' }} className={classes.toolbar}>
                <img src={LogoPng} className='w-100' style={{ height: '64px', padding: '11px 16px' }} />
            </div>
            <UserProfile />
            <Divider style={{ backgroundColor: '#adadad' }} />
            
            <Typography className='px-4 pt-3 font-size-sm' style={{ color: '#adadad' }}>Main</Typography>
            <SidebarMenu />

            <div className='mt-auto' />
            
            <Typography className='px-4 pt-3 font-size-sm' style={{ color: '#adadad' }}>Date</Typography>
            <DateNav />
            
            <div className='d-flex flex-row px-4 pt-3'>
                <Typography className='mr-auto font-size-sm' style={{ color: '#adadad' }}>Project</Typography>
                <ProjectSwitch />
            </div>
            <div className='px-4 pb-3 pt-2 font-size-sm'>
                <ProjectDesc />
            </div>

            <Divider style={{ backgroundColor: '#adadad' }} />
            <BottomMenu />
        </div>
    );
}