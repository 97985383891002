import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
  Box, InputLabel, Tabs, Tab
} from '@material-ui/core';
import {useParams} from "react-router";
// import MaterialTable from "material-table";
// import tableIcons from "../components/MaterialTable/TableIcons";
// import ActivityDetailsPopUp from "../Activity/ActivityList/ActivityDetailsPopUp";
// import {InlineStyles} from "../../../stylesCss";
// import {makeStyles} from "@material-ui/core/styles";
// import Add from "@material-ui/icons/Add";
// import {APP_URL} from 'static';
import ActivityViewTable from 'Page/UI_Design/Activity/ActivityList/ActivityViewTable';
import {APP_URL} from "../../../static.js";
import {GetActivityList} from "../../../actions/Activity";
import {GetReport} from "../../../actions/Report";
// function ReportActivityTable() {
//   const selectedReport = useSelector((state) => state.report.selectedReport);
//   const activityList = useSelector((state) => state.report.activityList);
//   const tableRef = React.createRef();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//
//   return (
//     <MaterialTable
//       icons={tableIcons}
//       title=<div style={InlineStyles.materialTables.title}>List of Activities</div>
//       tableRef={tableRef}
//       columns={[
//         { title: 'Date', field: 'date'},
//         {
//           title: 'Location',
//           field: 'location',
//           render: rowData => (
//             <span>{rowData.location.name}</span>
//           ),
//         },
//         {
//           title: 'Sub Location',
//           field: 'sub_location',
//           render: rowData => (
//             <span>{rowData.sub_location ? rowData.sub_location.name : ''}</span>
//           ),
//         },
//         { title: 'Main Activities',
//           field: 'details',
//           render: rowData => (
//           <React.Fragment>
//             {rowData.details.map((e, i) => (
//               <React.Fragment><span>{i+1}. {e.activity_category.name}:{e.activity_remarks}</span><br/></React.Fragment>
//             ))}
//           </React.Fragment>
//           ),
//           minWidth: 300
//         },
//         { title: 'Time Start', field: 'time_start',  },
//         { title: 'Time End', field: 'time_end' },
//         {
//           title: 'Labours',
//           field: 'labours',
//           render: rowData => (
//             <ActivityDetailsPopUp rowData={rowData} selectType={0}/>
//           ),
//         },
//         {
//           title: 'Plants',
//           field: 'plants',
//           render: rowData => (
//             <ActivityDetailsPopUp rowData={rowData} selectType={1}/>
//           ),
//         },
//         {
//           title: 'Materials',
//           field: 'materials',
//           render: rowData => (
//             <ActivityDetailsPopUp rowData={rowData} selectType={2}/>
//           ),
//         },
//       ]}
//       components={{
//         Container: props => <div>{props.children}</div>
//       }}
//       options={{
//         detailPanelColumnAlignment: 'right',
//       }}
//       data = {activityList}
//       actions={[
//         {
//           icon: tableIcons.Add,
//           tooltip: 'Add Activity',
//           isFreeAction: true,
//           onClick: (event) => navigate('/app/activity_form/none')
//         }
//       ]}
//     />
//   );
// }

function ReportViewPage3() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([])

  let { id } = useParams();

  useEffect(() => {
    GetReport(id).then((resp)=>{
      GetActivityList('?date=' + resp.data.date+'&review_status=approved').then((resp)=>{
        setActivityList(resp.data.results)
      })
    })
  },[])

  return (
      <ActivityViewTable dataSet={activityList} isLoading={false}/>
  );
}
export default ReportViewPage3;
