import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    Stepper,
    Step,
    StepButton,
    Paper,
} from '@material-ui/core';
import { useSelector, useDispatch, connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { change, reduxForm } from "redux-form";
import Step1 from 'Page/UI_Design/MonthlyReport/Step1';
import Step2 from 'Page/UI_Design/MonthlyReport/Step2';
import Step3 from 'Page/UI_Design/MonthlyReport/Step3';
import { GeneralButton } from 'components/other-components/Icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import axios from "axios";
import { APP_URL } from "../../../static";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import StepConnector from "@material-ui/core/StepConnector";
import StepLabel from "@material-ui/core/StepLabel";
import SettingsIcon from "@material-ui/icons/Settings";
import { ContentBox } from "../../../components/layout-components/Content";

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function ConstructionForm(props) {
    const { handleSubmit, pristine, reset, submitting } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Project Status', 'Vaccancies', 'Preview'];
    const step3Ref = useRef();
    let { id } = useParams();
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo(0, 0);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    function renderStepSwitch(activeStep, props) {
        switch (activeStep) {
            case 0:
                return (<Step1 props={props} />)
            case 1:
                return (<Step2 props={props} />)
            case 2:
                return (<Step3 props={props} handleBack={handleBack} id={id} />)
            default:
                return (<span></span>)
        }
    }

    useEffect(() => {
        console.log(step3Ref.current);
    }, [step3Ref]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <span className='font-size-lg font-weight-bold'>Return on Construction Site Employment <span className='text-primary'>(GF527A)</span></span>
            </Grid>
            <Grid item xs={12}>
                <Paper className='p-4'>
                    <Stepper alternativeLabel activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(index)}
                                    style={{ top: '1px', iconContainer: { transform: 'scale(2)', fontSize: '6em' } }}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    {renderStepSwitch(activeStep, props)}
                    {activeStep == 2 ?
                        null
                        :
                        <Grid item xs={12} container direction='row' justify={'space-between'} className='mt-4'>

                            <Grid item xs={3}>
                                {activeStep != 0 ?
                                    <Button style={{
                                        color: '#15B1D7',
                                        textTransform: 'none'
                                    }}
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                    >
                                        <ArrowBackIosIcon />
                                        <Typography>Previous Section</Typography>
                                    </Button>
                                    : null}
                            </Grid>

                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                {activeStep != 2 ?
                                    <GeneralButton label={'Next'} onClick={handleNext} style={{ width: '200px' }} />
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Grid>
        </Grid>
    );
}

export default reduxForm({
    form: 'construction', // a unique identifier for this form
})(ConstructionForm);

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
