import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    InputAdornment, FormControlLabel, Checkbox, Card, CardContent, CircularProgress
} from '@material-ui/core';
import { ReduxRequired } from "../../../components/form-components/validation";
import { ReduxTextField } from "../../../components/form-components/form-field/ReduxTextField";
import { reduxForm } from "redux-form";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { APP_URL } from "../../../static";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Select, MenuItem } from "@material-ui/core";
import hospitalSvg from 'icons/hospital.svg';
import diarySvg from 'icons/diary.svg';
import cerebroLtdSvg from 'icons/cerebro_limited.svg';
import LogoPng from 'components/siteDiary_blue_text@2x.png';
import LangSvg from 'icons/lang.svg';
import HelpSvg from 'icons/help.svg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Alert from '@material-ui/lab/Alert';
import {LoginRequest} from "../../../actions/Auth";

i18next.use(LanguageDetector).init();
const languageDetector = new LanguageDetector();

const LoginView = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.form);
    const errorMsg = useSelector((state) => state.auth.error_message);
    // const [errorMsg, setErrorMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    const language = i18n.language;
    function login(){
        setLoading(true);
        // const url = APP_URL + '/site/auth/token/login'
        const loginData = {
            email: formData.login.values.email,
            password: formData.login.values.password
        }
        LoginRequest(dispatch, loginData)
        // axios.post(url, loginData, {headers: {'Authorization': ``}})
        //     .then(
        //         (resp) => {
        //             dispatch({'type': 'LOGIN', 'payload':resp.data.auth_token});
        //             dispatch({'type':'SELECTPROJECT','payload':{}});
        //             window.location.reload(false);
        //         }
        //     )
        //     .catch(function (error) {
        //         if (error.response){
        //             setErrorMsg(error.response.data.non_field_errors[0]);
        //             console.log(error.response.data.non_field_errors[0]);
        //         }else{
        //             setErrorMsg('Server Error');
        //             console.log(error.response)
        //         }
        //     })
        // http://127.0.0.1:8000/site/auth/token/login
    }
    const handlePassword = () => {
        const tempShowPassword = !showPassword;
        setShowPassword(tempShowPassword);
    }
    useEffect(() => {
        if (!['en', 'zh-Hant'].includes(i18n.language)) {
            changeLanguage('en')
        }
    }, [])

    return (
        <Card className='card-box w-100 shadow-none border-0 rounded-0 vh-100 d-flex flex-column' style={{ backgroundColor: '#F2FFFE' }}>
            <div className='card-header border-0 mr-5 pr-5' style={{ backgroundColor: '#F2FFFE' }}>
                <div className='ml-auto mr-5 card-header--actions'>
                    <img src={LangSvg} className='mr-2' />
                    <Select labelId="label" id="language" disableUnderline value={language} onChange={(e) => changeLanguage(e.target.value)}>
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="zh-Hant">繁體中文</MenuItem>
                    </Select>
                    <img className='mx-2' src={HelpSvg} />
                </div>
            </div>
            <CardContent className='mt-auto'>
                <Grid container alignItems='center' justify='center' className='h-100' direction='row'>
                    <Grid item xs={12} className='text-center mt-5'><img className='' src={diarySvg} /></Grid>
                    <Grid item xs={12} className='text-center'><img style={{ fill: 'black', height: '64px' }} src={LogoPng} /></Grid>
                    <Grid item className='mt-5' style={{ width: 500 }}>
                        <Box boxShadow={3} className='px-5 pb-5 pt-4 text-center' style={{ backgroundColor: '#FFF' }}>
                            <form onSubmit={handleSubmit(() => login())}>
                                <p className='font-weight-bold h4 my-4'>{t('Login')}</p>
                                <Container className='my-2'>
                                    <ReduxTextField
                                        name={"email"}
                                        label={t('Email')}
                                        validate={[ReduxRequired]}
                                    />
                                </Container>
                                <Container className='my-2'>
                                    <ReduxTextField
                                        name={"password"}
                                        label={t('Password')}
                                        type={'password'}
                                        validate={[ReduxRequired]}
                                        type={showPassword ? 'text' : 'password'}
                                        className='my-2'
                                        InputProps={{ // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <VisibilityIcon
                                                        style={{ color: 'gray', cursor: 'pointer' }}
                                                        onMouseOver={handlePassword}
                                                        onMouseOut={handlePassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </VisibilityIcon>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {errorMsg ?
                                        <Alert severity="error">{errorMsg}</Alert>
                                        : null
                                    }
                                </Container>

                                <Container className='my-2 text-left'>
                                    <FormControlLabel
                                        style={{ color: "gray" }}
                                        className='mb-2'
                                        control={
                                            <Checkbox
                                                color="primary"
                                            />
                                        }
                                        label={t('Remember_me')}
                                    />
                                </Container>
                                <Container className='my-2'>
                                    <span className="btn-loading-wrapper shadow-none">
                                        <Button
                                            size='large'
                                            className='btn-primary btn-transition-none mb-3'
                                            // variant='contained'
                                            // color='primary'
                                            fullWidth
                                            type="submit"
                                            disabled={loading}
                                        >
                                            <span className="btn-wrapper--label">{t('Login')}</span>
                                        </Button>
                                        {loading && <CircularProgress size={30} className="text-white" style={{margin: '-15px 0 0 -15px'}} />}
                                    </span>
                                    <Link href="/forgotpassword">{t('Forgot_password')}</Link>
                                </Container>

                            </form>

                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
            <div className='card-footer d-flex flex-row text-center shadow-none border-0 rounded-0 mt-auto pb-0' style={{ backgroundColor: '#F2FFFE' }}>
                <div className='mr-auto py-4 px-5'><img src={cerebroLtdSvg} height={60} /></div>
            </div>
        </Card>
    );
};

// export default LoginView;

export default reduxForm({
    form: 'login', // a unique identifier for this form
})(LoginView);
