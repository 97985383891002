import React, {Fragment} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Field} from "redux-form";
import LabourCatSelect from "./LabourCatSelect";

function ReduxAnotherAutoCompleteTextFieldComponent(props) {
  const {
    input,
    label,
    dataSet,
    optionLabel,
    valueField,
    targetFieldName,
    multiple,
    limitTags,
    required,
    onChange,
    labour,
    meta: {touched, error, warning},
    ...rest
  } = props
  return (
    <Fragment>
      <Autocomplete
        multiple={multiple}
        fullWidth
        limitTags={limitTags || -1}
        options={dataSet || []}
        getOptionSelected={(option, value) => option[optionLabel] === value[optionLabel]}
        getOptionLabel={(option) => option[optionLabel]}
        filterSelectedOptions
        onChange={(event, value) => {
          input.onChange(value.map((e) => (e)))
        }
        }
        renderInput={(params) => (
          <TextField
            error={touched && Boolean(error)}
            {...params}
            variant="outlined"
            label={label}
            helperText={touched && error ? error : null}
          />
        )}
        {...rest}
      />
    </Fragment>
  )
}

export function ReduxAnotherAutoCompleteTextField(props){
    return (
        <Field
            component={ReduxAnotherAutoCompleteTextFieldComponent}
            {...props}
        />
    )
}
