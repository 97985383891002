import Highcharts from "highcharts";

export const colorSet = (function () {
    var colors = [],
        base = '#15B1D7',
        i;

    for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.color(base).brighten((i - 1) / 9).get());
    }
    return colors;
}());

export const colorSet2 = (function () {
    var colors = [],
        base = '#DAD0EC',
        i;

    for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.color(base).brighten((i - 7) / 16).get());
    }
    return colors;
}());

