import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { GeneralButton } from 'components/other-components/Icons';
import {
    Button,
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box, InputLabel, Typography, InputAdornment
} from '@material-ui/core';
import { ContentBox } from 'components/layout-components/Content';
import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxDateField } from "components/form-components/form-field/ReduxDateField";
import { ReduxRequired } from "components/form-components/validation";
import { ReduxTextAreaField } from "components/form-components/form-field/ReduxTextAreaField";
import { change, initialize, reduxForm } from "redux-form";
import { useParams } from "react-router";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {APP_URL} from 'static.js';
import {required, number, maxLength} from 'components/form-components/validation/fields';
import {GetReport, UpdateReport, GetWeatherData} from "../../../actions/Report";
import {displayCORSWarning} from "react-pdf/dist/umd/shared/utils";

const useStyles = makeStyles(theme => ({
    //   title: theme.styles.font.primary,
    //   frame: theme.styles.page.frame,
    //   content: theme.styles.page.content,
    //   button: theme.styles.buttons,
}))

  const maxLength100 = maxLength(100)
  const maxLength1000 = maxLength(1000)

function BasicFormSection(props){
      const {title, input_section} = props
      return (
        <Grid item container direction='row' spacing={3}>
            <Grid item xs={2}>
                <Typography style={{ fontFamily: 'Roboto', fontSize: '1rem', fontWeight: '500', color: '#15B1D7' }}>{title}</Typography>
            </Grid>
            <Grid container item xs={10}>
                {input_section}
            </Grid>
        </Grid>
    )
}

function WeatherSection() {
    return (
        <React.Fragment>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={6} lg={4}>
                    <ReduxDateField
                        name={"date"}
                        label={"Date"}
                        validate={[ReduxRequired]}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <ReduxTextField
                        name={"warning_signal"}
                        label="Typhoon / Rainstorm Warning Signal"
                        validate={[maxLength100]}
                    />
                </Grid>
                <Grid item xs={0} lg={4}></Grid>
                <Grid item xs={6} lg={4}>
                    <ReduxTextField
                        name={"weather_am"}
                        label="Weather (A.M)"
                        validate={[maxLength100]}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <ReduxTextField
                        name={"weather_pm"}
                        label="Weather (P.M)"
                        validate={[maxLength100]}
                    />
                </Grid>
                <Grid item xs={6} lg={4}>
                    <ReduxTextField
                        name={"rainfall"}
                        label="Rainfall"
                        validate={[ReduxRequired, number]}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

function WarningSection() {
    return (
        <React.Fragment>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Typography style={{ fontWeight: '500' }} className='mb-2'>Typhoon Signal and Rainstorm Warning Signal Information</Typography>
                    <ReduxTextAreaField
                        name={'warning_remarks'}
                    // label={"Details / Remarks"}
                      validate={[maxLength1000]}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography style={{ fontWeight: '500' }} className='mb-2'>Traffic Conditions Information</Typography>
                    <ReduxTextAreaField
                        name={'traffic_remarks'}
                    // label={"Details / Remarks"}
                        validate={[maxLength1000]}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography style={{ fontWeight: '500' }} className='mb-2'>Comments by Contractor&#39;s Representative / Supervisor&#39;s Delegate</Typography>
                    <ReduxTextAreaField
                        name={'comments'}
                    // label={'Comments'}
                        validate={[maxLength1000]}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography style={{ fontWeight: '500' }} className='mb-2'>Visitor</Typography>
                    <ReduxTextAreaField
                        name={'visitor'}
                    // label="Details / Remarks"
                        validate={[maxLength1000]}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <Typography style={{ fontWeight: '500' }} className='mb-2'>Remarks</Typography>
                    <ReduxTextAreaField
                        name={'remarks'}
                    // label="Remarks"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
function ReportForm(props) {
  const { handleSubmit, pristine, reset, submitting } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const page = useSelector((state) => state.project.page);
  const report = useSelector((state) => state.project.report);
  const formData = useSelector((state) => state.form);
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const classes = useStyles();
//  const projectList = useSelector((state) => state.project.projectList);

  useEffect(() => {
    dispatch({type:'SETPAGELOADING', payload:true})
    GetReport(id).then((resp) => {
      let report_data = resp.data
      GetWeatherData().then((weather_data) => {
        if(!report_data.rainfall){
          report_data['rainfall'] = weather_data.rainfall
        }
        if(!report_data.weather_am && !report_data.weather_pm){
          report_data['weather_am'] = weather_data.temperature
          report_data['weather_pm'] = weather_data.temperature
        }
        if(!report_data.warning_signal){
          report_data['warning_signal'] = weather_data.warning
        }
        props.initialize('report', report_data)
        dispatch({type:'SETPAGELOADING', payload:false})
      }).catch((err)=>{
        props.initialize('report', report_data)
        dispatch({type:'SETPAGELOADING', payload:false})
      })
      }
    )
  },[])


    function ProjectDetailsSection() {
        return (
            <Grid container direction='row' spacing={1}>
                <Grid item xs={3}>
                    <TextField
                        label={'Contract No.'}
                        variant={'outlined'}
                        name={"contract_no"}
                        value={selectedProject.contract_no}
                        fullWidth
                        disabled={true}
                    />
                </Grid>
            </Grid>
        )
    }


  function createReport(){
    const reportData = formData.report.values;
    reportData.project = selectedProject.id;
    if (reportData.date instanceof Date) {
        reportData.date = dateToString(reportData.date, '-');
    }
    UpdateReport(id, reportData)
      .then(
        (resp) => {
            navigate('/app/report');
        }
      )
      .catch(function (error) {
        console.log(error.response);
      }
    );
  }

    return (

        <form onSubmit={handleSubmit(() => createReport())}>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={12}>
                    <span className='font-size-lg font-weight-bold'>Daily Report</span>
                </Grid>
                <Grid item xs={12}>
                    <Paper className='p-4'>
                        <Grid container spacing={5}>
                            <BasicFormSection title={'Project details'} input_section={<ProjectDetailsSection />} />
                            <BasicFormSection title={'Date and Weather'} input_section={<WeatherSection />} />
                            <BasicFormSection title={'Report Information'} input_section={<WarningSection />} />
                            <Grid item>
                                <GeneralButton type='submit' label={'create & submit'}></GeneralButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    );
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
    change, initialize
};

ReportForm = connect(mapStateToProps,
    mapDispatchToProps
)(ReportForm)


export default reduxForm({
    form: 'report', // a unique identifier for this form
})(ReportForm);


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
