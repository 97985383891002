const initialState = {
  adminMode:false,
  actionLoading:false,
  pageLoading:false
};

const NavReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETMODE':
      return {...state, adminMode: action.payload};
    case 'SETPAGELOADING':
      return {...state, pageLoading: action.payload};
    case 'SETACTIONLOADING':
      return {...state, actionLoading: action.payload};
    default:
      return state;
  }
};

export default NavReducer;
