export const InlineStyles = {
    materialTables: {
        title: {
            marginLeft: '0px',
            marginTop: '0px',
            fontFamily: 'Roboto',
            color: '#15B1D7',
            fontSize: '1.1rem',
            fontWeight: '500',
        },
        row: {
            fontSize: '0.75rem'
        }
    },
}

export const generalSpacing = {
    padding: '1.3rem 1.5rem'
}
