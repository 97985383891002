import moment from "moment";

const initialState = {
  'staffList':[],
  'plants':[],
  'locations':[],
  'subLocations':[],
  'companies':[],
  'labourTrades': [],
  'activityCategories': [],
  'materials': [],
  'workCategories': [],
  'contractors': [],
  'positions': [],
  'selectedLocation': {},
  'selectedPlant': {},
  'selectedLabour': {},
};

function exclude_soft_deleted(json){
  return json.soft_deleted == false
}

const PresetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STOREPRESETS':
      return {...state, locations: action.payload.locations.filter(exclude_soft_deleted), plants:action.payload.plants.filter(exclude_soft_deleted),
      companies: action.payload.companies.filter(exclude_soft_deleted), activityCategories: action.payload.activity_categories.filter(exclude_soft_deleted),
      materials: action.payload.materials.filter(exclude_soft_deleted), labourTrades: action.payload.labour_trades.filter(exclude_soft_deleted), positions: action.payload.positions.filter(exclude_soft_deleted)}
    case 'GETSTAFFLIST':
      return {...state, staffList: action.payload}
    case 'LOADSUBLOCATIONS':
      const filterLocations = state.locations.filter(function(l) {
        return l.id == action.payload
      });
      const subLocations = filterLocations[0].sub_locations;
//      const subLocations = state.locations[action.payload] || '';
      return {...state, subLocations: subLocations.filter(exclude_soft_deleted)}
    case 'STORELOCATION':
      return {...state, locations: action.payload.locations}//.filter(exclude_soft_deleted)}
    // case 'STOREPLANT':
    //   return {...state, plants: action.payload.plants.filter(exclude_soft_deleted)}
    // case 'STORECOMPANIES':
    //   return {...state, companies: action.payload.companies.filter(exclude_soft_deleted)}
    // case 'STOREACTIVITYCAT':
    //   return {...state, activityCategories: action.payload.activity_categories.filter(exclude_soft_deleted)}
    // case 'STOREMATERIALS':
    //   const materials = action.payload.materials
    //     console.log(materials)
    //   return {...state, materials: materials.filter(exclude_soft_deleted)}
    case 'STORELABOUR':
      return {...state, labourTrades: action.payload.labour_trades}//.filter(exclude_soft_deleted)}
    // case 'STOREPOSITIONS':
    //   return {...state, positions: action.payload.positions.filter(exclude_soft_deleted)}
    // case 'STORECONTRACTORS':
    //   return {...state, contractors: action.payload.contractors}
    case 'SELECTLOCATION':
      return {...state, selectedLocation:action.payload}
    case 'SELECTPLANT':
      return {...state, selectedPlant:action.payload}
    case 'SELECTLABOUR':
      return {...state, selectedLabour:action.payload}
    default:
      return state;
  }
};

export default PresetsReducer;
