import React from 'react'
import styled from 'styled-components'
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';

// const CustomInputLabel = styled(InputLabel)`
// color: 'gray';
// padding: '0';
// fontSize: '0.8rem';
// fontFamily: "Roboto";
// fontWeight: '500';
// letterSpacing: '0.00938em';
// transform: 'translate(10px, -5px)';
// backgroundColor:'#f8f9ff';
// position:'absolute';
// zIndex:'101';
// `
// const CustomSelect = styled(Select)`
// border: 'none';
// fontSize:'1rem';
// padding:'5px 8px';
// textAlign:'left';
// boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px';
// backgroundColor:'#f8f9ff';
// `
// const useStyles = theme => ({
//     cssOutlinedInput: {
//         '&$cssFocused $notchedOutline': {
//             borderColor: `red !important`,
//         }
//     },

//     notchedOutline: {
//         borderWidth: '1px',
//         borderColor: 'green !important'
//     },
// });


export function FrameSelectBox(props) {
    const { style, labelId, onChange, label, value, children } = props
    // const borderSelectClasses = useBorderSelectStyles();
    // const classes = useStyles();

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon 
                // className={props.className + " " + borderSelectClasses.icon} 
            />
        )
    };
    return (
        <FormControl
            variant={'outlined'}
            // InputLabelProps={{
            //     classes: {
            //         root: classes.cssLabel,
            //         focused: classes.cssFocused,
            //     },
            // }} InputProps={{
            //     classes: {
            //         root: classes.cssOutlinedInput,
            //         focused: classes.cssFocused,
            //         notchedOutline: classes.notchedOutline,
            //     },
            // }}
            // size={'small'}
            // style={{ border: '0px solid black', borderRadius: '3px' }}
            style={{ width: '100%' }}
        >
            <InputLabel
            // InputProps={{
            //     classes: {
            //         root: classes.cssOutlinedInput,
            //         focused: classes.cssFocused,
            //         notchedOutline: classes.notchedOutline,
            //     },
            // }} InputLabelProps={{
            //     classes: {
            //         root: classes.cssLabel,
            //         focused: classes.cssFocused,
            //     },
            // }} style={{
            //     color: 'gray',
            //     padding: '0',
            //     fontSize: '0.8rem',
            //     fontFamily: "Roboto",
            //     fontWeight: '500',
            //     letterSpacing: '0.00938em',
            //     transform: 'translate(10px, -5px)',
            //     backgroundColor: '#f8f9ff',
            //     position: 'absolute',
            //     zIndex: '101',
            // }}
                id={labelId}
            >
                {label}
            </InputLabel>
            <Select
                // style={{ border: 'none', fontSize: '1rem', padding: '5px 8px', minWidth: '200px', textAlign: 'left', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px', backgroundColor: '#f8f9ff', }}
                // IconComponent={iconComponent}
                labelId={labelId}
                onChange={onChange}
                value={value}
                label={label}
                className='text-left'
            // InputLabelProps={{
            //     classes: {
            //         root: classes.cssLabel,
            //         focused: classes.cssFocused,
            //     },
            // }}
            // InputProps={{
            //     classes: {
            //         root: classes.cssOutlinedInput,
            //         focused: classes.cssFocused,
            //         notchedOutline: classes.notchedOutline,
            //     },
            // }}
            >
                {children}
            </Select>
        </FormControl>
    )
}
