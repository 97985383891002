import { Button } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

function Canvas(props) {
    const { setImage, h, w } = props;
    const canvasRef = useRef();
    const [isPainting, setIsPainting] = useState(false);
    const [mousePositionX, setMousePositionX] = useState(null);
    const [mousePositionY, setMousePositionY] = useState(null);
    const startPaint = useCallback((event) => {
        const coordinates = getCoordinates(event);
        if (coordinates) {
            setMousePositionX(coordinates.x);
            setMousePositionY(coordinates.y);
            setIsPainting(true);
        }
    }, []);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        return () => {
            canvas.removeEventListener('mousedown', startPaint);
        };
    }, [startPaint]);

    const paint = useCallback(
        (event) => {
            if (isPainting) {
                const newMousePosition = getCoordinates(event);
                if (mousePositionX && newMousePosition) {
                    drawLine(mousePositionX, mousePositionY, newMousePosition.x, newMousePosition.y);
                    setMousePositionX(newMousePosition.x);
                    setMousePositionY(newMousePosition.y);
                }
            }
        },
        [isPainting, mousePositionX, mousePositionY]
    );

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        canvas.addEventListener('mousemove', paint);
        return () => {
            canvas.removeEventListener('mousemove', paint);
        };
    }, [paint]);

    const exitPaint = useCallback(() => {
        setIsPainting(false);
        setMousePositionX(undefined);
        setMousePositionY(undefined);
        setImage(canvasRef.current.toDataURL('data:image/png'))
    }, []);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        canvas.addEventListener('mouseup', exitPaint);
        canvas.addEventListener('mouseleave', exitPaint);
        return () => {
            canvas.removeEventListener('mouseup', exitPaint);
            canvas.removeEventListener('mouseleave', exitPaint);
        };
    }, [exitPaint]);

    const getCoordinates = (event) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        return { x: event.pageX - canvas.offsetLeft, y: event.pageY - canvas.offsetTop };
    };

    const drawLine = (originalMousePositionX, originalMousePositionY, newMousePositionX, newMousePositionY) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            context.strokeStyle = 'black';
            context.lineJoin = 'round';
            context.lineWidth = 3;

            context.beginPath();
            context.moveTo(originalMousePositionX, originalMousePositionY);
            context.lineTo(newMousePositionX, newMousePositionY);
            context.closePath();
            context.stroke();
        }
    };

    const clearLine = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
        setImage(canvasRef.current.toDataURL('data:image/png'));
    }

    return (
        <div className='d-flex flex-column align-items-start'>
            <canvas ref={canvasRef} style={{ border: '1px solid gray', borderRadius:'6px' }} height={h} width={w} />
            <div style={{transform:'translate('+(w-45)+'px,-25px)'}}><label style={{color:'gray'}} onClick={()=>clearLine()}>reset</label></div>
        </div>
    );
};


export default Canvas;
