import React, {Fragment, useState, useEffect} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Field} from "redux-form";

function ReduxAutoCompleteTextFieldComponent({
                                                 input,
                                                 label,
                                                 name,
                                                 dataSet,
                                                 dataFieldName,
                                                 targetFieldName,
                                                 required,
                                                 meta: {touched, error, warning},
                                                 ...rest
                                             }){
  function getVal(array){
    let filtered_fields = array.filter((e)=>e[dataFieldName] ==input.value)
    return filtered_fields.length > 0 ? filtered_fields[0] : null
  }
  const [val,setVal] = useState(getVal(dataSet))
  function onChange(event, value){
    setVal(value)
    input.onChange(value ? value[dataFieldName] : null)
  }
    return (
    <Fragment>
        <Autocomplete
            fullWidth
            multiple={false}
            options={dataSet}
            getOptionSelected={(option, value) => option == value}
            value={val}
            onChange={onChange}
            renderInput={(params) => (
                <TextField
                    label={label}
                    name={name}
                    error={touched && Boolean(error)}
                    {...params}
                    variant="outlined"
                    label={label}
                    helperText={touched && error ? error : null}
                />
            )}
            {...rest}
        />
    </Fragment>
);
}
export const ReduxAutoCompleteFieldByValue = props => {
    return (
        <Field
            dataSet={props.dataSet}
            component={ReduxAutoCompleteTextFieldComponent}
            {...props}
        />
    );
}
