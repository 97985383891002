import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {loadAllStaff, getPresetField, loadPositions, loadLabourTrades, getSubLocationList} from 'actions/Presets';
import axios from "axios";
import {GetStaffList} from "../../../actions/Staff";
import {getSubTradeList} from "actions/Presets";

export async function CompanyLookUp(project_id) {
  let lookUp = {};
  await getPresetField(project_id, 'companies').then((resp) => {
      resp.data.companies.map((contractor, index) => {
        lookUp[contractor.id] = contractor.name;
      })
    }
  )

  return lookUp;
}

export async function SubTradeLookUp(project_id) {
  let lookUp = {}
  await getSubTradeList(project_id, '').then((resp) => {
        resp.data.results.map((trade, index) => {
          lookUp[trade.id] = trade.name + ' (' + trade.code + ')';
        })
      }
  )
  return lookUp
}

export async function LabourTradeLookUp(project_id) {
  let lookUp = {}
  await getPresetField(project_id, 'labour_trades').then((resp) => {
        resp.data.labour_trades.map((trade, index) => {
          lookUp[trade.id] = trade.name + ' (' + trade.code + ')';
        })
      }
  )
  return lookUp
}

export async function PositionLookUp(project_id) {
  let lookUp = {};
  await getPresetField(project_id, 'positions').then((resp) => {
        resp.data.positions.map((object, index) => {
          lookUp[object.id] = object.name;
        })
      }
  )
  return lookUp;
}


export async function StaffLookUp(project_id){
  let lookUp = {};
  await GetStaffList('?page_size=10000&project='+project_id).then((resp => {
        resp.data.results.map((object, index) => {
          lookUp[object.id] = object.reg_no;
        })
      }
    )
  )
  return lookUp;
}

export async function StaffLookUpByReg(project_id) {
  let lookUp = {};
  await GetStaffList('?page_size=10000&project=' + project_id).then((resp => {
            resp.data.results.map((object, index) => {
              lookUp[object.reg_no] = object.reg_no;
            })
          }
      )
  )
  return lookUp;
}
