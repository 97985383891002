  import axios from "axios";
  import {BackEndRequest, defaultHeaders} from "./APICalls";
  import {APP_URL} from "../static";
  import {useEffect} from "react";

  export async function GetMonthlyReportList(params){
    console.log(params)
    const resp = await BackEndRequest('get','/site/monthly_report/'+params, defaultHeaders, {})
    console.log(resp)
    return resp
  }

  export async function Upload527APDF(data){
    const resp = await BackEndRequest('post','/site/gen_monthly_report/GF527A/', defaultHeaders, data)
    return resp
  }
