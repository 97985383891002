import React, { useState } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { InputAdornment } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

export default function DateInputPicker(props) {
    const { label, value, onChange } = props;
    //    const [selectedDate, handleDateChange] = useState(new Date());
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                fullWidth
                autoOk
                disableToolbar
                format="dd-MM-yyyy"
                label={label}
                value={value}
                onChange={onChange}
                variant={'inline'}
                inputVariant={'outlined'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                            <KeyboardArrowDown />
                        </InputAdornment>
                    ),
                    style: { paddingRight: 7 }
                }}
            />
        </MuiPickersUtilsProvider>
    )
}