  import axios from "axios";
  import {BackEndRequest, defaultHeaders} from "./APICalls";
  import {APP_URL} from "../static";
  import {useEffect} from "react";

  export const ReportURL = '/site/report/'

  export async function GetSigneeList(params){
    const resp = await BackEndRequest('get','/site/daily_report/signees/'+params, defaultHeaders, {})
    return resp
  }

  export async function RequestSignature(params, data){
    const resp = await BackEndRequest('post','/site/daily_report/sign/request/'+params, defaultHeaders, data)
    return resp
  }

  export async function SignDocument(params, data){
    const resp = await BackEndRequest('post','/site/document_sign/report/'+params, defaultHeaders, data)
    return resp
  }

  export async function GetReportList(params){
    const resp = await BackEndRequest('get',ReportURL+params, defaultHeaders, {})
    return resp
  }

  export async function UploadReportDocument(report_id, data){
    const resp = await BackEndRequest('post','/site/document_upload/report/'+report_id, defaultHeaders, data)
    return resp
  }


  export async function CountSummary(params){
    const resp = await BackEndRequest('get','/site/counts/'+params, defaultHeaders, {})
    return resp
  }

  export async function PlantSummary(params){
    const resp = await BackEndRequest('get','/site/plant_summary/'+params, defaultHeaders, {})
    return resp
  }

  export async function LabourSummary(params){
    const resp = await BackEndRequest('get','/site/labour_summary/'+params, defaultHeaders, {})
    return resp
  }

  export async function GetReport(id){
    const resp = await BackEndRequest('get',ReportURL+id+'/', defaultHeaders, {})
    return resp;
  }

  export async function LoadReport(dispatch, id){
    const resp = await BackEndRequest('get',ReportURL+id+'/', defaultHeaders, {}).then((resp)=>{
      dispatch({'type': 'SELECTREPORT', 'payload': resp.data});
    })
  }

  // Update and Create use put
  export async function UpdateReport(id, data){
    const resp_data = await BackEndRequest('put',ReportURL+id+'/', defaultHeaders, data)
  }

  export async function GetWeatherData(){
    let url;
    url = 'https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en';
    const weather = await axios.get(url, {}).then((resp) =>
      {
        const data = resp.data
        const filtered_rainfall = data.rainfall.data.filter(function (el) {
          return el.place=='Kwun Tong';
        });
        const filtered_temperature = data.temperature.data.filter(function (el) {
          return el.place=='Kwun Tong';
        });
        return {'rainfall':filtered_rainfall[0].max, 'temperature':filtered_temperature[0].value, warning:resp.data.warningMessage};
      }
    );
    return weather
  }