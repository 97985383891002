import React, {useEffect, useState} from "react";
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch, connect } from 'react-redux';
import { Field, change, FieldArray, reduxForm, formValueSelector, propTypes } from 'redux-form';
import {Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@material-ui/core'
import 'antd/dist/antd.css';
import axios from 'axios';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import StaffEnhancedTable from "./StaffSelectTable/StaffEnhancedTable";


function SelectStaff(params) {
  const labour = params.labour;
  const staffSelectList = params.staffSelectList;

  function InsertStaffNo(e){
    //**if basic select box component
    // const select = e.target;
    // let addingStaff = [];
    // for (let i = 0; i < select.options.length; i++) {
    //   if (select.options[i].selected) {
    //     addingStaff.push(select.options[i].value);
    //   }
    // }
    //**if basic select box component
    params.change("activity", `${labour}.no`, e.length);
  }

  return (
    <Field
        name={`${labour}.staff`}
        size={10}
        multiple={true}
        onChange={InsertStaffNo}
        component={props =>
          <StaffEnhancedTable
            currentValue={props.input.value || []}
            staffSelectList={staffSelectList}
            thingsChanged={param => props.input.onChange(param)}/>
        }
      >
    </Field>
  )
}


function mapStateToProps(state) {
  return {
  }
}

const mapDispatchToProps = {
  change
};

SelectStaff = connect(mapStateToProps,
  mapDispatchToProps
)(SelectStaff)

export default SelectStaff;
