import React, { useEffect, useState, useRef } from "react";
import {
    Typography,
    Grid,
    makeStyles, Popover, Box, Modal, Card, CardContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from "@material-ui/icons/Image";
import { generalSpacing } from 'stylesCss';

const useStyles = makeStyles((theme) => ({
    // root: {},
    detail: {
        border: '1px solid #AFA4C8',
        // '&:hover': {
        //     border: '3px solid #15B1D7',
        //        color:'black',
        // },
    },
    selectedDetail: {
        backgroundColor: 'rgba(21, 177, 215, 0.03)',
        border: '2px solid #15B1D7',
    },
    card: {
        width: '80vw',
        height: '80vh'
    },
    large_img: {
        height: '80%'
    }
}));

export default function ActivityImageShowcase(props) {
    const { details, startTime, endTime, location, subLocation, date, labours, plants, materials } = props;
    const classes = useStyles();
    const [highlightIndex, setHighlight] = useState(0);
    const [hoverIndex, setHover] = useState(null);
    const [visible, setVisibile] = React.useState(false);
    const [images, setImages] = useState(details[0] ? details[0].images : null);
    const [selectedImage, setSelectedImage] = useState(details[0] ? details[0].images[0] : null);
    const [clicked, setClicked] = useState(details[0] ? details[0].id : null);
    const node = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (node.current) {
            if (node.current.contains(e.target)) {
                // inside click
                return;
            }
            setVisibile(false)
        }
    };

    function setSelected(activity) {
        if (activity.images.length > 0) {
            setSelectedImage(activity.images[0]);
        }
        setImages(activity.images);
        setClicked(activity.id);
    }

    return (
        <React.Fragment>
            <ImageIcon style={{ color: '#15B1D7' }} onClick={(e) => { setVisibile(true) }} />
            {/* {visible ?
                <Grid container justify='center' style={{ position: 'fixed', 'display': 'block', 'width': '100%', 'height': '100%', 'top': '0', 'left': '0', 'right': '0', 'bottom': '0', 'zIndex': '1300' }}>
                    <Box boxShadow={5} style={{ position: 'fixed', width: '80%', height: '80%', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', 'zIndex': '1301', backgroundColor: 'white', borderRadius: '3px' }}>
                        <Grid container item flexDirection='row' ref={node} style={{ width: '100%', height: '100%' }}>
                            <Grid container xs={9} item style={{ padding: '2rem', paddingBottom: '1rem', backgroundColor: '#262626', borderRadius: '3px 0px 0px 3px' }}>
                                <Grid container xs={12} item style={{ padding: '1rem', paddingBottom: '2rem', height: '85%' }}>
                                    {selectedImage ?
                                        <img style={{ backgroundColor: 'white', width: '100%' }} src={selectedImage}></img>
                                        : null}
                                </Grid>
                                <Grid container xs={12} item style={{ height: '15%' }}>
                                    {images.map((image, i) => (
                                        <Grid container xs={2} item style={{ padding: '1rem', width: '100%' }} onClick={() => setSelectedImage(image.data_url)}>
                                            <img style={{ backgroundColor: 'white', width: '100%' }} src={image.data_url}></img>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container xs={3} alignItems={'flex-start'} item style={{ height: '100%' }}>
                                <Grid xs={12} item style={{ textAlign: 'right', padding: '0.75rem', height: '5%' }}><CloseIcon onClick={() => setVisibile(false)} /></Grid>
                                <Grid xs={12} item style={{ padding: '1rem', paddingTop: '0rem', height: '95%', overflow: 'auto' }} >
                                    {details.map((activity, i) => (
                                        <div className={clicked == activity.id ? classes.selectedDetail : classes.detail} style={{ textAlign: 'left', padding: '1rem', marginTop: '1rem', borderRadius: '3px' }} onClick={() => setSelected(activity)}>
                                            <Typography style={{ fontSize: '1.3rem', fontWeight: '500', marginBottom: '0.2rem' }}>
                                                {activity.activity_category ? activity.activity_category.name : ''}
                                            </Typography>
                                            <Grid container style={{ color: '#262626', marginBottom: '0.2rem' }}>
                                                <Grid item xs={5}><Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>{location}</Typography></Grid>
                                                <Grid item xs={7}><Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>{subLocation}</Typography></Grid>
                                                <Grid item xs={5}><Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>{startTime + '-' + endTime}</Typography></Grid>
                                                <Grid item xs={7}><Typography style={{ fontSize: '0.8rem', fontWeight: '400' }}>{date}</Typography></Grid>
                                            </Grid>
                                            <Typography style={{ fontSize: '0.8rem', padding: '2px', fontWeight: '400' }}>
                                                {activity.activity_remarks}
                                            </Typography>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
                : null} */}
            <Modal open={visible} onClose={() => setVisibile(false)} className='d-flex'>
                <Card className={`${classes.card} m-auto`}>
                    <Grid container className='h-100'>
                        <Grid item xs='8' className='flex-column h-100' style={{ backgroundColor: '#000' }}>
                            <div className={`${classes.large_img} pt-5 px-5`} >
                                {selectedImage ? <img src={selectedImage} style={{ objectFit: 'contain', height: '100%', width: '100%' }} /> : null}
                            </div>
                            <div className='d-flex flex-row mx-5' style={{ overflowX: 'auto' }}>
                                {images.map((image, i) => (
                                    <img src={image.data_url} style={{ objectFit: 'contain', width: 100, height: 100, padding: 4 }} onClick={() => setSelectedImage(image.data_url)} />
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs='4' container className='d-flex flex-column pt-3'>
                            <div className='card-box border-0'>
                                <div className='card-header border-0 align-items-start'>
                                    <div className='card-header--title'>
                                        <Typography variant='h6' style={{fontWeight: 500}}>{location} - {subLocation}</Typography>
                                        <Typography style={{ color: 'rgba(38, 38, 38, 0.6)'}}>{date}</Typography>
                                        <Typography style={{ color: 'rgba(38, 38, 38, 0.6)'}}>{labours} Labours&nbsp;&nbsp;{plants} Plants&nbsp;&nbsp;{materials} Materials</Typography>
                                    </div>

                                    <div className='card-header--actions' style={{cursor: 'pointer'}}>
                                        <CloseIcon onClick={() => setVisibile(false)} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className='card-body'>
                                <Typography>{location}</Typography>
                                <Typography>{subLocation}</Typography>
                                <Typography>{date}</Typography>
                            </div> */}
                            {details.map((activity, i) => (
                                // <div className='px-3 py-3' onClick={() => setSelected(activity)}>
                                <Card
                                    className={clicked == activity.id ? `mx-3 my-3 ${classes.selectedDetail}` : `mx-3 my-3 ${classes.detail}`}
                                    // style={{ textAlign: 'left', padding: '1rem', marginTop: '1rem', borderRadius: '3px' }}
                                    onClick={() => setSelected(activity)}
                                >
                                    <CardContent className='h-100'>
                                        <Typography>Activity {i+1}</Typography>
                                        <Typography variant='h6' className='' style={{fontWeight: '500'}}>
                                            {activity.activity_category ? `${activity.activity_category.name} ${activity.activity_remarks}`: ''}
                                        </Typography>
                                        <Typography>Time: {startTime + '-' + endTime}</Typography>
                                    </CardContent>
                                </Card>
                                // </div>
                            ))}
                        </Grid>
                    </Grid>
                </Card>
            </Modal>
        </React.Fragment>
    );
}