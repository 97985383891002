import React, {useState, useRef, useEffect, createRef} from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {parseDate } from 'react-day-picker/moment';
import CustomDatePickerOverlay from 'components/date-components/CustomDatePickerOverlay';
import {FormControl, Grid, InputLabel} from "@material-ui/core";
import dateFnsFormat from 'date-fns/format';
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
// boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
  input: {

      fontSize: '1rem',
      border: '1px #e0e0e0 solid',
      color:'#616161',
      padding: '12px',
      '&:focus':{
          outline:'none',
      }
  }
}))

function Weekday({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div className={className} title={weekdayName}>
      {weekday} {weekdayName.slice(0, 1)}
    </div>
  );
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export default function DateRangePicker(props) {
    const {handleFromChange, handleToChange, to, from} = props
    const refTo = createRef();
    const refFrom = createRef();
    const classes = useStyles();
    function showFromMonth() {
        // const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            refTo.current.getDayPicker().showMonth(from);
        }
    }
    //
    function handleChangeTo(to) {
        handleToChange(to);
        showFromMonth();
    }
    const modifiers = { start: from, end: to };
// 1 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
// 2 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    //minWidth: 235px

    return (
        <span className="InputFromTo" style={{width:'235px', position:'relative'}}>
            <InputLabel style={{
                color: 'gray',
                padding: '0',
                fontSize: '0.8rem',
                fontFamily: "Roboto",
                fontWeight: '500',
                letterSpacing: '0.00938em',
                transform: 'translate(10px, -5px)',
                backgroundColor:'#f8f9ff',
                position:'absolute',
                zIndex:'101'
            }}>
                &nbsp;Date&nbsp;
            </InputLabel>
            <div style={{
                position:'absolute', width:'235px', zIndex:'100', height:'50px', borderRadius:'3px',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                border: '1px #e0e0e0 solid',
            }} onClick={()=>refFrom.current.getInput().focus()}></div>
            <ExpandMoreIcon
                style={{
                    transform: 'translate(205px, 13px)',
                    position:'absolute',
                    color:'gray',
                }}
                onClick={()=>refFrom.current.getInput().focus()}
            />
            <DayPickerInput
                inputProps={{className:classes.input, style:{border:'none', width:'110px', textAlign:'right', marginRight:'0px', borderRight:'none', paddingRight:'0px', borderRadius: '3px 0 0 3px'}}}
                overlayComponent={CustomDatePickerOverlay}
                value={from}
                ref={refFrom}
                placeholder="From"
                format="LL"
                formatDate={formatDate}
                format='dd/MM/yyyy -'
                parseDate={parseDate}
                dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 2,
                    onDayClick: () => refTo.current.getInput().focus(),
                }}
                onDayChange={handleFromChange}
            />

            <span className="InputFromTo-to">
                <DayPickerInput
                    inputProps={{onClick:()=>refFrom.current.getInput().focus(), className:classes.input, style:{border:'none',width:'125px', borderLeft:'none', paddingLeft:'0px', borderRadius: '0 3px 3px 0'}}}
                    overlayComponent={CustomDatePickerOverlay}
                    ref={refTo}
                    value={to}
                    placeholder="To"
                    format="LL"
                    formatDate={formatDate}
                    format=' dd/MM/yyyy'
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: from,
                        fromMonth: from,
                        numberOfMonths: 2,
                    }}
                    onDayChange={handleChangeTo}
                />
            </span>
            <Helmet>
                <style>{`
                  .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                    background-color: #f0f8ff !important;
                    color: #4a90e2;
                  }
                  .InputFromTo .DayPicker-Day {
                    border-radius: 0 !important;
                    padding: 0.5rem;
                  }
                  .InputFromTo .DayPicker-Day--start {
                    border-top-left-radius: 50% !important;
                    border-bottom-left-radius: 50% !important;
                  }
                  .InputFromTo .DayPicker-Day--end {
                    border-top-right-radius: 50% !important;
                    border-bottom-right-radius: 50% !important;
                  }
                  .InputFromTo .DayPickerInput-Overlay {
                    width: 550px;
                  }
              `}</style>
            </Helmet>
        </span>
    );
    // }
}

