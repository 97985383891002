import moment from "moment";

const initialState = {
    'page': 1,
    'selectedProject': JSON.parse(localStorage.getItem('PROJECT')) || {},
    'activityList': [],
    'reportList': [],
    'report': {},
    'labourStaff': [],
    'newArray': [],
    'attendanceList': [],
    'disableDatePicker': false,
    'selectedDay': moment().toDate(),
    'selectDateFrom': moment().toDate(),
    'selectDateTo': moment().toDate(),
    'show_filter': false,
    'filter': {},
    'filter_options': {},
    'copyActivity': {},
    'copyLabour': {},
    'copyPlant': {},
};

const ProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'COPYACTIVITY':
            return { ...state, copyActivity: action.payload };
        case 'COPYLABOUR':
            return { ...state, copyLabour: action.payload };
        case 'COPYPLANT':
            return { ...state, copyPlant: action.payload };
        case 'NEXTPAGE':
            return { ...state, page: state.page + 1 };
        case 'BACKPAGE':
            return { ...state, page: state.page - 1 };
        case 'SELECTPROJECT':
            localStorage.setItem('PROJECT', JSON.stringify(action.payload));
            return { ...state, selectedProject: action.payload };
        case 'PROJECTLIST':
            return { ...state, projectList: action.payload };
        case 'ATTENDANCELIST':
            return { ...state, attendanceList: action.payload };
        // case 'REPORT':
        //   let report = action.payload
        //   // var dateStart = moment(report.date).format('YYYY-MM-DD');
        //   // var dateEnd = moment(report.date).add(1,'days').format('YYYY-MM-DD');
        //   // report.dateStart = dateStart
        //   // report.dateEnd = dateEnd
        //   return {...state, report: report}
        case 'REPORTLIST':
            return { ...state, reportList: action.payload }
        case 'ACTIVITYLIST':
            let combined_section = [];
            for (var i = 0; i < action.payload.length; i++) {
                let c = true;
                const back_up = JSON.parse(JSON.stringify(action.payload[i]));
                while (c) {
                    let labours = action.payload[i].labours.shift() || {};
                    let plants = action.payload[i].plants.shift() || {};
                    let materials = action.payload[i].materials.shift() || {};
                    let details = action.payload[i].details.shift() || {};

                    c = Object.keys(labours).length != 0 || Object.keys(plants).length != 0 || Object.keys(materials).length != 0 || Object.keys(details).length != 0
                    if (c) {
                        combined_section.push({ 'labours': labours, 'plants': plants, 'materials': materials, 'details': details });
                    }
                }
                action.payload[i] = back_up;
                action.payload[i]['combined_section'] = combined_section
                combined_section = []
            }
            return { ...state, activityList: action.payload }
        case 'WEATHERSIGNALS':
            const typhoon = action.payload.WTCSGNL || { 'type': 'Nil' }
            const rainstorm = action.payload.WRAIN || { 'type': 'Nil' }
            const signals = typhoon.type + ', ' + rainstorm.type
            return { ...state, warningSignal: signals };
        case 'WEATHERREPORT':
            const rainfall = action.payload.rainfall.data.filter(function (i, n) { return i.place == 'Kwun Tong' });
            return { ...state, rainfall: rainfall[0].max, weather: action.payload.warningMessage };
        case 'NAVDATETO':
            return { ...state, selectDateTo: action.payload }
        case 'NAVDATEFROM':
            return { ...state, selectDateFrom: action.payload }
        case 'TOGGLEFILTER':
            return { ...state, show_filter: !state.show_filter }
        case 'UPDATEFILTER':
            let filter = state.filter
            Object.assign(filter, action.payload);
            return { ...state, filter: filter }
        case 'REMOVEFILTER':
            let filter2 = Object.assign({}, state.filter)
            filter2[action.payload] = '';
            return { ...state, filter: filter2 }
        case 'REMOVINGFILTER':
            let filter3 = state.filter_remove
            Object.assign(filter3, action.payload);
            return { ...state, filter_remove: filter3 }
        case 'RESETFILTER':
            return { ...state, filter: {} }
        default:
            return state;
    }
};

export default ProjectReducer;

//    case 'updateData':
//      const newData = state.data.slice(0);
//
//      for (let [row, column, oldValue, newValue] of action.dataChanges) {
//        newData[row][column] = newValue;
//      }
//
//      return Object.assign({}, state, {
//        data: newData
//      });
//    case 'updateReadOnly':
//      return Object.assign({}, state, {
//        readOnly: action.readOnly
//      });
