
export default function SiteSheet2Template(diary2inputs){
  return [
    {
    'sheet_name': '2',
    'insert': {
        'start_position': ['A',13],
        'table_area': [1,1],
        'data': [[diary2inputs.typhoon]],
     },
    },
    {
    'sheet_name': '2',
    'insert': {
        'start_position': ['A',38],
        'table_area': [1,1],
        'data': [[diary2inputs.text2]],
     },
    },
    {
    'sheet_name': '2',
    'insert': {
        'start_position': ['I',13],
        'table_area': [1,1],
        'data': [[diary2inputs.comments]],
     },
    },
    {
    'sheet_name': '2',
    'insert': {
        'start_position': ['I',43],
        'table_area': [1,1],
        'data': [[diary2inputs.visitor]],
     },
    },
    {
    'sheet_name': '2',
    'insert': {
        'start_position': ['Q',13],
        'table_area': [1,1],
        'data': [[diary2inputs.remarks]],
     },
    },
  ]
}
