import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    Box, InputLabel, Tabs, Tab
} from '@material-ui/core';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import {APP_URL} from 'static.js';
import {WrapperBox} from "../../../components/layout-components/Content";
import {CountSummary, PlantSummary, LabourSummary} from "../../../actions/Report";

const countCellColor = '#15B1D7';
const dataColumnsColor = 'rgba(0,0,0,0.6)';
function ReportViewPage1() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // let { id } = useParams();
    const selectedReport = useSelector((state) => state.report.selectedReport);
    const [attendanceCount, setAttendanceCount] = useState([])
    const [supervisorCount, setSupervisorCount] = useState([])
    const [laboursCount, setLabour] = useState([]);
    const [plantsCount, setPlant] = useState([]);
    const attendanceTotal = attendanceCount.reduce(function (total, element) { return total + element.count; }, 0);
    const supervisorTotal = supervisorCount.reduce(function (total, element) { return total + element.count; }, 0);
    const laboursTotal = laboursCount.reduce(function (total, element) { return total + element.count; }, 0);
    const plantsIdlingTotal = plantsCount.reduce(function (total, element) { return total + element.idling_count; }, 0);
    const plantsWorkingTotal = plantsCount.reduce(function (total, element) { return total + element.working_count; }, 0);

  useEffect(() => {
    let params;
    if(selectedReport.id){
      params = '?date='+selectedReport.date+'&filter=staff__position__supervisor&value=1&exclude=1'
      CountSummary(params).then((resp)=> setAttendanceCount(resp.data));
      params = '?date='+selectedReport.date+'&filter=staff__position__supervisor&value=1&count=staff__position__name'
      CountSummary(params).then((resp)=> setSupervisorCount(resp.data));
      params = '?activity__date='+selectedReport.date
      LabourSummary(params).then((resp)=>setLabour(resp.data));
      PlantSummary(params).then((resp)=>setPlant(resp.data));
    }
  },[selectedReport])

    function StaffTable() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Contractors Site Staff
                        </TableCell>
                        <TableCell className='text-center'>
                            No.
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {attendanceCount.map((s, i) => (
                        <TableRow>
                            <TableCell style={{ color: dataColumnsColor }}>
                                {s.staff__trade__name || 'N/A'}
                            </TableCell>
                            <TableCell style={{ color: dataColumnsColor }} className='text-center'>
                                {s.count}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: countCellColor }}>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }}>
                            Total
                        </TableCell>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }} className='text-center'>
                            {attendanceTotal}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function SupervisingTable() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Assitance to Supervising Officer
                        </TableCell>
                        <TableCell className='text-center'>
                            No.
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supervisorCount.map((s, i) => (
                        <TableRow>
                            <TableCell style={{ color: dataColumnsColor }} >
                                {s.staff__position__name || 'N/A'}
                            </TableCell>
                            <TableCell style={{ color: dataColumnsColor }} className='text-center'>
                                {s.count}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: countCellColor }}>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }}>
                            Total
                        </TableCell>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }} className='text-center'>
                            {supervisorTotal}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function LabourTable() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Labours
                        </TableCell>
                        <TableCell className='text-center'>
                            No.
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {laboursCount.map((s, i) => (
                        <TableRow>
                            <TableCell style={{ color: dataColumnsColor }}>
                                {s.trade__name || 'N/A'}
                            </TableCell>
                            <TableCell style={{ color: dataColumnsColor }} className='text-center'>
                                {s.count}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: countCellColor }}>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }}>
                            Total
                        </TableCell>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }} className='text-center'>
                            {laboursTotal}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    function PlantTable() {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Plants
                        </TableCell>
                        <TableCell className='text-center'>
                            Working No.
                        </TableCell>
                        <TableCell className='text-center'>
                            Idling No.
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plantsCount.map((s, i) => (
                        <TableRow>
                            <TableCell style={{ color: dataColumnsColor }}>
                                {s.plant__type || 'N/A'}
                            </TableCell>
                            <TableCell style={{ color: dataColumnsColor }} className='text-center'>
                                {s.working_count}
                            </TableCell>
                            <TableCell style={{ color: dataColumnsColor }} className='text-center'>
                                {s.idling_count}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: countCellColor }}>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }}>
                            Total
                        </TableCell>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }} className='text-center'>
                            {plantsWorkingTotal}
                        </TableCell>
                        <TableCell style={{ color: 'white', borderBottom: '0px' }} className='text-center'>
                            {plantsIdlingTotal}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    return (
        <React.Fragment>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={6} lg={3}>
                    <WrapperBox>
                        <StaffTable />
                    </WrapperBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <WrapperBox>
                        <SupervisingTable />
                    </WrapperBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <WrapperBox>
                        <LabourTable />
                    </WrapperBox>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <WrapperBox>
                        <PlantTable />
                    </WrapperBox>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
export default ReportViewPage1;
