import React, { useEffect, useState } from "react";
import { MTableToolbar } from "material-table";
import { Grid, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { generalSpacing } from 'stylesCss';
import CancelIcon from '@material-ui/icons/Cancel';

export function CustomToolBar(props) {
    const filter = useSelector((state) => state.project.filter);
    const filter_options = useSelector((state) => state.project.filter_options);
    const dispatch = useDispatch();
    var keys = [];
    for (var k in filter) keys.push(k);
    console.log(props);
    return (
        <React.Fragment>
            <Card style={{ boxShadow: 'none' }}>
                {/* <Grid container>
                    <Grid item xs={6}>
                        <CardHeader 
                            title={props.title} 
                            // style={generalSpacing} 
                        />
                    </Grid>
                    <Grid item xs={6}> */}
                        <div style={props.titleColor ? {backgroundColor: props.titleColor, color: '#FFF'} : null}>
                            <MTableToolbar {...props} title={props.title}/>
                        </div>
                    {/* </Grid>
                </Grid> */}
                {keys.map((field, i) => (
                    filter[field] ?
                        <Chip
                            style={{ marginLeft: '10px', backgroundColor: 'rgba(57,52,74,0.12)', color: 'rgba(57,52,74)', borderColor: 'rgba(57,52,74)'}}
                            label={field + ':' + filter[field]}
                            onDelete={() => {
                                dispatch({ 'type': 'REMOVEFILTER', 'payload': field });
                            }}
                            variant="outlined"
                            deleteIcon={<CancelIcon style={{color: 'rgba(57,52,74,0.6)'}}/>}
                        />
                        : null
                ))}
            </Card>
        </React.Fragment>
    )
}

export function CustomFilter(props) {
    const show_filter = useSelector((state) => state.project.show_filter);
    const filter = useSelector((state) => state.project.filter);
    return (
        <React.Fragment>
            {show_filter ? (
                <React.Fragment>
                    <tr>
                        {props.columns.map((column, i) => (
                            <React.Fragment>
                                {
                                    !column.lookup ?
                                        <th>
                                            <FilterText {...props} current={filter[column.title]} index={i} column={column.title} />
                                        </th>
                                        :
                                        <th>
                                            <FilterSelect {...props} current={filter[column.title]} index={i} column={column.title} />
                                        </th>
                                }
                            </React.Fragment>
                        ))}
                    </tr>
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};

function FilterSelect(props) {
    const dispatch = useDispatch()
    const filter = useSelector((state) => state.project.filter);
    let lookupArray = []
    const [selectedId, setId] = useState(null)
    const [textVal, setTextVal] = useState('')
    Object.keys(props.columns[props.index].lookup).forEach(function (k) {
        lookupArray.push({ id: k, val: props.columns[props.index].lookup[k] });
    });
    const defaultProps = {
        options: lookupArray,
        getOptionLabel: (option) => option.val,
    };
    // console.log(filter)

    function handleChange(e, selected) {
        let payload = {}
        payload[props.column] = selected.val
        dispatch({ 'type': 'UPDATEFILTER', 'payload': payload })
        let filter_options = {};
        filter_options[props.column] = props.index;
        props.onFilterChanged(props.index, selected.id);
        setId(selected.id)
        setTextVal(selected.val)
    }

    function handleTextChange(e) {
        const val = e.target.value;
        setTextVal(val)
    }

    useEffect(() => {
        const val = filter[props.column]
        if (!val) {
            props.onFilterChanged(props.index, '');
            setTextVal('')
        } else {
            props.onFilterChanged(props.index, selectedId);
        }
    }, [filter]);

    return (
        <Autocomplete
            {...defaultProps}
            id={"auto-complete-filter" + props.index}
            disableClearable
            onChange={handleChange}
            // value={filter[props.column]}
            renderInput={(params) => {
                let p = params;
                p.inputProps.value = textVal
                return (
                    <TextField onChange={handleTextChange} {...params} style={{ marginBottom: '-10px', paddingLeft: '5px', paddingRight: '5px' }} />)
            }}
        />
    )
}

function FilterText(props) {
    const dispatch = useDispatch()
    const filter = useSelector((state) => state.project.filter);

    function handleChange(e) {
        const val = e.target.value;
        props.onFilterChanged(props.index, val);
        let payload = {}
        payload[props.column] = val
        dispatch({ 'type': 'UPDATEFILTER', 'payload': payload })
        let filter_options = {};
        filter_options[props.column] = props.index;
    }

    useEffect(() => {
        const val = filter[props.column]
        props.onFilterChanged(props.index, val);
    }, [filter]);

    return (
        <TextField value={filter[props.column]} onChange={handleChange} margin={"normal"} style={{ paddingLeft: '5px', paddingRight: '5px' }} />
    )
}
