import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, Grid, CssBaseline } from '@material-ui/core';
import GlobalStyles from 'components/GlobalStyles';
import 'mixins/chartjs';
// import theme from 'theme';
import routes from 'routes';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { generalSpacing } from './stylesCss';
const theme = createMuiTheme({
    // spacing: factor => `${0.25 * factor}rem`,
    // p: factor => `${0.25 * factor}rem`, // (Bootstrap strategy)
    // m: factor => `${0.25 * factor}rem`, // (Bootstrap strategy)
    // overrides: {
    //     MuiToolbar: {
    //         padding: '0px'
    //     },
    //     MuiTableCell: {
    //         root: {
    //             "&:first-child": {
    //                 paddingLeft: '2rem'
    //             },
    //             fontFamily: 'Roboto',
    //             fontSize: '0.85rem',
    //             fontWeight: '500',
    //         }
    //     },
    //     MuiTab: {
    //         wrapper: {
    //             textTransform: 'none'
    //         }
    //     },
    // },
    typography: {
        fontFamily: [
            // 'Open Sans',
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            'Roboto',
            // '"Helvetica Neue"',
            // 'Arial',
            // 'sans-serif',
            // '"Apple Color Emoji"',
            // '"Segoe UI Emoji"',
            // '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        common: {
            highlight: '#2e333a',
        },
        primary: {
            light: '#757ce8',
            main: '#15B1D7',
            variant: '#4BD4E7',
            dark: '#39344A',
            contrastText: '#fff',
            backgroundColor: '#39344A',
        },
        secondary: {
            light: '#AFA4C8',
            main: '#39344A',
            variant: '#2C263F',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        error: {
            main: '#DC143C',
        },
        titles: {
            secondary: '#15B1D7',
        },
        // text: {
        //     secondary: 'black',
        // },
        highlights: {
            main: '#15B1D7',
        },
    },
    // styles: {
    //     buttons: {
    //         padding: '0.6rem',
    //         height: '2.6rem',
    //         fontSize: '1rem',
    //         borderRadius: '5px',
    //         paddingRight: '3rem',
    //         paddingLeft: '3rem',
    //         backgroundColor: '#15B1D7',
    //         color: '#fff',
    //         boxShadow: '0 4px 6px 0px #ccc',
    //         '&:hover': {
    //             backgroundColor: '#4BD4E7',
    //             //        color:'black',
    //         },
    //     },
    //     font: {
    //         primary: {
    //             fontFamily: 'Roboto',
    //             fontSize: '1.4rem',
    //             color: '#39344A',
    //             fontWeight: '600',
    //         },
    //         secondary: {
    //             fontFamily: 'Roboto',
    //             fontSize: '1.3rem',
    //             color: '#15B1D7',
    //         },
    //         tertiary: {
    //             fontFamily: 'Roboto',
    //             fontSize: '1rem',
    //             color: '#15B1D7',
    //             fontWeight: '500',
    //         }
    //     },
    //     page: {
    //         frame: {
    //             padding: '2rem',
    //             paddingTop: '1.5rem',
    //         },
    //         content: {
    //             minHeight: '5rem',
    //             padding: '0.5rem',
    //             paddingRight: '0',
    //             paddingBottom: '0'
    //         },
    //         generalSpacing: {
    //             generalSpacing
    //         }
    //     },
    //     miniAddMore: {
    //         add: { position: 'relative', fontSize: '1em' },
    //         addButton: { color: '#15B1D7', fontWeight: '600', fontSize: '0.8rem' },
    //     },
    // },
});

// #15B1D7
// #39344A
const App = () => {
    // const dispatch = useDispatch();
    // axios.defaults.headers.post['Content-Type'] = 'application/json';
    // axios.defaults.headers.common['Authorization'] = 'Token '+JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || null) || null;
    // axios.interceptors.response.use(request => {
    //   // Edit request config
    //   return request;
    // }, error => {
    //   if (error.response ? error.response.status == 401: false){
    //     dispatch({'type':'LOGGED_IN', 'payload':false})
    //     window.location.reload();
    //   }
    //   console.log(error);
    //   return Promise.reject(error);
    // });
    const routing = useRoutes(routes);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {routing}
            </ThemeProvider>
        </div>
    );
};


// function App() {
//   return (
//     <div>
//       {/*<StaffList />*/}
//     </div>
//   );
// }
//

export default App;
