import moment from "moment";

const initialState = {
  'activityList': [],
  'selectedReport': {},
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'ACTIVITYLIST':
    //   return {...state, activityList: action.payload}
    case 'SELECTREPORT':
      return {...state, selectedReport: action.payload}
    default:
      return state;
  }
};

export default ReportReducer;

//    case 'updateData':
//      const newData = state.data.slice(0);
//
//      for (let [row, column, oldValue, newValue] of action.dataChanges) {
//        newData[row][column] = newValue;
//      }
//
//      return Object.assign({}, state, {
//        data: newData
//      });
//    case 'updateReadOnly':
//      return Object.assign({}, state, {
//        readOnly: action.readOnly
//      });
