import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    List,
} from '@material-ui/core';
import {
    Settings as SettingsIcon,
} from 'react-feather';
import {
    PermContactCalendar as PermContactCalendarIcon,
    Dashboard as DashboardIcon,
    ExitToApp as ExitToApp,
    AssignmentInd as AssignmentIcon,
    GroupWork as GroupWorkIcon,
    LibraryBooks as LibraryBooksIcon,
    People as PeopleIcon,
    ImportContacts as ImportContacts,
} from '@material-ui/icons';
import NavItem from 'components/layout-components/SidebarMenu/NavItem';
import {GetPermissions} from "../../../actions/Permissions";

const adminItems = [
    {
        href: '/app/admin',
        icon: SettingsIcon,
        title: 'Category Settings',
        permission: 'feature_admin'
    },
    {
        href: '/app/site_staff',
        icon: PeopleIcon,
        title: 'Staff Management',
        permission: 'feature_admin'
    },
    {
        href: '/app/lab',
        icon: ImportContacts,
        title: 'Attendance Import',
        permission: 'feature_admin'
    },
]

const normalItems = [
    {
        href: '/app/graph',
        icon: DashboardIcon,
        title: 'Overview',
        permission: 'feature_dashboard'
    },
    {
        href: '/app/user/attendance',
        icon: AssignmentIcon,
        title: 'Attendance',
        permission: 'feature_attendance'
    },
    {
        href: '/app/act',
        icon: GroupWorkIcon,
        title: 'Activities',
        permission: 'feature_activities'
    },
    {
        href: '/app/approve',
        icon: GroupWorkIcon,
        title: 'Approve',
        permission: 'feature_approve'
    },
    {
        href: '/app/report',
        icon: LibraryBooksIcon,
        title: 'Daily Report',
        permission: 'feature_daily_report'
    },
    {
        href: '/app/monthly_report',
        icon: PermContactCalendarIcon,
        title: 'Monthly Report',
        permission: 'feature_monthly_report'
    }
];

export default function SidebarMenu(props) {
    const adminMode = useSelector((state) => state.nav.adminMode);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const dispatch = useDispatch();
    const [adminTabs, setAdminTabs] = useState([])
    const [tabs, setTabs] = useState([])
    useEffect(()=>{
        if(selectedProject.id){
            GetPermissions(selectedProject.id).then((resp)=>{
                const featurePermissions = resp.data.results.map(x => x.name)
                if (featurePermissions.length != 0) {
                    setAdminTabs(adminItems.filter((x) => featurePermissions.includes(x.permission)))
                    setTabs(normalItems.filter((x) => featurePermissions.includes(x.permission)))
                    // console.log(resp.data.results.filter((e) => e.id = project.id).length)
                }
                else{
                    dispatch({'type':'SELECTPROJECT', payload:{}})
                }
            })
        }
    },[selectedProject])
    const content = (
        <React.Fragment>
            <Box
                display="flex"
                flexDirection="column"
            >
                <List className='p-0'>
                    {adminMode ?
                        <Box>
                            {adminTabs.map((item) => (
                                <NavItem
                                    href={item.href}
                                    key={item.title}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            ))}
                        </Box>
                        :
                        <Box>
                            {tabs.map((item) => (
                                <NavItem
                                    href={item.href}
                                    key={item.title}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            ))}
                        </Box>
                    }

                </List>
            </Box>
        </React.Fragment>
    );
    return content
}
