import { ExitToApp } from '@material-ui/icons';
import React from 'react'
import { useDispatch } from 'react-redux';
import {Logout} from "../../../actions/Auth";

export default function BottomMenu(props) {
    const dispatch = useDispatch();

    return (
        <div className='px-4 py-4' style={{ color: '#fff', cursor: 'pointer' }}
            onClick={() => {
                Logout(dispatch)
            }}
        >
            <ExitToApp size="20" />
            <span className='font-size-sm'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Log out</span>
        </div>
    )
}