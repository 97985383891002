import React, {useEffect, useState, useRef} from "react";
import {
    Typography,
    Grid,
    makeStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  highlight: {
    border:'3px solid #15B1D7'
  },
  normal: {
    border:'2px solid #AFA4C8'
  }
}));


export default function LivePreviewExample() {
    const classes= useStyles();
    const [highlightIndex, setHighlight] = useState(0)
    const [hoverIndex, setHover] = useState(null)
    return (
        <Grid container justify='center' style={{position: 'fixed', 'display': 'block', 'width': '100%', 'height': '100%', 'top': '0', 'left': '0', 'right': '0', 'bottom': '0', 'zIndex': '1300'}}>
            <Grid container item flexDirection='row' style={{position: 'fixed', width:'80%', height:'80%', top:'50%', left:'50%', transform: 'translate(-50%,-50%)', 'zIndex': '1300', backgroundColor:'white', borderRadius:'5px'}}>
                <Grid container xs={8} item style={{padding:'1rem', backgroundColor:'#262626', borderRadius:'5px 0px 0px 5px'}}>
                    <Grid container xs={12} item style={{padding:'1rem', height:'80%'}}>
                        <div style={{backgroundColor:'white', width:'100%'}}>Enlarged Photo</div>
                    </Grid>
                    <Grid container xs={12} item style={{height:'20%'}}>
                        <Grid container xs={2} item style={{padding:'1rem', width:'100%'}}>
                            <div style={{backgroundColor:'white', width:'100%'}}>Photo Selections</div>
                        </Grid>
                        <Grid container xs={2} item style={{padding:'1rem', width:'100%'}}>
                            <div style={{backgroundColor:'white', width:'100%'}}>Photo Selections</div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={4} alignItems={'flex-start'} item style={{height:'100%'}}>
                    <Grid xs={12} item style={{textAlign:'right', padding:'0.5rem', height:'5%'}}><CloseIcon/></Grid>
                    <Grid xs={12} item style={{padding:'1rem', paddingTop:'0rem', height:'95%'}} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                        <div style={{padding:'1rem', border:'2px solid #AFA4C8', borderRadius:'5px'}}>
                            <Typography style={{fontSize:'1.4rem', fontWeight:'500'}}>
                                Activity Ttiel
                            </Typography>
                            <Grid container style={{color:'#262626'}}>
                                <Grid item xs={5}><Typography style={{fontSize:'0.8rem'}}>Activity Ttiel</Typography></Grid>
                                <Grid item xs={7}><Typography style={{fontSize:'0.8rem'}}>Activity Ttiel</Typography></Grid>
                                <Grid item xs={5}><Typography style={{fontSize:'0.8rem'}}>Activity Ttiel</Typography></Grid>
                                <Grid item xs={7}><Typography style={{fontSize:'0.8rem'}}>Activity Ttiel</Typography></Grid>
                            </Grid>
                            <Typography style={{fontSize:'0.8rem', padding:'2px'}}>
                                Activity Ttiel
                            </Typography>
                        </div>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}