import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    TextareaAutosize,
    Box, InputLabel, Tabs, Tab
} from '@material-ui/core';
import { useParams } from "react-router";
import { ReduxTextAreaField } from "components/form-components/form-field/ReduxTextAreaField";
import { APP_URL } from 'static';
import { ContentBox } from 'components/layout-components/Content';
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles(theme => ({
    sectionTitles: { color: theme.palette.primary.main }
}))

function ReportViewPage2() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    let { id } = useParams();
    const selectedReport = useSelector((state) => state.report.selectedReport);

    // const diarystaff = useSelector((state) => state.excel.work_cat_staff_data);
    // const diaryystaff_total = diarystaff.reduce(function(total, element){return total + element.count;}, 0);
    return (
        <Paper className='p-4'>
            <Grid container direction='row' spacing={3}>
                <Grid item xs={2} className='mt-3'>
                    <Typography className={classes.sectionTitles} style={{ fontWeight: 500 }}>
                        Typhoon Signal and Rainstorm Warning Signal
                        </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="warningSignalText"
                        readOnly={true}
                        rows={5}
                        multiline={true}
                        value={selectedReport.warning_remarks}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        disabled
                    />
                </Grid>
                <Grid item xs={2} className='mt-3'>
                    <Typography className={classes.sectionTitles} style={{ fontWeight: 500 }}>
                        Traffic Condition Information
                        </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="trafficText"
                        readOnly={true}
                        rows={5}
                        value={selectedReport.traffic_remarks}
                        multiline={true}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        disabled
                    />
                </Grid>
                <Grid item xs={2} className='mt-3'>
                    <Typography className={classes.sectionTitles} style={{ fontWeight: 500 }}>
                        Comments by Contractors Representative / Supervisors Delegate
                        </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="commentsText"
                        readOnly={true}
                        rows={5}
                        multiline={true}
                        value={selectedReport.comments}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        disabled
                    />
                </Grid>
                <Grid item xs={2} className='mt-3'>
                    <Typography className={classes.sectionTitles} style={{ fontWeight: 500 }}>
                        Visitor
                        </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="visitorText"
                        readOnly={true}
                        rows={5}
                        multiline={true}
                        value={selectedReport.visitor}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        disabled
                    />
                </Grid>
                <Grid item xs={2} className='mt-3'>
                    <Typography className={classes.sectionTitles} style={{ fontWeight: 500 }}>
                        Remarks
                        </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="remarksText"
                        readOnly={true}
                        rows={5}
                        multiline={true}
                        value={selectedReport.remarks}
                        fullWidth={true}
                        variant="outlined"
                        color="secondary"
                        disabled
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
export default ReportViewPage2;
