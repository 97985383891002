import Highcharts from 'highcharts';
import {InlineStyles} from 'stylesCss';
import {colorSet} from 'Page/UI_Design/Dashboard/Highcharts/colors';

export function column_configs(cat, series, title, xAxis, yAxis){
  return {
    chart: {
        type: 'column',
    },
    legend:{
        enabled:false,
    },
    colors:'colorSet',
    title: {
        text: title,
        align: 'left',
        x:14,
        y:28,
        style: {
            'color':InlineStyles.materialTables.title.color,
            'fontSize':InlineStyles.materialTables.title.fontSize,
            'fontWeight':InlineStyles.materialTables.title.fontWeight,
            'fontFamily':'Roboto',
        }
    },
    xAxis: {
        type: 'xAxis',
        categories: cat,
    },
    yAxis: {
        gridLineWidth:0,
        minorGridLineWidth:0,
        title: {
            text: yAxis
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
    },
    plotOptions: {
        column: {
            pointPadding: 0.1,
            // borderWidth: 20,
            groupPadding: 0.2,
            shadow: false
        }
    },
    series: series
  }
}
