  import axios from "axios";
  import {APP_URL} from "../static";
  export const defaultHeaders = {'Content-Type':'application/json;charset=utf-8'}

  export async function BackEndRequest (request_type, url_path, headers, payload) {
    headers['Authorization'] = 'Token '+JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || null) || null;
    const resp = await axios({url:APP_URL+url_path,method:request_type,data:payload,
      'headers':headers
    }).catch((error)=>{
      if (error.response ? error.response.status == 401: false){
        localStorage.setItem('LOGGED_IN', JSON.stringify(false));
        window.location.reload();
      }
      else{
        alert('unhandled API error' + url_path)
        throw 'unhandled API error'
      }
    })
    return resp
  }

  function error(url_path){
    alert('unhandled API error' + url_path)
    throw 'unhandled API error'
  }

  export async function BackEndRequestArrayBuffer (request_type, url_path, headers, payload) {
    headers['Authorization'] = 'Token '+JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || null) || null;
    const resp = await axios({url:APP_URL+url_path,method:request_type,data:payload,
      'headers':headers, 'responseType': 'arraybuffer'
    }).catch((error)=>{
      if (error.response ? error.response.status == 401: false){
        localStorage.setItem('LOGGED_IN', JSON.stringify(false));
        window.location.reload();
      }
      else{
        alert('unhandled API error')
        throw 'unhandled API error'
      }
    })
    return resp
  }

    export async function BackEndRequestFullURL (request_type, url, headers, payload) {
    headers['Authorization'] = 'Token '+JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH') || null) || null;
    const resp = await axios({url:url,method:request_type,data:payload,
      'headers':headers
    }).catch((error)=>{
      if (error.response ? error.response.status == 401: false){
        localStorage.setItem('LOGGED_IN', JSON.stringify(false));
        window.location.reload();
      }
      else{
        alert('unhandled API error')
        throw 'unhandled API error'
      }
    })
    return resp
  }