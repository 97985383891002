import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { useNavigate } from 'react-router-dom'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Notifications from '@material-ui/icons/Notifications';
import {Button, Divider, Link, List, ListItem, ListItemIcon, ListItemText, Menu, Card, CardHeader, Avatar, ListItemAvatar, Typography} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import {Logout} from "../../../../actions/Auth";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {GetNotificationsList, GetNotificationsNo, ReadNotification} from "../../../../actions/Notifications";

const useStyles = makeStyles(theme => ({
    menuOverride: {
        borderRadius: '0px 0px 4px 4px',
    },
    popUpMenu: {
        marginLeft: '2rem'
    },
    popUpMenuContent: {
        right: 20,
        left: 'auto',
        overflow: 'hidden',
        minWidth:'260px',
        boxShadow:'none'
    },
    popUpMenuList: {
        paddingBottom:0,
        textAlign: "left",
        alignItems: "center",
        // display: "flex",
        flexDirection: "column",
    },
    popUpMenuItem: {
        width: '100%'
    },
    popUpMenuItemIcon: {
        minWidth: 40
    },
    menuDivider: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    userProfile: {
        textAlign: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    userName: {
        color: theme.palette.text.primary,
        marginTop: theme.spacing(1),
        fontWeight: "bold"
    },
    userTitle: {
        color: theme.palette.text.secondary,
    },
}));

export default function ReminderBadge() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const full_name = useSelector(state => state.auth.full_name);
    const notificationList = useSelector(state => state.notification.notifications)
    const unreadCount = useSelector(state => state.notification.unreadCount)
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        const params = '?project='+selectedProject.id+'&page_size=5'
        GetNotificationsList(dispatch, params);
        GetNotificationsNo(dispatch, selectedProject.id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function readNotification(notification){
        ReadNotification(dispatch, notification)
        window.location.href = notification.link
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Badge badgeContent={unreadCount} max={99} color="error">
                    <Notifications style={{color:'#39344A'}}/>
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                open={Boolean(anchorEl)}
                anchorOrigin={{ vertical: 60, horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={handleClose}
                classes={{paper:classes.menuOverride}}
                >
                <div className={classes.popUpMenuContent}>
                    <List className={classes.popUpMenuList}>
                        <div className={classes.userProfile}>
                            <div className={classes.userTitle}>
                                Notifications
                            </div>
                        </div>
                        <Divider className={classes.menuDivider}/>
                        {notificationList.map((item, i) => (
                            <ListItem className={classes.popUpMenuItem} style={{backgroundColor:item.read?null:'#f2f2f2'}} onClick={()=>readNotification(item)} divider button dense>
                                <ListItemIcon className={classes.popUpMenuItemIcon}>
                                    <AccountCircleIcon/>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <div style={{fontWeight:item.read?null:'500'}}>{item.subject}</div>
                                  }
                                  secondary={
                                    <React.Fragment>
                                        <div style={{color:item.read?null:'#696969'}}>{item.message}</div>
                                        <div style={{fontWeight:item.read?null:'500', fontSize:'0.8rem'}}>{(new Date(item.created_time)).toLocaleTimeString() +', ' + (new Date(item.created_time)).toDateString()}</div>
                                    </React.Fragment>
                                  }
                                />
                            </ListItem>
                        ))}
                        {notificationList.length == 0 ? null :
                            null
                        }
                </List>
                </div>
            </Menu>
        </div>
    );
}
