import React, { useEffect } from "react";
import ImageUploading from "react-images-uploading";
import { GeneralButton } from 'components/other-components/Icons';
import { Grid } from '@material-ui/core';
import ImagePreview3 from "components/other-components/ImagePreview3";

function ImageUpload3(props) {
    const {reduxChange, value} = props;
    const [images, setImages] = React.useState(value);
    const maxNumber = 69;
    console.log('re-rendered')
    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
        if (reduxChange) {
            reduxChange(imageList);
        }
    };

      useEffect(() => {
        setImages(value)
      }, [value])

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps
            }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <Grid container style={{ marginBottom: '1rem' }}>
                        <GeneralButton label={'Add Image'}
                            style={isDragging ? { color: "red" } : null}
                            onClick={onImageUpload}
                            {...dragProps}
                        />
                &nbsp;
                {false ? <GeneralButton onClick={onImageRemoveAll} label={'Remove all images'} /> : null}
                    </Grid>
                    {imageList.map((image, index) => (
                        <ImagePreview3 update={onImageUpdate} clear={onImageRemove} index={index} src={image.data_url} />
                    ))}
                </div>
            )}
        </ImageUploading>
    );
}

export default ImageUpload3;
