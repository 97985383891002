import React, { useEffect } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { change, Field } from "redux-form";
import { InputAdornment } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';


const ReduxDateFieldComponent = ({ input, meta: { touched, error }, variant, popUpVariant, ...rest }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                fullWidth
                error={touched && Boolean(error)}
                autoOk
                disableToolbar
                format="yyyy-MM-dd"
                label={rest.label}
                value={input.value || null}
                onChange={value => input.onChange(value)}
                variant={popUpVariant || 'inline'}
                inputVariant={variant} 
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                            <KeyboardArrowDown />
                        </InputAdornment>
                    ),
                    style: {paddingRight: 7}
                }}
            />
        </MuiPickersUtilsProvider>
    );
};


export const ReduxDateField = props => {
    return (
        <Field
            variant="outlined"
            popUpVariant="inline"
            component={ReduxDateFieldComponent}
            {...props}
        >
        </Field>
    )
};
