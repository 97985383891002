import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    Stepper,
    Step,
    StepButton,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import {ReduxRequired} from "../../../components/form-components/validation";
import {ReduxTextField} from "../../../components/form-components/form-field/ReduxTextField";
const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function Step2(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }

    function SubSection1(){
        return (
            <React.Fragment>
                <Grid item xs={8}>
                    <FormLabel component="legend" style={{color: '#15B1D7'}}>Number of persons engaged at site </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <FormLabel component="legend" style={{color: '#15B1D7'}}>Total</FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <FormLabel component="legend" style={{color: '#15B1D7'}}>Of whichFemale</FormLabel>
                </Grid>
                <Grid item xs={8}>
                    <FormLabel component="legend">(i) Manual workers </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <ReduxTextField
                        name={"manual_workers"}
                        label={""}
                        validate={[ReduxRequired]}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                    <ReduxTextField
                        name={"manual_workers_fem"}
                        label={""}
                        validate={[ReduxRequired]}
                        size='small'
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormLabel component="legend">(ii) Professional / technologist </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <ReduxTextField
                        name={"professionals"}
                        label={""}
                        validate={[ReduxRequired]}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={8}>
                    <FormLabel component="legend">(iii) Technician </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <ReduxTextField
                        name={"technicians"}
                        label={""}
                        validate={[ReduxRequired]}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={8}>
                    <FormLabel component="legend">(iv) Other site personnel (e.g. amah and security guards) </FormLabel>
                </Grid>
                <Grid item xs={2}>
                    <ReduxTextField
                        name={"other_personnel"}
                        label={""}
                        validate={[ReduxRequired]}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid container item xs={12}>
                <Typography style={{color:'#B00020'}}>***Please insert either the data or ‘0’. Do not leave any cell blank. </Typography>
            </Grid>
            <Grid container item xs={12}>
                <FormLabel component="legend">I. Number of persons engaged and vacancies on the last day of the reporting month . </FormLabel>
            </Grid>
            <SubSection1/>
            <Grid item xs={8}>
                <FormLabel component="legend">(A) Total number of persons engaged at site </FormLabel>
            </Grid>
            <Grid item xs={2}>
                <ReduxTextField
                    name={"total_engaged"}
                    label={""}
                    validate={[ReduxRequired]}
                    size='small'
                />
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={8}>
                <FormLabel component="legend">(B) Number of employers / self-employed persons in (A) </FormLabel>
            </Grid>
            <Grid item xs={2}>
                <ReduxTextField
                    name={"employers"}
                    label={""}
                    validate={[ReduxRequired]}
                    size='small'
                />
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={8}>
                <FormLabel component="legend">(C) Number of vacancies for manual workers (i.e. excluding items (ii), (iii) and (iv)above) at site </FormLabel>
            </Grid>
            <Grid item xs={2}>
                <ReduxTextField
                    name={"vacancies"}
                    label={""}
                    validate={[ReduxRequired]}
                    size='small'
                />
            </Grid>
            <Grid item xs={2}>
            </Grid>
            <Grid container item xs={12}>
                <FormLabel component="legend">II. If there are vacancies in I (C) above, please provide the job title and the number of vacancies.  </FormLabel>
            </Grid>
            <Grid item xs={8}>
                <FormLabel component="legend" style={{color: '#15B1D7'}}>Job title (e.g. leveller, truck driver, bricklayer and electrical fitter)</FormLabel>
            </Grid>
            <Grid item xs={4}>
                <FormLabel component="legend" style={{color: '#15B1D7'}}>Number of vacancies</FormLabel>
            </Grid>

            <Grid item xs={8}>
                <ReduxTextField
                    name={"job_title1"}
                    label={"Job Title"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"number_of_vacancies1"}
                    label={"Number of Vacancies"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={8}>
                <ReduxTextField
                    name={"job_title2"}
                    label={"Job Title"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"number_of_vacancies2"}
                    label={"Number of Vacancies"}
                    validate={[ReduxRequired]}
                />
            </Grid>
        </Grid>
    );
}
export default Step2;