import React, { useEffect, useState } from "react";
import { Field, FieldArray, reduxForm } from 'redux-form';
import { IconButton, Button, Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Divider, Hidden } from '@material-ui/core'
import 'antd/dist/antd.css';

import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxRequired } from "components/form-components/validation";

import { ReduxNormalSelectField } from "../../../../components/form-components/form-field/ReduxNormalSelectField";
import {ReduxAutoCompleteFieldByValue} from "../../../../components/form-components/form-field/ReduxAutoCompleteFieldByValue";
import { useSelector, useDispatch } from "react-redux";
import Add from "@material-ui/icons/Add";
import { isEmpty } from "../../js_utils/general";
import { RemoveCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import { required, number, maxLength } from 'components/form-components/validation/fields';
import { GeneralAddMore } from "components/other-components/Icons";

const maxLength3 = maxLength(3)
const maxLength100 = maxLength(100)
const useStyles = makeStyles(theme => ({
    //   add: theme.styles.miniAddMore.add,
    //   addButton: theme.styles.miniAddMore.addButton
}))


function ActivityFormPlants({ fields }) {
    const dispatch = useDispatch();
    const plants = useSelector((state) => state.presets.plants);
    const companies = useSelector((state) => state.presets.companies);
    const copyPlant = useSelector((state) => state.project.copyPlant);
    const classes = useStyles();
    useEffect(() => {
        if (!isEmpty(copyPlant)) {
            fields.push({
                plant: copyPlant.plant.id,
                unit_id: copyPlant.unit_id,
                working_no: copyPlant.working_no,
                idling_no: copyPlant.idling_no,
                idling_code: copyPlant.idling_code,
                mc_sc: copyPlant.mc_sc ? copyPlant.mc_sc.id : null,
            });
            dispatch({ type: 'COPYPLANT', payload: {} });
            // renderStaffList()
        }
    }, [copyPlant]);
    return (
        <Grid item xs={9} container spacing={4}>
            {fields.map((plant, index) =>
                <Grid item xs={12} container direction='row' spacing={3} key={'plantRowGrid' + index}>
                    <Grid item xs={10} lg={7}>
                        <ReduxAutoCompleteFieldByValue
                            getOptionLabel={(option) => (option.type)}
                            multiple={false}
                            name={`${plant}.plant`}
                            label={"Plant" + (index + 1) +'*'}
                            validate={[ReduxRequired]}
                            dataSet={plants}
                            dataFieldName={'id'}
                        >
                        </ReduxAutoCompleteFieldByValue>
                    </Grid>
                    <Hidden lgUp>
                        <Grid item xs={2} className='text-right'>
                            <IconButton
                                onClick={() => fields.remove(index)}
                            // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                            >
                                <RemoveCircle style={{ color: '#AFA4C8' }} />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} lg={3}>
                        <ReduxTextField
                            name={`${plant}.working_no`}
                            label="Working No.*"
                            validate={[ReduxRequired, number]}
                        />
                    </Grid>
                    <Hidden mdDown>
                        <Grid item xs={2} className='text-right'>
                            <IconButton
                                onClick={() => fields.remove(index)}
                            // style={{ width: '10px', transform: 'translate(0,-14px)' }}
                            >
                                <RemoveCircle style={{ color: '#AFA4C8' }} />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} lg={4}>
                        <ReduxTextField
                            name={`${plant}.unit_id`}
                            label={"Plant " + (index + 1) + " ID"}
                            validate={[maxLength100]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={3}>
                        <ReduxTextField
                            name={`${plant}.idling_no`}
                            label="Idling No."
                            validate={[number]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={3}>
                        <ReduxTextField
                            name={`${plant}.idling_code`}
                            label="Idling Code"
                            validate={[maxLength3]}
                        />
                    </Grid>
                    <Grid item xs={10} lg={5}>
                         <ReduxAutoCompleteFieldByValue
                             getOptionLabel={(option) => (option.name)}
                             multiple={false}
                             name={`${plant}.mc_sc`}
                             label={'BK/SC'}
                             validate={[]}
                             dataSet={companies}
                             dataFieldName={'id'}
                        >
                        </ReduxAutoCompleteFieldByValue>
                    </Grid>
                    <Grid item xs={10}>
                        { index>=0 && index!=fields.length-1 ? 
                            <div style={{ border: '1px #AFA4C8 dashed'}}></div>
                            :
                            <Divider className='' style={{ backgroundColor: '#AFA4C8' }} />
                        }
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={10}>
                    <GeneralAddMore onClick={()=>fields.push({})} />
                </Grid>
                {/* <Button onClick={() => fields.push({})} className={classes.addButton}><Add className={classes.add} />ADD MORE</Button> */}
            </Grid>
        </Grid >
    )
}

export default ActivityFormPlants;