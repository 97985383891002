export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

export function downloadBlobFile(newBlob, filename){
   const url = window.URL.createObjectURL(newBlob);
   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', filename);
   document.body.appendChild(link);
   link.click();
}

// function toSinglePDF(imgsrc) {
//   console.log(imgsrc);
//   const blob = pdf(ImageDoc(imgsrc)).toBlob();
//   console.log(blob);
//   let formdata = new FormData();
//
//   formdata.append('file', blob, 'asd.pdf');
//   formdata.append('file', blob, 'asd2.pdf');
//   axios.post(APP_URL+'/pdf/', {'pdf_files':formdata},{ headers: {"Content-Type": "multipart/form-data"}})
//     .then((resp) => console.log(resp.data));
//     // .catch((error) => (console.log(error.response)))
//     // .then((resp) => reduxDispatch(AddAttachment(resp.data.file))
// }
