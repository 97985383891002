export const ReduxRequired = value => {
    if (value !== undefined) {
        if (value === null || value.length === 0) {
            return 'Required';
        }
    }

    if (value) {
        return undefined;
    }
    else {
        if (value === 0) {
            return undefined;
        }
    }
    return 'Required'
};