import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import axios from 'axios';
import {
  Button,
  Grid,
  TextField,
  Paper,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box
} from '@material-ui/core';
import tableIcons from "../../../components/other-components/MaterialTable/TableIcons";
import MaterialTable from "material-table";
import {InlineStyles} from 'stylesCss';
import {APP_URL} from 'static.js';
import {CustomFilter, CustomToolBar} from "../../../components/other-components/MaterialTable/Components";
import MaterialTableBase from 'Page/UI_Design/Presets/AdminMaterialTableCRUDBase';
import {WrapperBox} from "../../../components/layout-components/Content";
import {getPresetField} from "../../../actions/Presets";

function MaterialDescTable() {

  const selectedProject = useSelector((state) => state.project.selectedProject);
  const [materials, setList] = useState();
    const [isLoading, setLoad] = useState(true);

  useEffect(() => {
    getPresetField(selectedProject.id, 'materials').then((resp) => setList(resp.data.materials));
    setLoad(false)
  }, [selectedProject]);

    function beforeAdd(newData){
      newData.project = selectedProject.id;
      return newData
    }
  return (
    <MaterialTableBase
        titleText='List of Materials'
        data={materials}
        setList={setList}
        crudURL={APP_URL+'/site/presets/material/'}
        beforeAdd={beforeAdd}
        beforeUpdate={(a)=>a}
        CustomLoading={isLoading}
        allowFilter={false}
      columns={[
        { title: 'Description', field: 'description'},
      ]}
      components={{
        Container: props => <WrapperBox>{props.children}</WrapperBox>,
        FilterRow: props => <CustomFilter {...props}/>,
        Header: props => (null),
        Toolbar: props => (
          <CustomToolBar {...props}/>
        ),
      }}
    />
  );
}

export default MaterialDescTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
