import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import allReducers from "./reducers/index";
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import 'react-app-polyfill/ie9';
// import './index.css';
// import './override-everything.css';
// import registerServiceWorker from './registerServiceWorker';
import WebFont from 'webfontloader';
import 'i18n';
import 'assets/base.scss';


const reduxDev = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const composeEnhance = compose(
    applyMiddleware(thunk),
    // reduxDev
);

const store = createStore(allReducers,
    composeEnhance)

const container = document.getElementById("root");

WebFont.load({
    google: {
        families: ['Roboto:400,500,700', 'sans-serif']
    }
});

ReactDOM.render((
    <Suspense fallback="loading">
        <Provider store={store}>
            <BrowserRouter>
                {/*<PDFContextProvider>*/}
                <App />
                {/*</PDFContextProvider>*/}
            </BrowserRouter>
        </Provider>
    </Suspense>
), container);
// debugContextDevtool(container, {});//options (Slow!)
serviceWorker.unregister();
// registerServiceWorker();

