import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'

import MaterialTable from 'material-table';
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import { InlineStyles } from 'stylesCss';

import { Field } from "redux-form";
import axios from "axios";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import { APP_URL } from 'static.js';
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { CustomToolBar } from "../../../components/other-components/MaterialTable/Components";
import { GeneralButton } from "../../../components/other-components/Icons";
import ImagePreview3 from "../../../components/other-components/ImagePreview3";
import ImageUploading from "react-images-uploading";
import {UploadReportDocument} from "../../../actions/Report";
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    // add: theme.styles.miniAddMore.add,
    // addButton: theme.styles.miniAddMore.addButton
}))



function ReportTable(props) {
    const { reportList, refresh } = props;
    const tableRef = React.createRef();
    const navigate = useNavigate();
    const classes = useStyles();

    function UploadDocument(id, e) {
        const formData = new FormData();
        formData.append('File', e.target.files[0])
        UploadReportDocument(id, formData).then(response => {
            refresh();
        })
    }

    function UploadButton(props) {
        const { id } = props
        return (
            <span>
                <label htmlFor="myInput">
                    <svg color={InlineStyles.primaryColor} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" /></svg>
                </label>
                <input
                    id="myInput"
                    style={{ display: 'none' }}
                    type={"file"}
                    onChange={(e) => UploadDocument(id, e)}
                />
            </span>
    )
  }
  return (
      <MaterialTable
          title={<Typography color='primary' style={{ fontWeight: 500 }}>List of Reports</Typography>}
          tableRef={tableRef}
          icons={tableIcons}
          columns={[
              { title: 'Date', field: 'date'},
            { title: 'Submitted By',
                render: rowData => (
                <React.Fragment>{rowData.updated_by ? <span>{rowData.updated_by.full_name}</span> : <span></span>} </React.Fragment>
            )},
            { title: 'Update By',
                render: rowData => (
                <React.Fragment>{rowData.updated_by ? <span>{rowData.updated_by.full_name}</span> : <span></span>} </React.Fragment>
            )},
            { title: 'Last Updated On', field: 'updated_time',
                render: rowData => (
                <React.Fragment>{rowData.updated_by ? <span>{(moment((rowData.updated_time)).format('YYYY-MM-DD h:mm a'))}</span> : <span></span>} </React.Fragment>
            )},
            { title: 'Daily Activities', field: 'activities',
                render: rowData => (
                <React.Fragment>{rowData.activities ? <span>Submitted</span> : <Button onClick={() => navigate('/app/activity_form/none')} color='primary'><Add fontSize='inherit' />ACTIVITY</Button>} </React.Fragment>
            )},
            { title: 'Daily Report', field: 'report',
                render: rowData => (
                <React.Fragment>{rowData.status == 'pending' ? <Button onClick={() => navigate('/app/report_form/' + rowData.id)} color='primary'><Add fontSize='inherit' />REPORT</Button> : <span>Submitted</span>}</React.Fragment>
            )},
            { title: 'Uploaded Document', field: 'report_document',
                render: rowData => (
                <React.Fragment>
                  {rowData.status == 'completed' ?
                      <span>Completed</span> :
                      <span>Pending<UploadButton id={rowData.id}/></span>}
                </React.Fragment>
            )},
            { title: 'View Report', field: 'id',
                render: rowData => (
                <React.Fragment>{rowData.status == 'pending' ? <span></span> : <Button onClick={() => navigate('/app/report_view/' + rowData.id)} color='primary'>VIEW</Button>} </React.Fragment>
            )},
          ]}
          data={reportList}
          options={{
            rowStyle: {
              // fontSize: InlineStyles.materialTables.row.fontSize,
            },
            pageSize:10,
            pageSizeOptions:[10],}
        }
        components={{
          Container: props => <div>{props.children}</div>,
              Toolbar: props => (
              <CustomToolBar {...props}/>
          ),
        }}
        actions={[
              ]}
        editable={{
        }}
    />
  );
}

export default ReportTable;

