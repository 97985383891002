import React, { useState, useMemo, useEffect, useRef, forwardRef } from 'react'
import MaterialTable, { MTableToolbar, MTableFilterRow } from 'material-table';
import { CustomToolBar, CustomFilter } from 'components/other-components/MaterialTable/Components';
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import { ContractorLookUp, StaffLookUp, LabourTradeLookUp } from "./MaterialTableLookUps";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {InlineStyles} from 'stylesCss';
import {APP_URL} from 'static.js';
import PropTypes from "prop-types";
import { dateToString } from "Page/UI_Design/js_utils/general";
import { Box, Button, Dialog, TablePagination, Typography } from '@material-ui/core';
import OverlaySuccess from "../OverlaySuccess";
import {TableLoader} from "../TableLoader";
import {WrapperBox} from "../../layout-components/Content";
import {BackEndRequestFullURL, defaultHeaders} from "../../../actions/APICalls";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MaterialTableCRUDBase(props) {
    const { titleText, data, setList, crudURL, beforeAdd, beforeUpdate, allowFilter, CustomLoading } = props
    const dispatch = useDispatch()
    const tableRef = React.createRef();
    const [overlayVis, setVis] = useState(false);
    const [successMsg, setMsg] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState();
    const [disableDelete, setDisableDelete] = useState(false);

    useEffect(() => {
        if(!CustomLoading){
            tableRef.current.dataManager.changePageSize(10)
        }
    }, [CustomLoading])

    const cancelDelete = () => {
        setOpenDeleteModal(false);
        setDeleteRowData(null);
    }

    const deleteRow = () => {
        setDisableDelete(true);
        const index = deleteRowData.id;
        axios.delete(crudURL + index + '/', {}).then(response => {
            console.log(response);
            const dataDelete = [...data];
            const index = deleteRowData.tableData.id;
            dataDelete.splice(index, 1);
            setList([...dataDelete]);
            setVis(true)
            setMsg('Deleted Successfully');
            setOpenDeleteModal(false);
            setDisableDelete(false);
        }).catch(error => {
            console.log(error)
            console.log(error.response);
        });
    }

    return (
        <div style={{ position: 'relative' }}>
            {CustomLoading ?
                <TableLoader />
            :null}
            <OverlaySuccess message={successMsg} setVis={setVis} overlayVis={overlayVis} />
            <MaterialTable
                localization={{ body:{ emptyDataSourceMessage:<span>{CustomLoading?null:'No records to display'}</span>}}}
                icons={tableIcons}
                tableRef={tableRef}
                title={<Typography color='primary' style={{ fontWeight: 500 }}>{titleText}</Typography>}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    filtering: { allowFilter },
                    rowStyle: {
                        // fontSize: InlineStyles.materialTables.row.fontSize
                    },
                    sorting: true,
                    addRowPosition: 'first'
                }
                }
                components={{
                    Container: props => <WrapperBox>{props.children}</WrapperBox>,
                    FilterRow: props => <CustomFilter {...props} />,
                    Toolbar: props => (
                        <CustomToolBar {...props} />
                    ),
                    Pagination: props => (
                        <TablePagination
                            {...props}
                        />
                    )
                }}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                // newData.staff_id = newData.staff.id;
                                // newData.project_id = selectedProject.id;
                                console.log(newData)
                                newData = beforeAdd(newData)
                                // console.log(newData)
                                BackEndRequestFullURL('post',crudURL, defaultHeaders, newData).then(response =>{
                                    setList([...data, response.data]);
                                    setVis(true)
                                    setMsg('Added Successfully')
                                })
                                    .catch(error => {
                                        console.log(error)
                                        console.log(error.response);
                                    });
                                // setData([...data, newData]);
                                resolve();
                            }, 1000)
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            setTimeout(() => {
                                const index = oldData.id;
                                // newData.staff_id = newData.staff.id;
                                newData = beforeUpdate(newData)
                                // console.log(newData)
                                BackEndRequestFullURL('put',crudURL+index+'/', defaultHeaders, newData).then(response =>{
                                    const dataUpdate = [...data];
                                    const index = oldData.tableData.id;
                                    dataUpdate[index] = response.data;
                                    setList([...dataUpdate]);
                                    setVis(true)
                                    setMsg('Updated Successfully')
                                })
                                    .catch(error => {
                                        console.log(error)
                                        console.log(error.response);
                                    });
                                resolve();
                            }, 1000)
                        }),
                    // onRowDelete: oldData =>
                    //     new Promise((resolve, reject) => {
                    //         setTimeout(() => {
                    //             const index = oldData.id;
                    //             BackEndRequestFullURL('delete',crudURL+index+'/', defaultHeaders, {}).then(response =>{
                    //                 console.log(response);
                    //                 const dataDelete = [...data];
                    //                 const index = oldData.tableData.id;
                    //                 dataDelete.splice(index, 1);
                    //                 setList([...dataDelete]);
                    //                 setVis(true)
                    //                 setMsg('Deleted Successfully')
                    //             })
                    //                 .catch(error => {
                    //                     console.log(error)
                    //                     console.log(error.response);
                    //                 });
                    //             resolve()
                    //         }, 1000)
                    //     }),
                }}
                actions={[
                    allowFilter ? {
                        icon: tableIcons.Filter,
                        tooltip: 'Filter',
                        isFreeAction: true,
                        onClick: () => { dispatch({ 'type': 'TOGGLEFILTER' }); },
                    } : null,
                    rowData => ({
                        icon: tableIcons.Delete,
                        isFreeAction: false,
                        onClick: (event, rowData) => {
                            setOpenDeleteModal(true);
                            setDeleteRowData(rowData);
                        },
                    })
                ]}
                {...props}
            />
            <Dialog open={openDeleteModal} onClose={() => cancelDelete()} classes={{ paper: 'shadow-lg rounded' }}>
                <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">
                        <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                            <FontAwesomeIcon icon='times' className="d-flex align-self-center display-3" />
                        </div>
                    </div>
                    <h4 className="font-weight-bold mt-4">Are you sure you want to delete this entry?</h4>
                    {/* <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p> */}
                    <div className="pt-4">
                        <Button onClick={() => cancelDelete()} className="btn-neutral-secondary btn-pill mx-1">
                            <span className="btn-wrapper--label">
                                Cancel
                            </span>
                        </Button>
                        <Button onClick={() => deleteRow()} className="btn-danger btn-pill mx-1" disabled={disableDelete}>
                            <span className="btn-wrapper--label">
                                Delete
                            </span>
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div >
    )
}

export default MaterialTableCRUDBase;
