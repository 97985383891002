import React, {useEffect, useState, createRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardContent, CardHeader, makeStyles, TextField, Typography
} from '@material-ui/core';
import tableIcons from "../../../components/other-components/MaterialTable/TableIcons";
import { ContractorLookUp } from "../../../components/other-components/MaterialTable/MaterialTableLookUps";
import MaterialTable, { MTableToolbar } from "material-table";
import { InlineStyles } from 'stylesCss';
import { APP_URL } from 'static';
import { Edit, DeleteOutline, Check, Clear } from "@material-ui/icons/";
import {loadLocations} from 'actions/Presets';
import MaterialTableBase from "./AdminMaterialTableCRUDBase";
import { CustomFilter } from "../../../components/other-components/MaterialTable/Components";
import { WrapperBox } from "../../../components/layout-components/Content";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {getSubLocation, updateLocation} from "actions/Presets";

const useStyles = makeStyles(theme => ({
    addIconColor: { color: theme.palette.highlights.main },
    searchField: {
        color: '#FFF',
        '& .MuiInput-underline:before, & .MuiInput-underline:after, & .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiIconButton-label': {
            color: '#FFF'
        }
    }
}
))

function SubLocationTable() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const selectedLocation = useSelector((state) => state.presets.selectedLocation);
    const [subLocations, setSubLocations] = useState([])
    const tableRef = React.createRef();
    const [editName, setEdit] = useState(false)
    const [deleteItem, toggleDelete] = useState(false)
    const nameEditor = createRef();
    const [editingValue, setValues] = useState('');
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [isLoading, setLoad] = useState(true);

  useEffect(() => {
    if(selectedLocation.id) {
      getSubLocation(selectedLocation.id).then((resp) => setSubLocations(resp.data.results));
      setLoad(false);
      console.log(subLocations);
    }
    setEdit(false);
    toggleDelete(false);
  }, [selectedLocation]);

    useEffect(() => {
        setSubLocations([]);
    }, [selectedProject]);

    function toggleEdit(bool) {
        setValues(selectedLocation.name);
        setEdit(bool);
    }

  function updateName(){
    const newData = {'name':editingValue}
    updateLocation(selectedLocation.id, newData).then(response => {
      dispatch({'type':'SELECTLOCATION', 'payload':response.data});
      loadLocations(dispatch, selectedProject.id);
    })
  }

  function deleteLocation(bool){
    updateLocation(selectedLocation.id,{'soft_deleted':bool}).then(response => {
      dispatch({'type':'SELECTLOCATION', 'payload':response.data});
      loadLocations(dispatch, selectedProject.id);
    })
  }

    function beforeAdd(newData) {
        console.log(selectedLocation)
        newData.location = selectedLocation.id;
        newData.project = selectedLocation.project;
        return newData
    }

    function beforeUpdate(newData) {
        newData.location = selectedLocation.id;
        newData.project = selectedLocation.project;
        return newData
    }
    return selectedLocation.name ? (
        <MaterialTableBase
            data={subLocations}
            setList={setSubLocations}
            crudURL={APP_URL + '/site/presets/sublocation/'}
            beforeAdd={beforeAdd}
            beforeUpdate={beforeUpdate}
            // CustomLoading={isLoading}
            allowFilter={false}
            title={
                <div style={{ color: '#FFF' }}>
                    {editName ?
                        <React.Fragment>
                            <TextField
                                autoFocus={true}

                                value={editingValue}
                                onChange={(e) => setValues(e.target.value)}
                                inputProps={{
                                    style: { color: '#FFF' }
                                }}
                            ></TextField>
                            <span className='ml-2 p-2'><Check onClick={() => updateName()} /></span>
                            <span className='p-2'><Clear onClick={() => toggleEdit(false)} /></span>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {deleteItem ?
                                <React.Fragment>
                                    <span><em>Are you sure you want to disable this Location?</em></span>
                                    <span className='ml-2 p-2'><Check onClick={() => (deleteLocation())} /></span>
                                    <span className='p-2'><Clear onClick={() => toggleDelete(false)} /></span>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography style={{ fontWeight: 500, color: 'white' }}>
                                        {selectedLocation.name}
                                        {selectedLocation.soft_deleted
                                            ? <span className='ml-2 p-2' onClick={()=>deleteLocation(false)}><LockIcon/></span>
                                            : <span className='ml-2 p-2' onClick={()=>toggleDelete(true)}><LockOpenIcon/></span>
                                        }
                                        {/*<span onClick={()=>toggleDelete(!deleteItem)}><DeleteOutline/></span>*/}
                                        <span onClick={() => toggleEdit(!editName)} className='p-2'><Edit /></span>
                                    </Typography>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                </div>
            }
            columns={[
                { title: 'Name', field: 'name' },
            ]}
            components={{
                Container: props => <WrapperBox>{props.children}</WrapperBox>,
                FilterRow: props => <CustomFilter {...props} />,
                Toolbar: props => (
                    <div style={{ backgroundColor: '#15B1D7', color: '#FFF' }}>
                        <MTableToolbar classes={{ searchField: classes.searchField }} {...props} />
                    </div>),
                Header: props => (null)
            }}
            options={{
                searchFieldStyle: {
                    color: '#FFF',
                },
                addRowPosition: 'first',
              actionsColumnIndex:-1
            }}
            blueHeader={true}
        />

    )
        :
        (<Card className='rounded-0 w-100 h-100'>
            <CardHeader style={{ backgroundColor: '#15B1D7', height: 64 }}>

            </CardHeader>
            <CardContent className='h-100 d-flex justify-content-center align-items-center'>
                <div>Select a location to preview</div>
            </CardContent>
        </Card>);
}

export default SubLocationTable;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
