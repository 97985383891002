import React, { useRef } from "react";
import MaterialTable from 'material-table';
import { AppBar, Tab, Tabs, Button, Popover, Grid, Tooltip, Paper, Select, MenuItem, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@material-ui/core'
import { TabContext } from '@material-ui/lab';
import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import IdlingCodePopUp from "Page/UI_Design/Activity/ActivityList/IdlingCodePopUp";
import TabPanel from 'components/other-components/TabPanel';

const activeTabsColor = '#15B1D7'

const useStyles = makeStyles((theme) => ({
    tab: {
        backgroundColor: theme.palette.background.paper,
    },
}))

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function ActivityDetailsPopUp(props) {
    const { rowData, selectType } = props;
    // const {selectedRow} = props
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [onEntered, setOnEntered] = React.useState(false);
    const [type, setType] = React.useState(selectType);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOnEntered(false);
    };

    const handleEntered = () => {
        setOnEntered(true);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function LabourTable() {
        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Trade</TableCell>
                            <TableCell align="center">No.</TableCell>
                            <TableCell align="left">BK/SC</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.labours.map((e, i) => (
                            <TableRow key={'labours' + i}>
                                <TableCell component="th" scope="row">
                                    <Typography style={{ marginBottom: '0.5rem', fontSize: '0.9rem' }}>{e.trade ? e.trade.name : ''}</Typography>
                                    {e.staff.map((s, si) => (
                                        <div>
                                            <div style={{ marginLeft: '0.5rem', marginTop: '0.3rem' }}>
                                                <Typography style={{ color: activeTabsColor, fontSize: '0.8rem' }}>{s.eng_name}</Typography>
                                            </div>
                                        </div>
                                    ))}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" style={{ verticalAlign: 'top' }}>
                                    {e.no}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                    {e.mc_sc ? e.mc_sc.name : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function PlantTable() {
        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="center">Working No.</TableCell>
                            <TableCell align="center">Idling No.</TableCell>
                            <TableCell align="center" className='d-flex flex-row align-items-center'>
                                <div>Idling Code</div><IdlingCodePopUp />
                            </TableCell>
                            <TableCell align="left">MC/SC</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.plants.map((e, i) => (
                            <TableRow key={'plants' + i}>
                                <TableCell component="th" scope="row">
                                    {e.plant.type}
                                </TableCell>
                                <TableCell component="th" scope="row"  style={{ verticalAlign: 'top' }}>
                                    {e.unit_id}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" style={{ verticalAlign: 'top' }}>
                                    {e.working_no}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" style={{ verticalAlign: 'top' }}>
                                    {e.idling_no}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" style={{ verticalAlign: 'top' }}>
                                    <span>{e.idling_code}</span>
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                    {e.mc_sc ? e.mc_sc.name : ''}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function MaterialTable() {
        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="center">QTY.</TableCell>
                            <TableCell align="left">MC/SC</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowData.materials.map((e, i) => (
                            <TableRow key={'materials' + i}>
                                <TableCell component="th" scope="row">
                                    {e.material.description}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center" style={{ verticalAlign: 'top' }}>
                                    {e.quantity}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                    {e.mc_sc?.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    function changeTab(event, newValue) {
        setType(newValue);
    }

    return (
        <React.Fragment>
            {selectType == 0 ?
                <div className='d-flex flex-row align-items-center'>
                    <div>{rowData.labours.reduce((a, b) => a + b.no, 0)}&nbsp;</div>
                    <div onClick={(e) => { handleClick(e); setType(0); }}><MoreHoriz style={{ fill: "#15B1D7" }} /></div>
                </div>
                : null}
            {selectType == 1 ?
                <div className='d-flex flex-row align-items-center'>
                    <div>{rowData.plants.reduce((a, b) => a + b.working_no, 0)}&nbsp;</div>
                    <div onClick={(e) => { handleClick(e); setType(1); }}><MoreHoriz style={{ fill: "#15B1D7" }} /></div>
                </div>
                : null}
            {selectType == 2 ?
                <div className='d-flex flex-row align-items-center'>
                    <div>{rowData.materials.length}&nbsp;</div>
                    <div onClick={(e) => { handleClick(e); setType(2); }}><MoreHoriz style={{ fill: "#15B1D7" }} /></div>
                </div>
                : null}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onEntered={handleEntered}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                <Paper className={classes.root} style={{ width: '500px' }}>
                    {false ?
                        <TabContext value={'undefined'}>
                            <Tabs
                                onChange={changeTab}
                                aria-label="activity tabs"
                                variant="fullWidth"
                                value={type}
                                indicatorColor='primary'
                                textColor='primary'
                            >
                                <Tab label="Labours" {...a11yProps(0)} />
                                <Tab label="Plants" {...a11yProps(1)} />
                                <Tab label="Materials" {...a11yProps(2)} />
                            </Tabs>
                        </TabContext>
                        : null}
                    <TabPanel value={type} index={0}>
                        <LabourTable />
                    </TabPanel>
                    <TabPanel value={type} index={1}>
                        <PlantTable />
                    </TabPanel>
                    <TabPanel value={type} index={2}>
                        <MaterialTable />
                    </TabPanel>
                </Paper>

            </Popover>

        </React.Fragment>
    );
}

export default ActivityDetailsPopUp;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
