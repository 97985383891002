import React, {useEffect} from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import {change, Field} from "redux-form";


const ReduxTimeFieldComponent = ({input, meta: {touched, error}, variant, popUpVariant, ...rest}) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                fullWidth
                error={touched && Boolean(error)}
                autoOk
                label={rest.label}
                value={input.value || null}
                onChange={value => input.onChange(value)}
                variant={popUpVariant || 'inline'}
                inputVariant={variant}
            />
        </MuiPickersUtilsProvider>
    );
};


export const ReduxTimeField = props => {
    return (
        <Field
            variant="outlined"
            popUpVariant="inline"
            component={ReduxTimeFieldComponent}
            {...props}
        >
        </Field>
    )
};
