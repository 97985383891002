import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux'
import { useSelector, useDispatch, connect } from 'react-redux';
import {
    Grid,
    Paper,
    TextField,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box,
    Button
} from '@material-ui/core'
import 'antd/dist/antd.css';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { SyncAlt } from '@material-ui/icons';
import SelectStaff from 'components/old/Forms/Select/SelectStaff';
import axios from "axios";
import {APP_URL} from 'static.js';
import {GetProjectDetails} from "../../../actions/Auth";

const required = value => value ? undefined : 'Required'

const useStyles = makeStyles(theme => ({
    paper: {
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        float: 'left',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        top: '15%',
        left: '30%',
        padding: theme.spacing(4),
        paddingLeft: '70px',
        paddingRight: '70px',
        borderRadius: '5px'
    },
    title: {
        fontWeight: '600',
        fontSize: '1rem'
    },
    projectBox: {
        border: '1px solid #15B1D7',
        backgroundColor: 'white',
        minWidth: '800px',
        borderRadius: '10px',
        minHeight: 80,
        '&:hover': {
            backgroundColor: '#F2FFFE'
        },
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    // projectBoxTitle: theme.styles.font.tertiary,
    projectBoxDesc: {
        marginTop: '5px',
        fontSize: '12px'
    },
    // button: theme.styles.buttons
}));

function getModalStyle() {
    const top = 0;
    const left = 0;
    return {
        top: `${top}%`,
        left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
    };
}

function ProjectSwitch() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [projectList, setList] = React.useState([]);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    // const loggedIn = JSON.parse(localStorage.getItem('LOGGED_IN'))||false;
    const loggedIn = useSelector((state) => state.auth.loggedIn);
  useEffect(() => {
      if (loggedIn) {
          GetProjectDetails().then((resp) => {
                  setList(resp.data.results)
              }
          )
      }
  },[loggedIn])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  function chooseProject(proj){
    dispatch({'type':'SELECTPROJECT', 'payload':proj})
    handleClose();
  }

    return (
        <div>
            <Box boxShadow={5}>
                <Modal
                    open={open || Object.keys(selectedProject).length === 0 && selectedProject.constructor === Object}
                    onClose={handleClose}
                    style={modalStyle}
                >
                    <Paper className={classes.paper}>
                        <Grid container direction='column'>
                            <Grid item>
                                <Box m={1} p={0} className='pb-3'>
                                    <span className='font-size-mmd' style={{fontWeight: 500}}>Select a project</span>
                                </Box>
                            </Grid>
                            {projectList.map((e, i) => (
                                <Grid item key={'grid item ' + i}>
                                    <Box key={'grid box ' + i} m={1} p={1} className={classes.projectBox} onClick={() => chooseProject(e)} style={selectedProject.id == e.id ? { backgroundColor: '#15B1D7', color: '#fff' } : {}}>
                                        <span key={'title span' + i} style={selectedProject.id == e.id ? { color: '#fff' } : { color: '#15B1D7'}} className='font-size-mmd font-weight-bold'>{e.name}</span><br />
                                        <div key={'description' + i} className='font-size-xs pt-1'>{e.description}</div>
                                    </Box>
                                </Grid>
                            ))}
                            {/* <Grid container item xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
                                
                            </Grid> */}
                        </Grid>
                        <div className='mt-4 text-center'>
                            <Button className='' variant='contained' color='primary' style={{height: 32, width: 120}}>SWITCH</Button>
                        </div>
                    </Paper>
                </Modal>
            </Box>
            <div onClick={() => handleOpen()} className='ml-auto' style={{ color: '#fff' }}>
                <SyncAlt />
            </div>
        </div>
    )
}

export default ProjectSwitch;
