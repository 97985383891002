import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    Stepper,
    Step,
    StepButton,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Avatar from '@material-ui/core/Avatar';
import { Field } from 'redux-form'
import {ReduxCheckBoxField} from "../../../components/form-components/form-field/ReduxCheckBoxField";
import {ReduxRequired} from "../../../components/form-components/validation";
import {ReduxTextField} from "../../../components/form-components/form-field/ReduxTextField";
import {RadioSiteStatus} from "./Step1RadioSiteStatus";
const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function Step1(props) {
    const { handleSubmit, pristine, reset, submitting } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState(new Set());
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = ['Project Status','Vaccancies','Preview'];

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    function isStepComplete(step) {
        return completed.has(step);
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"contract_no"}
                    label={"Reference no. in Buildings Department / Contract no."}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={8}>
                <ReduxTextField
                    name={"contract_title"}
                    label={"Contract title / Contract description"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"contractor_name"}
                    label={"Name of contractor"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"contractor_tel"}
                    label={"Tel."}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxCheckBoxField name="nominated" label="Nominated sub-contractor (Tick if applicable)"/>
            </Grid>
            <Grid item xs={12}>
                <ReduxTextField
                    name={"address"}
                    label={"Site / Contract address"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"authorized_person_1"}
                    label={"Name of authorized person / Company of site representative"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"authorized_tel_1"}
                    label={"Tel."}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"authorized_person_2"}
                    label={"Name of authorized person / Company of site representative"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"authorized_tel_2"}
                    label={"Tel."}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={12}>
                <ReduxTextField
                    name={"work_code"}
                    label={"Work code for government site only① (If applicable) "}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"work_stage"}
                    label={"Broad stage of work"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"completion"}
                    label={"% of completion"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>

            <Grid item xs={12}>
                <FormLabel component="legend">Current status of the site (Please select one of the following and tick as appropriate box)</FormLabel>
                <RadioSiteStatus/>
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"report_month_year"}
                    label={"Reporting month/year"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
                <ReduxTextField
                    name={"last_day"}
                    label={"Last day of reporting month"}
                    validate={[ReduxRequired]}
                />
            </Grid>
            <Grid item xs={4}>
            </Grid>
            <Grid container item xs={12}>
            <Typography style={{color: 'rgba(0,0,0,0.34)'}}>
                ① Please refer to work code in the GF 527 for the same site / contract.<br/>
                ② Please exclude Saturdays, Sundays, public holidays and days of inclement weather, and advance the reporting day accordingly (i.e. in the order of 31st, 30th, 29th …).
            </Typography>
            </Grid>
        </Grid>
    );
}
export default Step1;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
