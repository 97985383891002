  import axios from "axios";
  import {BackEndRequest, defaultHeaders} from "./APICalls";
  import {APP_URL} from "../static";
  import {useEffect} from "react";

  export const StaffURL = '/site/staff/'

  export async function GetStaffList(params){
    const resp = await BackEndRequest('get',StaffURL+params, defaultHeaders, {})
    return resp
  }

  export async function UploadReportDocument(report_id, data){
    const resp = await BackEndRequest('post','/site/document_upload/report/'+report_id, defaultHeaders, data)
    return resp
  }
