import {ReduxNormalSelectField} from "../../../../../components/form-components/form-field/ReduxNormalSelectField";
import {ReduxRequired} from "../../../../../components/form-components/validation";
import {Grid, MenuItem} from "@material-ui/core";
import React, {useEffect,useState} from "react";
import {ReduxAutoCompleteFieldByValue} from "../../../../../components/form-components/form-field/ReduxAutoCompleteFieldByValue";
import {getSubLocationList, getSubTradeList} from "../../../../../actions/Presets";
import {useSelector} from "react-redux";
export function TradeSelectField(props) {
    const {labour, index, labourTrades, disabled, onChange} = props;
    return(
        <ReduxAutoCompleteFieldByValue
            getOptionLabel={(option) => (option.code + ': ' + option.name)}
            disabled={disabled}
            multiple={false}
            name={`${labour}.trade`}
            label={"Work Category*"}
            validate={[ReduxRequired]}
            dataSet={labourTrades}
            dataFieldName={'id'}
        >

        </ReduxAutoCompleteFieldByValue>
    )
}

function exclude_soft_deleted(json){
  return json.soft_deleted == false
}

export function SubTradeSelectField(props) {
    const {labour, index, disabled, onChange, init} = props;
    const [list, setList] = useState([])
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const formData = useSelector((state) => state.form.report.values);
    const division = formData.details[0].division || ''
    function getTradeList(){
        if (division){
            getSubTradeList(selectedProject.id, '&division='+division).then((resp)=>
                setList(resp.data.results.filter(exclude_soft_deleted))
            )
        }
    }

    useEffect(() => {
    if(init){
        getTradeList()
      }
    },[init])


    return(
        <ReduxAutoCompleteFieldByValue
            disabled={disabled}
            name={`${labour}.sub_trade`}
            label={"Work Category"}
            validate={[ReduxRequired]}
            onChange={onChange}
            onOpen={()=>getTradeList()}
        >
            {list.map((e, i) => (
                <MenuItem key={index + 'labourSubTrades' + i} value={e.id}>{e.name + ':' + e.code}</MenuItem>
            ))}
        </ReduxAutoCompleteFieldByValue>
    )
}

export default TradeSelectField;