import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  TextField,
  Paper,
  Select,
  MenuItem,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box, InputLabel, Typography, Card, FormControl
} from '@material-ui/core';
import ActivityViewTable from "./ActivityViewTable";
import TwoBoxesDateRangePicker from 'components/date-components/TwoBoxesDateRangePicker';
import { dateToString } from 'Page/UI_Design/js_utils/general';
import { useSelector, useDispatch } from 'react-redux';
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components"
import {APP_URL} from 'static.js';
import {GetActivityList} from "../../../../actions/Activity";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

function Index() {
  const dispatch = useDispatch();
  const to = useSelector((state) => state.project.selectDateTo);
  const from = useSelector((state) => state.project.selectDateFrom);
  function setTo(day) {
      dispatch({ 'type': 'NAVDATETO', 'payload': day });
  }
  function setFrom(day) {
      dispatch({ 'type': 'NAVDATEFROM', 'payload': day });
  }
  const [activityList, setList] = useState([]);
  // const classes = useStyles();
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const [isLoading, setLoad] = useState(true)
  const statusOptions = [{name:'Approved', value:'approved'},{name:'Rejected', value:'rejected'}, {name:'Pending', value:'pending'}]
  const [option, setOption] = useState(statusOptions[0].value)

  useEffect(() => {
    setLoad(true);
    let params = '?';
    params+='date__gte=' + dateToString(from || new Date(),'-');
    params+='&date__lte=' + dateToString(to || new Date(),'-');
    params+='&project=' + selectedProject.id;
    params+='&review_status='+option;
    params+='&page_size=500';
    GetActivityList(params).then((resp)=>{
      setList(resp.data.results);
      setLoad(false);
    })
  }, [to, from, selectedProject, option]);

  return (
      <Grid container spacing={3} justify={'space-between'}>
          <Grid item lg={3}>
              <span className='font-size-lg font-weight-bold'>Activity</span>
          </Grid>
            <Grid item lg={9} spacing={3} container direction='row'>
              <Grid item lg={4} className='pl-3'>
                <FormControl className='w-100' variant="outlined">
                      <InputLabel id="status_label">Status</InputLabel>
                      <Select
                          className='text-left'
                          labelId="optionLabel"
                          label='Option'
                          id="option"
                          value={option}
                      IconComponent={KeyboardArrowDown}
                      >
                          {statusOptions.map((item, key) => (
                              <MenuItem
                                  key={'option' + key}
                                  onClick={() => {
                                      setOption(item.value)
                                  }}
                                  value={item.value}
                              >
                                  {item.name}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item lg={8}>
                <TwoBoxesDateRangePicker to={to} from={from} handleFromChange={setFrom} handleToChange={setTo} />
              </Grid>
          </Grid>
          <Grid item sm={12} >
              <Card><ActivityViewTable dataSet={activityList} isLoading={isLoading} /></Card>
          </Grid>
      </Grid>

    );
}

export default Index;

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
