import {Checkbox} from "@material-ui/core";
import React from "react";
import {Field} from "redux-form";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const renderCheckbox = ({
  input: { value, onChange, ...input },
  meta: { touched, error },
  ...rest
}) => (
  <div>
      <FormControlLabel
        control={
           <Checkbox
              {...input}
              {...rest}
              defaultChecked={!!value}
              onChange={(e, data) => onChange(data.checked)}
              type="checkbox"
              color='primary'
            />
        }
        label={rest.label}
      />
    {touched && error && <span>{error}</span>}
  </div>
);
export function ReduxCheckBoxField(props){
    return(
        <React.Fragment><Field {...props} component={renderCheckbox}></Field></React.Fragment>
    )
}