import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    Box, InputLabel, Tabs, Tab, Card, CardContent, FormControl
} from '@material-ui/core';
import { ContentBox } from 'components/layout-components/Content';
import { useParams } from "react-router";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { TabContext } from "@material-ui/lab";
import PropTypes from 'prop-types';
import ReportViewPage1 from 'Page/UI_Design/DailyReport/ReportViewPage1';
import ReportViewPage2 from 'Page/UI_Design/DailyReport/ReportViewPage2';
import ReportViewPage3 from 'Page/UI_Design/DailyReport/ReportViewPage3';
import ReportViewPage4 from 'Page/UI_Design/DailyReport/ReportViewPage4';

import TabPanel from 'components/other-components/TabPanel';

import { makeStyles } from "@material-ui/core/styles";
import { APP_URL } from 'static';
import EditIcon from '@material-ui/icons/Edit';
import {LoadReport} from "../../../actions/Report";
import moment from 'moment'
import {downloadReport} from "./Reports_NGA/ReportDownload";
import {GeneralButton} from "../../../components/other-components/Icons";
import {downloadFile} from "../js_utils/general";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import {GetSigneeList, RequestSignature} from "../../../actions/Report";

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
    dataFields: { color: 'black', fontSize: '0.9rem' },
    labelFields: { color: 'gray', fontSize: '0.8rem' },
}))
const activeTabsColor = '#15B1D7'

function ReportView() {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { id } = useParams();
    const selectedReport = useSelector((state) => state.report.selectedReport);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [supervisorList, setSupervisorList] = useState([])
    const [contractorList, setContractorList] = useState([])
    const [seniorList, setSeniorList] = useState([])
    const [supervisor, setSupervisor] = useState('')
    const [contractor, setContractor] = useState('')
    const [senior, setSenior] = useState('')
    const [page, setPage] = useState(0)

    // const [selectedReport, setReport] = useState({});
    useEffect(() => {
        if(selectedProject.id){
            GetSigneeList('?project='+selectedProject.id+'&signature=supervisor').then((resp)=>{
                setSupervisorList(resp.data);
                if(resp.data.length==1){
                    setSupervisor(resp.data[0].user_id)
                }
            })
            GetSigneeList('?project='+selectedProject.id+'&signature=contractor').then((resp)=>{
                setContractorList(resp.data);
                if(resp.data.length==1){
                    setContractor(resp.data[0].user_id)
                }
            })
            GetSigneeList('?project='+selectedProject.id+'&signature=senior').then((resp)=>{
                setSeniorList(resp.data);
                if(resp.data.length==1){
                    setSenior(resp.data[0].user_id)
                }
            })
        }
    },[selectedProject])

    useEffect(() => {
        LoadReport(dispatch, id)
    },[page])
    //
//'file.xlsx'
    function downloadExcel(){
        downloadReport(selectedReport, 'excel').then((resp)=> {
                downloadFile(resp.data, "application/vnd.ms-excel", 'file.xlsx')
            }
        )
    }

    function requestSignature(){
        RequestSignature(selectedReport.id+'?project='+selectedProject.id, {"contractor":contractor, "senior":senior, 'supervisor':supervisor}).then((resp)=>{
            console.log(resp)
        })
    }

    function Section1() {
        return (
            <React.Fragment>
                <Grid container direction='row' spacing={1}>
                    <Grid item xs={3}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Date</InputLabel>
                        <Typography className={classes.dataFields}>{selectedReport.date}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Typhoon / Rainstorm Warning Signal</InputLabel>
                        <Typography className={classes.dataFields}>{selectedReport.warning_signal}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Weather (A.M.)</InputLabel>
                        <Typography className={classes.dataFields}>{selectedReport.weather_am}</Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction='row' spacing={1}>
                    <Grid item xs={3}>
                        <span></span>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Rainfall</InputLabel>
                        <Typography className={classes.dataFields}>{selectedReport.rainfall}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">Weather (P.M.)</InputLabel>
                        <Typography className={classes.dataFields}>{selectedReport.weather_pm}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    function Section2() {
        return (
            <React.Fragment>
                <Grid container direction='row' spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">{selectedReport.status=='completed'?<span>Submitted By</span>:<span>Updated By</span>}</InputLabel>
                        <Typography className={classes.dataFields}>&nbsp;{selectedReport.updated_by?.full_name}</Typography>
                    </Grid>
                </Grid>
                <br />
                <Grid container direction='row' spacing={1}>
                    <Grid item xs={12}>
                        <InputLabel className={classes.labelFields} htmlFor="color-native-simple">&nbsp;{selectedReport.updated_by?.title}</InputLabel>
                        <Typography className={classes.dataFields}>{moment(selectedReport.updated_time).format('YYYY-MM-DD h:mm a')}</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    function changeTab(event, newValue) {
        setPage(newValue);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <span className='font-size-lg font-weight-bold'>View Report</span>
            </Grid>

            <Grid item xs={8}>
                <div className='d-flex justify-content-end'>
                    <div className='d-flex ml-2' style={{minWidth:'180px'}}>
                        <FormControl className='w-100' variant="outlined">
                            <InputLabel id="Supervisor_Signee">Supervisor Signee</InputLabel>
                            <Select
                                className='text-left'
                                labelId="SupervisorsigneeLabel"
                                label='Supervisor_Signee'
                                id="Supervisor_signee"
                                value={supervisor}
                                onChange={(e)=>setSupervisor(e.target.value)}
                            IconComponent={KeyboardArrowDown}
                            >
                                {supervisorList.map((item, key) => (
                                    <MenuItem
                                        key={'supervisor' + key}
                                        value={item.user_id}
                                    >
                                        {item.full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-flex ml-2' style={{minWidth:'180px'}}>
                        <FormControl className='w-100' variant="outlined">
                            <InputLabel id="Contractor_Signee">Contractor Signee</InputLabel>
                            <Select
                                className='text-left'
                                labelId="Contractorsignee2Label"
                                label='Contractor_Signee'
                                id="Contractor_Signee"
                                value={contractor}
                                onChange={(e)=>setContractor(e.target.value)}
                            IconComponent={KeyboardArrowDown}
                            >
                                {contractorList.map((item, key) => (
                                    <MenuItem
                                        key={'contractor' + key}
                                        value={item.user_id}
                                    >
                                        {item.full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-flex ml-2' style={{minWidth:'180px'}}>
                        <FormControl className='w-100' variant="outlined">
                            <InputLabel id="Senior_Signee">Senior Signee</InputLabel>
                            <Select
                                className='text-left'
                                labelId="Seniorsignee2Label"
                                label='Senior_Signee'
                                id="Senior_Signee"
                                value={senior}
                                onChange={(e)=>setSenior(e.target.value)}
                            IconComponent={KeyboardArrowDown}
                            >
                                {seniorList.map((item, key) => (
                                    <MenuItem
                                        key={'Senior' + key}
                                        value={item.user_id}
                                    >
                                        {item.full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='d-flex ml-2'>
                        <GeneralButton onClick={() => requestSignature()} label='SUBMIT FOR SIGNATURE'></GeneralButton>
                    </div>
                    <div className='d-flex ml-2'>
                        <GeneralButton onClick={() => downloadExcel()} label='DOWNLOAD REPORT'></GeneralButton>
                    </div>
                </div>
            </Grid>

            <Grid item xs={8}>
                <Card className='h-100'><CardContent><Section1 /></CardContent></Card>
            </Grid>
            <Grid item xs={4}>
                <Card className='h-100'><CardContent><Section2 /></CardContent></Card>
            </Grid>
            <Grid item xs={12}>
                <TabContext>
                    <div className='d-flex flex-row align-items-center'>
                        <Tabs
                            value={page}
                            onChange={changeTab}
                            indicatorColor='primary'
                            textColor='primary'
                            TabIndicatorProps={{ style: { background: activeTabsColor } }}
                            style={{width: '50%'}}
                        >
                            <Tab label="Page 1" style={{ minWidth: '100px' }} />
                            <Tab label="Page 2" style={{ minWidth: '100px' }} />
                            <Tab label="Page 3" style={{ minWidth: '100px' }} />
                            <Tab label="Preview PDF" style={{ minWidth: '100px' }} />
                        </Tabs>
                        {page == 1 ?
                            <div style={{width: '50%', textAlign: 'right'}}>
                                {selectedReport.status != 'completed' ?
                                <span style={{ fontWeight: 500, color: activeTabsColor, cursor: 'pointer' }} onClick={() => navigate('/app/report_form/' + id)}>
                                    <EditIcon /> EDIT REPORT
                                </span>
                                :null}
                            </div>
                        : null }
                    </div>
                    <br />
                    <TabPanel value={page} index={0}>
                        <ReportViewPage1 />
                    </TabPanel>
                    <TabPanel value={page} index={1}>
                        <ReportViewPage2 />
                    </TabPanel>
                    <TabPanel value={page} index={2}>
                        <ReportViewPage3 />
                    </TabPanel>
                    <TabPanel value={page} index={3}>
                        <ReportViewPage4 />
                    </TabPanel>
                </TabContext>
            </Grid>
        </ Grid>
    );
}
export default ReportView;