import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayPicker from 'react-day-picker';
import { getCaptionProps } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";
import { Select, MenuItem, Typography, Box, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    nav_day_picker: {
        display: "flex",
        fontSize: "11px",
        // fontFamily: theme.styles.font.fontFamily,
        color: '#fff',
        lineHeight: '1.2',
        height: '275px',
        paddingLeft: theme.spacing(1),
        '& .DayPicker-NavButton--prev': {
            width: '1.3rem',
            height: '1.3rem',
            transform: 'translate(-2rem, -1rem)',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi4yMyIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDExLjExNSAxOCI+CiAgPHBhdGggaWQ9Ikljb25fbWF0ZXJpYWwta2V5Ym9hcmQtYXJyb3ctbGVmdCIgZGF0YS1uYW1lPSJJY29uIG1hdGVyaWFsLWtleWJvYXJkLWFycm93LWxlZnQiIGQ9Ik0yMy4xMTUsMjQuMTM1bC02Ljg3LTYuODg1LDYuODctNi44ODVMMjEsOC4yNWwtOSw5LDksOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMiAtOC4yNSkiIGZpbGw9IiNmZmYiLz4KPC9zdmc+) !important'
        },
        '& .DayPicker-NavButton--next': {
            width: '1.3rem',
            height: '1.3rem',
            transform: 'translate(0, -1rem)',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi4yMyIgaGVpZ2h0PSIzNiIgdmlld0JveD0iMCAwIDExLjExNSAxOCI+CiAgPHBhdGggaWQ9Ikljb25fbWF0ZXJpYWwta2V5Ym9hcmQtYXJyb3ctcmlnaHQiIGRhdGEtbmFtZT0iSWNvbiBtYXRlcmlhbC1rZXlib2FyZC1hcnJvdy1yaWdodCIgZD0iTTEyLjg4NSwyNC41MWw2Ljg3LTYuODg1LTYuODctNi44ODVMMTUsOC42MjVsOSw5LTksOVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMi44ODUgLTguNjI1KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K) !important'
        }
    },
icon: {
    fill: 'white',
        fontSize: '1.3em',
            transform: 'translate(-0.3em,0.2em)',
    },
upDownIcon: {
    position: 'absolute',
        left: '0px',
            fontSize: '1rem',
                "&:hover": {
        cursor: 'pointer'
    }
}
}))

const modifiersStyles = {
    today: {
        color: '#fff',
        padding: '0px'
    },
};

export default function DateNav() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectDateFrom = useSelector((state) => state.project.selectDateFrom)
    const selectDateTo = useSelector((state) => state.project.selectDateTo)
    const [changemonthvar, handleYearMonthChange] = React.useState()
    const navigate = useNavigate();

    function YearMonthForm({ date, localeUtils, onChange }) {
        const months = localeUtils.getMonths();
        const sdate = new Date();
        const years = [];
        for (let i = sdate.getFullYear(); i <= sdate.getFullYear(); i += 1) {
            years.push(i);
        }

        const handleChange = function handleChange(e) {
            // const { year, month } = e.target.form;
            // onChange(new Date(year.value, month.value));
        };

        return (
            <form className="DayPicker-Caption" style={{ fontSize: '1.15em', paddingBottom: '0.5rem', marginTop: '-0.5rem' }}>
                <Select
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                        },
                    }}
                    style={{ color: 'white', fontSize: '1em', fontWeight: '400' }}
                    size={'small'}
                    disableUnderline
                    name="month"
                    onChange={handleChange}
                    value={date.getMonth()}
                >
                    {months.map((month, i) => (
                        <MenuItem key={month} value={i}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
                <span>{date.getFullYear()}</span>
                {/*<span style={{position:'relative', left:0, top:0}}>*/}
                {/*        <ArrowDropUpIcon className={classes.upDownIcon} onClick={()=>incrementYear(1)}style={{top:'-0.25rem'}}/>*/}
                {/*        <ArrowDropDownIcon className={classes.upDownIcon} style={{position:'absolute', left:'0px', top:'0.25rem', fontSize:'1rem'}}/>*/}
                {/*</span>*/}
            </form>
        );
    }


    function handleDayClick(day) {
        dispatch({ 'type': 'NAVDATETO', 'payload': day });
        dispatch({ 'type': 'NAVDATEFROM', 'payload': day });
        navigate("/app/graph");
    }
    return (
        // <div className="NavDate">
        <>
            <DayPicker
                captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                        date={date}
                        localeUtils={localeUtils}
                        onChange={handleYearMonthChange}
                    />
                )}
                className={classes.nav_day_picker}
                selectedDays={selectDateTo}
                modifiersStyles={modifiersStyles}
                onDayClick={handleDayClick}
            />
            <Helmet>
                <style>{`
                    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
                        background-color: #15B1D7;
                        color:#fff;
                    }
                    .DayPicker-Caption{
                        padding-bottom:1rem;
                    }
                    .DayPicker-Day{
                        padding:0.73rem;
                    }
                    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
                        background-color: #15B1D7;
                    }
                    .DayPicker-Day--selected:hover{
                        background-color: #15B1D7;
                    }
                    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover{
                        background-color: #15B1D7;
                    }
                    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                        background-color: #15B1D7;
                    }
                `}</style>
            </Helmet>
        </>
        // </div>
    )
}
