import React, {useEffect, useState} from "react";

import {
  InputLabel,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  Typography
} from '@material-ui/core';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {dateToString} from 'Page/UI_Design/js_utils/general';
import {makeStyles} from "@material-ui/core/styles";
import {APP_URL} from 'static.js';

import {bar_options} from 'Page/UI_Design/Dashboard/Highcharts/bar_configs';
import {column_options} from 'Page/UI_Design/Dashboard/Highcharts/column_configs';
import {pie_options} from 'Page/UI_Design/Dashboard/Highcharts/pie_configs';
import {process_bar_data, process_line_data, process_pie_data} from "./Highcharts/get_chart_data";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {HighchartsComponent} from 'components/other-components/HighchartsComponent';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";

const BootstrapInput = withStyles((theme) => ({
    root: {
        paddingRight:'10px'
    },
}))

// const StyledSelect = styled(Select)`
//
//   & .select {
//     paddingRight:'10px'
//   }
// `;


function NewSelect(){
    const borderSelectClasses = useBorderSelectStyles();
    const iconComponent = (props) => {
        return (
        <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon}/>
        )
    };
    return (
        // <ThemeProvider theme={BootstrapInput}>
          <Select
              // classes={{ root: borderSelectClasses.select, }}
              IconComponent={iconComponent}
              style={{textAlign:'left', minWidth:'100px', marginLeft:'20px'}}
              labelId={'SelectOpt'}
              disableUnderline
          >
              <MenuItem
                  key={'labour_opt'}
                  value='Labour'>Labour
              </MenuItem>
              <MenuItem
                  key={'plant_opt'}
                  value='Plant'>Plant
              </MenuItem>
          </Select>
        // </ThemeProvider>
  );
}

export default NewSelect;