import React, { useEffect, useState } from "react";
import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom'
import ActivityDetailsPopUp from 'Page/UI_Design/Activity/ActivityList/ActivityDetailsPopUp'
import tableIcons from 'components/other-components/MaterialTable/TableIcons';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Card, TablePagination, Tooltip, Typography} from '@material-ui/core';
import ActivityImageShowcase from 'Page/UI_Design/Activity/ActivityList/ActivityImageShowcase';
import { TableLoader } from 'components/other-components/TableLoader';
import { WrapperBox } from "../../../../components/layout-components/Content";
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {GetActivityList, ReviewActivity, CheckOverdueReviewActivity} from "../../../../actions/Activity";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {GeneralButton} from "../../../../components/other-components/Icons";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {Collapse, IconButton} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import DateInputPicker from "../../../../components/date-components/DatePicker";
import {dateToString} from "../../js_utils/general";
function Index() {
  const navigate = useNavigate();
  const [activityList, setList] = useState([]);
  const [overdue, setOverdue] = useState('');
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const [isLoading, setLoad] = useState(true);
  const [rejectList, setRejectList] = useState([]);
  const [approveList, setApproveList] = useState([]);
  const [alert, setAlert] = useState(false);
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    setLoad(true);
    let params = '?';
    params+='&review_status=pending';
    params+='&project=' + selectedProject.id;
    params+='&page_size=100';
    params+='&date=' + dateToString(date, '-');
    GetActivityList(params).then((resp)=>{
      setList(resp.data.results);
      setLoad(false);
    })
  }, [selectedProject, date]);

  useEffect(() => {
    CheckOverdueReviewActivity('?project='+selectedProject.id).then((resp)=>{
      if (resp.data.length > 10){
        setOverdue('Overdue records pending approval on dates ' + resp.data[0].date + ' to ' + resp.data[resp.data.length -1].date)
        setAlert(true)
      }
      else if (resp.data.length > 0){
        setOverdue('Overdue records pending approval on dates ' + resp.data[0].date + ' to ' + resp.data[resp.data.length -1].date)
        // setOverdue('show some overdue dates, implementing')
        setAlert(true)
      }
      else{
        setOverdue('No Activities pending approval')
        setAlert(true)
      }
    })
  }, [selectedProject]);

  // function select(e, rowData){
  //   rowData.selected=e.target.checked
  // }

  function draftReview(e, rowData, approve){
    setLoad(true);
    let editingList = [...activityList]
    editingList.splice(rowData.tableData.id, 1);
    // editingList.pop(rowData.tableData.id)
    setList(editingList)
    if (approve){
      let addToList = [...approveList]
      addToList.push(rowData)
      setApproveList(addToList)
    }else{
      let addToList = [...rejectList]
      addToList.push(rowData)
      setRejectList(addToList)
    }
    setLoad(false);
  }

  function review(review_status, list, setList){
    const ids = list.map((x)=>x.id)
    ReviewActivity('?project='+selectedProject.id, {'ids':ids, review_status:review_status}).then((resp)=>{
      setList([])
    })
  }


  return (
      <Grid container spacing={3}>
        <Grid container item justify={'space-between'}>
          <Grid item lg={6}>
              <span className='font-size-lg font-weight-bold'>Approve</span>
          </Grid>
          <Grid item lg={3}>
            <DateInputPicker label={'Date'} value={date} onChange={setDate}/>
          </Grid>
        </Grid>
        {alert?
          <Grid item xs={12}>
            <Collapse in={alert}>
                <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                    severity="warning">
                  {overdue}
                </Alert>
            </Collapse>
          </Grid>
        :null}
        <Grid item xs={12}>
          <Card>
            <Accordion>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
              >
                <Typography color='primary' style={{ fontWeight: 500 }}>
                  <GeneralButton style={{backgroundColor:'#3b3e66'}} label={'Confirm'} onClick={()=>review('approved',approveList, setApproveList)}/>
                  <span style={{color:'#3b3e66'}}> Approve List ({approveList.length})</span>
                </Typography>
              </AccordionSummary>
              <EditingTable dataList={approveList}/>
            </Accordion>
          </Card>
        </Grid><Grid item xs={12}>
        <Card>
          <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography color='primary' style={{ fontWeight: 500 }}>
                <GeneralButton style={{backgroundColor:'maroon'}} label={'Confirm'} onClick={()=>review('rejected',rejectList, setRejectList)}/>
                <span style={{color:'maroon'}}> Reject List ({rejectList.length})</span>
              </Typography>

            </AccordionSummary>
            <EditingTable dataList={rejectList}/>
          </Accordion>
        </Card>
      </Grid>
        <Grid item xs={12}>
          <Card>
            <div style={{ position: 'relative' }}>
              {isLoading ?
                  <TableLoader />
                  : null}
              <MaterialTable
                  icons={tableIcons}
                  localization={{ body:{ emptyDataSourceMessage:<span>{isLoading?null:'No records to display'}</span>}}}
                  title={<Typography color='primary' style={{ fontWeight: 500 }}>List of Activities</Typography>}
                  columns={
                    [
                      {
                        title: 'Approve/Reject',
                        render: rowData => (
                            <span><DoneIcon style={{color:'#3b3e66'}} onClick={(e)=>draftReview(e, rowData, true)}/>/<CloseIcon style={{color:'maroon'}} onClick={(e)=>draftReview(e, rowData, false)}/></span>
                        ),
                      },
                      // {
                      //      title: 'Select',
                      //      render: rowData => (
                      //          <input type="checkbox" defaultChecked={rowData.selected} onClick={(e)=>select(e,rowData)}/>
                      //      ),
                      //  },
                      {
                        title: 'Location',
                        field: 'location.name',
                        render: rowData => (
                            <span>{rowData.location.name}</span>
                        ),
                      },
                      {
                        title: 'Sub Location',
                        field: 'sub_location.name',
                        render: rowData => (
                            <span>{rowData.sub_location ? rowData.sub_location.name : ''}</span>
                        ),
                      },
                      { title: 'Date', field: 'date' },
                      {
                        title: 'Time',
                        // field: 'details.activity_category.name',
                        customFilterAndSearch: (term, rowData) => {
                          let match = false;
                          rowData.details.map((e, i) => {
                            if (e.activity_category) {
                              match = e.time_start?.indexOf(term) > -1 || e.time_end?.indexOf(term) > -1;
                            } else {
                              return;
                            }
                          });
                          return match;
                        },
                        render: rowData => (
                            <Grid container>
                              <Grid item xs={10}>
                                {rowData.details.map((e, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', padding: 4 }}>
                                      <div>
                                        <span>{e.time_start + ' - ' + e.time_end}</span>
                                      </div>
                                    </div>
                                ))}
                              </Grid>

                            </Grid>
                        ),
                      },
                      {
                        title: 'Main Activities',
                        // field: 'details.activity_category.name',
                        customFilterAndSearch: (term, rowData) => {
                          let match = false;
                          rowData.details.map((e, i) => {
                            if (e.activity_category) {
                              match = e.activity_category.name.indexOf(term) > -1;
                            } else {
                              return;
                            }
                          });
                          return match;
                        },
                        render: rowData => (
                            <Grid container>
                              <Grid item xs={10}>
                                {rowData.details.map((e, i) => (
                                  <Tooltip
                                      title={
                                          <div>
                                              <div>
                                                  {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                              </div>
                                          </div>
                                      }
                                  >
                                      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 300, padding:4}}>
                                          <span>
                                              {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                          </span>
                                      </div>
                                  </Tooltip>
                                ))}
                              </Grid>
                              <Grid item xs={2} className='d-flex flex-row align-items-center text-center'>
                                <div className='m-auto'>
                                  {rowData.details.length ?
                                      <ActivityImageShowcase
                                          details={rowData.details}
                                          location={rowData.location.name}
                                          subLocation={rowData.sub_location ? rowData.sub_location.name : ''}
                                          startTime={rowData.time_start} endTime={rowData.time_end} date={rowData.date}
                                          labours={rowData.labours.reduce((a, b) => a + b.no, 0)}
                                          plants={rowData.plants.reduce((a, b) => a + b.working_no, 0)}
                                          materials={rowData.materials.length}
                                      />
                                      : null}
                                </div>
                              </Grid>
                            </Grid>
                        ),
                        minWidth: 500
                      },
                      // { title: 'Time Start', field: 'time_start' },
                      // { title: 'Time End', field: 'time_end' },
                      {
                        title: 'Labours',
                        // field: 'labours',
                        render: rowData => (
                            <ActivityDetailsPopUp rowData={rowData} selectType={0} />
                        ),
                      },
                      {
                        title: 'Plants',
                        // field: 'plants',
                        render: rowData => (
                            <ActivityDetailsPopUp rowData={rowData} selectType={1} />
                        ),
                      },
                      {
                        title: 'Materials',
                        // field: 'materials',
                        render: rowData => (
                            <ActivityDetailsPopUp rowData={rowData} selectType={2} />
                        ),
                      },
                      {
                        title: 'Edit',
                        // field: 'report.updated_by.email',
                        customFilterAndSearch: (term, rowData) => {
                          let match = false;
                          if (rowData.report.updated_by) {
                            match = rowData.report.updated_by.email.indexOf(term) > -1;
                          } else {
                            match = 'Anonymous'.indexOf(term) > -1;
                          }
                          return match;
                        },
                        render: rowData => (
                            rowData.report.status == 'completed' ?
                                <span> completed by {rowData.report.updated_by ? rowData.report.updated_by.full_name : 'Anonymous'}</span>
                                :
                                <div className='d-flex flex-row align-items-center'>
                                  <span>drafted by {rowData.updated_by ? rowData.updated_by.full_name : 'Anonymous'}</span>
                                </div>
                        ),
                      },
                    ]}
                  components={{
                    Container: props => <WrapperBox>{props.children}</WrapperBox>,

                  }}
                  options={{
                    detailPanelColumnAlignment: 'right',
                    pageSize: 10,
                    pageSizeOptions: [10],
                    rowStyle: {
                      // fontSize: InlineStyles.materialTables.row.fontSize,
                      whiteSpace: 'nowrap'
                    },
                    search:false,
                    headerStyle: {
                      whiteSpace: 'nowrap'
                    },

                  }}
                  data={activityList}
                  actions={[
                    {
                      icon: props => <GeneralButton style={{backgroundColor:'#3b3e66'}} label={'Approve All'} fullWidth={true}></GeneralButton>,
                      tooltip: 'New Activity',
                      isFreeAction: true,
                      onClick: (event) => review('approved',activityList, setList)
                    },
                    {
                      icon: props => <GeneralButton style={{backgroundColor:'maroon'}} label={'Reject All'} fullWidth={true}></GeneralButton>,
                      tooltip: 'New Activity',
                      isFreeAction: true,
                      onClick: (event) => review('rejected',activityList, setList)
                    },
                  ]}
              />
            </div >
          </Card>
        </Grid>
      </Grid>
  );
}

function EditingTable(props){
  const {dataList} = props
  return (
      <MaterialTable
          icons={tableIcons}
          columns={
            [
              {
                title: 'Location',
                field: 'location.name',
                render: rowData => (
                    <span>{rowData.location.name}</span>
                ),
              },
              {
                title: 'Sub Location',
                field: 'sub_location.name',
                render: rowData => (
                    <span>{rowData.sub_location ? rowData.sub_location.name : ''}</span>
                ),
              },
              { title: 'Date', field: 'date' },
              {
                title: 'Time',
                // field: 'details.activity_category.name',
                customFilterAndSearch: (term, rowData) => {
                  let match = false;
                  rowData.details.map((e, i) => {
                    if (e.activity_category) {
                      match = e.time_start?.indexOf(term) > -1 || e.time_end?.indexOf(term) > -1;
                    } else {
                      return;
                    }
                  });
                  return match;
                },
                render: rowData => (
                    <Grid container>
                      <Grid item xs={10}>
                        {rowData.details.map((e, i) => (
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', padding: 4 }}>
                              <div>
                                <span>{e.time_start + ' - ' + e.time_end}</span>
                              </div>
                            </div>
                        ))}
                      </Grid>

                    </Grid>
                ),
              },
              {
                title: 'Main Activities',
                // field: 'details.activity_category.name',
                customFilterAndSearch: (term, rowData) => {
                  let match = false;
                  rowData.details.map((e, i) => {
                    if (e.activity_category) {
                      match = e.activity_category.name.indexOf(term) > -1;
                    } else {
                      return;
                    }
                  });
                  return match;
                },
                render: rowData => (
                    <Grid container>
                      <Grid item xs={10}>
                        {rowData.details.map((e, i) => (
                          <Tooltip
                              title={
                                  <div>
                                      <div>
                                          {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                      </div>
                                  </div>
                              }
                          >
                              <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: 300, padding:4}}>
                                  <span>
                                      {i + 1}. {e.activity_category ? e.activity_category.name : ''} {e.activity_remarks}
                                  </span>
                              </div>
                          </Tooltip>
                        ))}
                      </Grid>
                      <Grid item xs={2} className='d-flex flex-row align-items-center text-center'>
                        <div className='m-auto'>
                          {rowData.details.length ?
                              <ActivityImageShowcase
                                  details={rowData.details}
                                  location={rowData.location.name}
                                  subLocation={rowData.sub_location ? rowData.sub_location.name : ''}
                                  startTime={rowData.time_start} endTime={rowData.time_end} date={rowData.date}
                                  labours={rowData.labours.reduce((a, b) => a + b.no, 0)}
                                  plants={rowData.plants.reduce((a, b) => a + b.working_no, 0)}
                                  materials={rowData.materials.length}
                              />
                              : null}
                        </div>
                      </Grid>
                    </Grid>
                ),
                minWidth: 500
              },
              // { title: 'Time Start', field: 'time_start' },
              // { title: 'Time End', field: 'time_end' },
              {
                title: 'Labours',
                // field: 'labours',
                render: rowData => (
                    <ActivityDetailsPopUp rowData={rowData} selectType={0} />
                ),
              },
              {
                title: 'Plants',
                // field: 'plants',
                render: rowData => (
                    <ActivityDetailsPopUp rowData={rowData} selectType={1} />
                ),
              },
              {
                title: 'Materials',
                // field: 'materials',
                render: rowData => (
                    <ActivityDetailsPopUp rowData={rowData} selectType={2} />
                ),
              },
              {
                title: 'Edit',
                // field: 'report.updated_by.email',
                customFilterAndSearch: (term, rowData) => {
                  let match = false;
                  if (rowData.report.updated_by) {
                    match = rowData.report.updated_by.email.indexOf(term) > -1;
                  } else {
                    match = 'Anonymous'.indexOf(term) > -1;
                  }
                  return match;
                },
                render: rowData => (
                    rowData.report.status == 'completed' ?
                        <span> completed by {rowData.report.updated_by ? rowData.report.updated_by.full_name : 'Anonymous'}</span>
                        :
                        <div className='d-flex flex-row align-items-center'>
                          <span>drafted by {rowData.updated_by ? rowData.updated_by.full_name : 'Anonymous'}</span>
                        </div>
                ),
              },
            ]}å
          components={{
            Container: props => <AccordionDetails style={{position: 'relative', display:'block', padding:'0'}}>{props.children}</AccordionDetails>,
            Toolbar: (props) => (<div
                    style={{
                      height: "0px",
                    }}
                ></div>
            ),
          }}
          options={{
            detailPanelColumnAlignment: 'right',
            pageSize: 5,
            rowStyle: {
              // fontSize: InlineStyles.materialTables.row.fontSize,
              whiteSpace: 'nowrap'
            },
            search:false,
            headerStyle: {
              whiteSpace: 'nowrap'
            },

          }}
          data={dataList}
      />
  )
}

export default Index;

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
