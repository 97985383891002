import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Typography,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    makeStyles
} from '@material-ui/core';
import {InlineStyles} from 'stylesCss';
import {Upload527APDF} from "../../../actions/MonthlyReport";
//import FileSaver from 'file-saver';
import {APP_URL} from 'static.js';

import {Page as VPage, pdfjs, Document as VDoc} from 'react-pdf';
import {GeneralButton} from "../../../components/other-components/Icons";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GF527APDF from 'Page/UI_Design/MonthlyReport/GF527APDF';
import PDF from "./GF527APDF";
import SignatureBox from "components/other-components/canvas";
import {BackEndRequestArrayBuffer, defaultHeaders} from "../../../actions/APICalls";
const useStyles = makeStyles(theme => ({
    // button: theme.styles.buttons
}))

function downloadFile(object, filename){
  const url = window.URL.createObjectURL(object);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
}

function Step3(props) {
    const classes = useStyles();
    const {id, handleBack} = props;
    const dispatch = useDispatch();
    const [PDFFile, setBlob] = useState(null);
    // const diary2inputs = useSelector((state) => state.excel.diary2);
    // const selectedReport = useSelector((state) => state.report.selectedReport);
    // const selectedProject = useSelector((state) => state.project.selectedProject);
    const formData = useSelector((state) => state.form.construction);
    const navigate = useNavigate();
    const [image, setImage] = useState(null);
    function createReport() {
        // let data = formData.values;
        let data = {}
        data['id'] = id
        const convertFileToBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                data['pdfFile'] = reader.result;
                Upload527APDF(data)
                .then((response) => {
                    navigate('/app/monthly_report')
                })
            };
            reader.onerror = reject;
        });
        convertFileToBase64(PDFFile)
    }

    function downloadExcel(){
        BackEndRequestArrayBuffer('post','/site/preview_excel/GF527A/', defaultHeaders, formData.values)
        .then((response) => {
            const newBlob = new Blob([response.data], {type: "application/excel"});
            downloadFile(newBlob, 'Form527A.xlsx')
        })
    }

    return (
        <React.Fragment>
        <Grid container spacing={0} style={{paddingTop:'20px'}} justify={'center'} alignItems={'center'}>
            <Grid container item xs={7} style={{marginLeft:'40px'}}>
                <Typography>Return on Construction Site Employment (GF527A) - OCT 2020&nbsp;&nbsp;</Typography>
                <svg onClick={downloadExcel} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill='#15B1D7' d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
            </Grid>
            <Grid container item xs={3} style={{textAlign:'right', marginRight:'40px'}}>
                <Typography>Download as PDF&nbsp;&nbsp;</Typography>
                <svg onClick={()=>downloadFile(PDFFile, 'GF527A.pdf')} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill='#15B1D7' d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>
            </Grid>
                <Grid container item xs={12} justify={'center'}>
                <PDF setBlob={setBlob} signature={image} formData={formData.values || {}}/>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <span>Signature</span>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <SignatureBox setImage={setImage} h={200} w={600}/>
            </Grid>
        </Grid>
        <Grid container direction='row' justify={'space-between'} style={{padding:'20px'}}>
            <Grid item xs={3}>
                <Button style={{color: '#15B1D7', textTransform:'none'}}
                    onClick={handleBack}
                >
                    <ArrowBackIosIcon /> <Typography >Previous Section</Typography>
                </Button>
            </Grid>
            <Grid item xs={3} style={{textAlign:'right'}}>
                <GeneralButton label={'Submit'} onClick={createReport} style={{width: '200px'}}/>
            </Grid>
        </Grid>
        </React.Fragment>
    )
}
export default Step3;

//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
