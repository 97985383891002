  import axios from "axios";
  import {BackEndRequest, defaultHeaders} from "./APICalls";
  import {APP_URL} from "../static";
  import {useEffect} from "react";

  export async function GetActivityList(params){
    const resp = await BackEndRequest('get','/site/activity/list/'+params, defaultHeaders, {})
    return resp
  }

  export async function CreateActivity(data){
    const resp = await BackEndRequest('post','/site/activity/create/', defaultHeaders, data)
    return resp
  }

  export async function GetActivity(id){
    const resp = await BackEndRequest('get','/site/activity/retrieve/'+id, defaultHeaders)
    return resp
  }

  export async function ReviewActivity(params, data){
    const resp = await BackEndRequest('post','/site/activity/review/'+params, defaultHeaders, data)
    return resp
  }

  export async function CheckOverdueReviewActivity(params, data){
    const resp = await BackEndRequest('post','/site/activity/overdue/'+params, defaultHeaders, data)
    return resp
  }
  //
  // export async function LoadReport(dispatch, path_name){
  //   const resp = await BackEndRequest('get',ReportURL, defaultHeaders, {})
  //   dispatch({'type': 'REPORT', 'payload': resp.data})
  // }
  //
  // export async function GetReport(id){
  //   const resp = await BackEndRequest('get',ReportURL+id+'/', defaultHeaders, {})
  //   return resp;
  // }
  //
  // // Update and Create use put
  // export async function UpdateReport(id, data){
  //   const resp_data = await BackEndRequest('put',ReportURL+id+'/', defaultHeaders, data)
  // }
  //
  // export async function GetWeatherData(){
  //   let url;
  //   url = 'https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en';
  //   const weather = await axios.get(url, {}).then((resp) =>
  //     {
  //       const data = resp.data
  //       const filtered_rainfall = data.rainfall.data.filter(function (el) {
  //         return el.place=='Kwun Tong';
  //       });
  //       const filtered_temperature = data.temperature.data.filter(function (el) {
  //         return el.place=='Kwun Tong';
  //       });
  //       return {'rainfall':filtered_rainfall[0].max, 'temperature':filtered_temperature[0].value, warning:resp.data.warningMessage};
  //     }
  //   );
  //   return weather
  // }