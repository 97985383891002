import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
    root: {},
    opacity: {
        opacity: '30%'
    },
    normal: {
        opacity: '100%'
    }
}));

function ImagePreview3(props) {
    const classes = useStyles()
    const { src, clear, update, index } = props;
    const [isHovered, setHover] = useState(false);
    const myRef = React.useRef();
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    return (
        <div
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            ref={myRef}
            style={{ border: '1px solid #eceef7', position: 'relative', width: '120px', float: 'left', 'marginRight': '0.5rem', marginBottom: '0.5rem' }}
        >
            <Image src={src} className={isHovered ? classes.opacity : classes.normal} style={{ width: '100%' }} />
            {isHovered && (
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '5px',
                        height: '20px',
                        width: '20px',
                        zIndex: '2000',
                        cursor: 'pointer',
                    }}
                    onClick={() => clear(index)}
                >
                    <HighlightOffIcon />
                </div>
            )}
        </div>
    );
}

export default ImagePreview3;