const initialState = {
  loggedIn:(JSON.parse(localStorage.getItem('LOGGED_IN'))||false),
  token:(JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH'))||{}),
  // full_name:(JSON.parse(localStorage.getItem('FULL_NAME'))||'')
  error_message: ''
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGGED_IN':
      localStorage.setItem('LOGGED_IN', JSON.stringify(action.payload));
      return {...state}//, loggedIn:action.payload};
    // case 'LOGIN':
    //   localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(action.payload));
    //   localStorage.setItem('LOGGED_IN', JSON.stringify(true));
    //   localStorage.removeItem('PROJECT');
    //   return {...state, loggedIn:true, token:action.payload};
    case 'LOGOUT':
      localStorage.removeItem('REACT_TOKEN_AUTH');
      localStorage.removeItem('LOGGED_IN');
      localStorage.removeItem('PROJECT');
      localStorage.removeItem('FULL_NAME');
      return {...state}//, loggedIn:false};
    case 'ERRORMESSAGE':
      return {...state, error_message: action.payload}
    case 'USER_DETAILS':
      localStorage.setItem('FULL_NAME', JSON.stringify(action.payload));
      return {...state, full_name:action.payload};
    default:
      return state;
  }
};

export default AuthReducer;
