import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
    Button,
    Grid,
    Dialog,
    TextField,
    Card,
    Select,
    MenuItem,
    InputAdornment,
    Typography,
    CardContent
} from '@material-ui/core';
import { ReduxTextField } from "components/form-components/form-field/ReduxTextField";
import { ReduxDateField } from "components/form-components/form-field/ReduxDateField";
import { ReduxRequired } from "components/form-components/validation";
import { ReduxTextAreaField } from "components/form-components/form-field/ReduxTextAreaField";
import { ReduxNormalSelectField } from "../../../../components/form-components/form-field/ReduxNormalSelectField";
import { change, initialize, Field, FieldArray, reduxForm } from "redux-form";
import { useParams } from "react-router";
import ActivityFormLabours from "./ActivityFormLabours";
import ActivityFormMaterials from "./ActivityFormMaterials";
import ActivityFormPlants from "./ActivityFormPlants";
import ActivityFormDetails from "./ActivityFormDetails";
import {makeStyles} from "@material-ui/core/styles";
import {APP_URL} from 'static.js';
import {dateToString} from 'Page/UI_Design/js_utils/general';
import {ContentBox} from 'components/layout-components/Content';
import {GeneralButton} from 'components/other-components/Icons';
import {CreateActivity} from "../../../../actions/Activity";
import 'date-fns';
import {required, number, maxLength} from 'components/form-components/validation/fields';
import {loadAllPresets, loadSubLocation} from "../../../../actions/Presets";
import {GetActivity} from "../../../../actions/Activity";
import DateFnsUtils from '@date-io/date-fns';import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import {GetWeatherData} from "../../../../actions/Report";

const useStyles = makeStyles(theme => ({
    // sideTitles: theme.styles.font.tertiary,
    // button: theme.styles.buttons
}))

const maxLength100 = maxLength(100)

async function convertImgToBase64(url, callback, outputFormat){
	var canvas = document.createElement('CANVAS');
	var ctx = canvas.getContext('2d');
	var img = new Image;
	img.crossOrigin = '';
	img.onload = function(){
		canvas.height = img.height;
		canvas.width = img.width;
	  	ctx.drawImage(img,0,0);
	  	var dataURL = canvas.toDataURL(outputFormat || 'image/png');
	  	callback.call(this, dataURL);
        // Clean up
	  	canvas = null;
	};
	img.src = url;
}
//
// function convertImgToBase64(url, onSuccess, onError) {
//     var xhr = new XMLHttpRequest();
//
//     xhr.responseType = "arraybuffer";
//     xhr.open("GET", url);
//
//     xhr.onload = function () {
//         var base64, binary, bytes, mediaType;
//
//         bytes = new Uint8Array(xhr.response);
//         //NOTE String.fromCharCode.apply(String, ...
//         //may cause "Maximum call stack size exceeded"
//         binary = [].map.call(bytes, function (byte) {
//             return String.fromCharCode(byte);
//         }).join('');
//         mediaType = xhr.getResponseHeader('content-type');
//         base64 = [
//             'data:',
//             mediaType ? mediaType + ';':'',
//             'base64,',
//             btoa(binary)
//         ].join('');
//         onSuccess(base64);
//     };
//     xhr.onerror = onError;
//     xhr.send();
// }

function ActivityForm(props) {
    const { handleSubmit, pristine, reset, submitting } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorMsg, setError] = useState('')
    const formData = useSelector((state) => state.form);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const plants = useSelector((state) => state.presets.plants);
    const locations = useSelector((state) => state.presets.locations);
    const subLocations = useSelector((state) => state.presets.subLocations);
    const selectedReport = useSelector((state) => state.report.selectedReport);
    // const companies = useSelector((state) => state.presets.companies);
    const classes = useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const { id } = useParams();

  function LocationOnChange(e){
    loadSubLocation(dispatch, e.target.value)
    props.change('report', `sub_location`, null)
  }

  function createActivities(){
    // const url = APP_URL+'/site/activity/create/';
    const formValues = formData.report.values;
    var reportData = Object.assign({}, formValues);
    reportData.project = selectedProject.id;
    if (reportData.date instanceof Date){
      reportData.date = dateToString(reportData.date, '-');
    }
    if (!reportData.hasOwnProperty('details')){
      reportData.details = []
    }
    if (!reportData.hasOwnProperty('labours')){
      reportData.labours = []
    }
    if (!reportData.hasOwnProperty('plants')){
      reportData.plants = []
    }
    if (!reportData.hasOwnProperty('materials')){
      reportData.materials = []
    }
    reportData.labours.map((a,i)=>a.staff = a.staff.map((b,n)=>b.id));
    reportData.details.map((a,i)=>{
      if (a.time_start){
        a.time_start = a.time_start.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
      }else{
        a.time_start = null;
      }
    });
    reportData.details.map((a,i)=>{
      if (a.time_end){
        a.time_end = a.time_end.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
      }else{
        a.time_end = null;
      }
    });
    CreateActivity(reportData)
        .then(
            (resp) => {
              navigate('/app/act');
            }
        )
        .catch(function (error) {
          //console.log((error.response))
              setError(error.response.request.responseText);
            }
          );
    }

  useEffect(() => {
    dispatch({type:'SETPAGELOADING', payload:true})
    loadAllPresets(dispatch, selectedProject.id).then(() => {
    // axios.get(APP_URL+'/site/presets/all/1/').then((resp) => {
      if (id != 'none'){
        GetActivity(id).then((resp) => {
          let manipulate_time_data = resp.data
          manipulate_time_data.details.map((d, d_index)=> {
            let date1 = new Date()
            if (d.time_start) {
              let splitTime = d.time_start.split(':')
              date1.setHours(splitTime[0])
              date1.setMinutes(splitTime[1])
              d.time_start = date1;
            }
            if (d.time_end) {
              let date2 = new Date()
              let splitTime = d.time_end.split(':')
              date2.setHours(splitTime[0])
              date2.setMinutes(splitTime[1])
              d.time_end = date2;
            }
            d.images.map(async(value, i_index)=>{
              await convertImgToBase64(value.data_url, function(base64Img){
                props.change('report', `details[${d_index}].images[${i_index}]`, {'data_url':base64Img})
                //         onChange={() => {props.change("activity", `${labour}
              })
            })
          })
          props.initialize('report', resp.data)
          loadSubLocation(dispatch, resp.data.location)
          // dispatch({'type':'LOADSUBLOCATIONS', 'payload':(resp.data.location)});
        });
      }else{
        GetWeatherData()
          .then((weather_data) => {
            let report_data = {}
              report_data['rainfall'] = weather_data.rainfall
              report_data['weather_am'] = weather_data.temperature
              report_data['weather_pm'] = weather_data.temperature
              report_data['typhoon'] = weather_data.warning.length > 0 ? weather_data.warning[0] : ''
              props.initialize('report', report_data)
          })
      }
      dispatch({type:'SETPAGELOADING', payload:false})
    });
  }, []);

    return (
        <Card className=''>
            <form onSubmit={handleSubmit(() => createActivities())}>
                <CardContent className=''>
                    <Grid container spacing={3}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} >
                                <Typography className='font-weight-bold ' color='primary'>Project details</Typography>
                            </Grid>
                            <Grid item xs={9} container spacing={4}>
                                <Grid item xs={12} container direction='row' spacing={3}>
                                    <Grid item xs={10} lg={5} className=''>
                                        <TextField
                                            label={'Contract No.'}
                                            variant={'outlined'}
                                            name={"contract_no"}
                                            value={selectedProject.contract_no}
                                            disabled={true}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} >
                                <Typography className='font-weight-bold ' color='primary'>Location and Date</Typography>
                            </Grid>
                            <Grid item xs={9} container spacing={4}>
                                <Grid item xs={12} container direction='row' spacing={3}>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxNormalSelectField
                                            name="location"
                                            label="Location*"
                                            onChange={LocationOnChange}
                                            validate={[ReduxRequired]}
                                        >
                                            {locations.map((e, i) => (
                                                <MenuItem key={'locations' + i} value={e.id}>{e.name}</MenuItem>
                                            ))}
                                        </ReduxNormalSelectField>
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxNormalSelectField
                                            name="sub_location"
                                            label="Sub Location"
                                            validate={[]}
                                        >
                                            {subLocations.map((e, i) => (
                                                <MenuItem key={'subLocations' + i} value={e.id}>{e.name}</MenuItem>
                                            ))}
                                        </ReduxNormalSelectField>
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxDateField name={"date"}
                                            label={"Date*"}
                                            validate={[ReduxRequired]}
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''></Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxTextField
                                            name={"weather_am"}
                                            label={"Weather (A.M.)"}
                                            validate={[maxLength100]}
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxTextField
                                            name={"weather_pm"}
                                            label={"Weather (P.M.)"}
                                            validate={[maxLength100]}
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxTextField
                                            name={"typhoon"}
                                            label={"Typhoon / Rainstorm Warning Signals"}
                                            validate={[maxLength100]}
                                        />
                                    </Grid>
                                    <Grid item xs={10} lg={5} className=''>
                                        <ReduxTextField
                                            name={"rainfall"}
                                            label={"Rainfall"}
                                            validate={[maxLength100, number]}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} className=''>
                                <Typography className='font-weight-bold ' color='primary'>Activities</Typography>
                            </Grid>
                            {/* <Grid item xs={9} spacing={3}> */}
                            <FieldArray name="details" component={ActivityFormDetails} />
                            {/* </Grid> */}
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} className=''>
                                <Typography className='font-weight-bold ' color='primary'>Labours</Typography>
                            </Grid>
                            {/* <Grid item xs={9}> */}
                                <FieldArray name="labours" component={ActivityFormLabours} />
                            {/* </Grid> */}
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} className=''>
                                <Typography className='font-weight-bold ' color='primary'>Plants</Typography>
                            </Grid>
                            {/* <Grid item xs={9}> */}
                                <FieldArray name="plants" component={ActivityFormPlants} />
                            {/* </Grid> */}
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={3} className=''>
                                <Typography className='font-weight-bold ' color='primary'>Materials</Typography>
                            </Grid>
                            {/* <Grid item xs={9}> */}
                                <FieldArray name="materials" component={ActivityFormMaterials} />
                            {/* </Grid> */}
                        </Grid>
                        <Grid item className='mt-3' xs={12} lg={4} className=''>
                            <GeneralButton type='submit' label={'create & submit'} fullWidth={true}></GeneralButton>
                        </Grid>
                    </Grid>
                </CardContent>

            </form>
        </Card>
    );
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
    change, initialize
};

ActivityForm = connect(mapStateToProps,
    mapDispatchToProps
)(ActivityForm)

export default reduxForm({
    form: 'report', // a unique identifier for this form
})(ActivityForm);


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)