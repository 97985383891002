import {combineReducers} from "redux";
import ProjectReducer from "./ProjectReducer";
import PresetsReducer from "./PresetsReducer";
import ReportReducer from "./ReportReducer";
import NavReducer from "./NavReducer";
import AuthReducer from "./AuthReducer";
import { reducer as reduxFormReducer } from 'redux-form';
import NotificationReducer from "./NotificationReducer";

// const allReducers = StaffReducer;
const allReducers = combineReducers({
  // staff: StaffReducer,
  // pdf: PDFReducer,
  notification: NotificationReducer,
  project: ProjectReducer,
  presets: PresetsReducer,
  report: ReportReducer,
  nav:NavReducer,
  auth:AuthReducer,
  form: reduxFormReducer
});

export default allReducers;
