import {TableCell, TableRow} from "@material-ui/core";
import React from "react";

export function SiteSheet1TemplateStaff(array_values, total){
  return [
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['A',12],
        'table_area': [3,37],
        'data': array_values.map((a, i)=> {return [a.staff__trade__name || 'N/A',null, a.count]}),
     },
    },
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['E',12],
        'table_area': [3,26],
        'data': array_values.map((a, i)=> {return [a.staff__trade__name || 'N/A',null, a.count]}).slice(37),
     },
    },
    // {
    // 'sheet_name': '1',
    // 'insert': {
    //     'start_position': ['E',38],
    //     'table_area': [3,1],
    //     'data': [['Total',null,total]],
    //  },
    // },
  ]
}

export function SiteSheet1TemplateLabour(array_values, total){
  return [
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['I',12],
        'table_area': [3,37],
        'data': array_values.map((a, i)=> {return [a.trade__name || 'N/A',null, a.count]}),
     },
    },
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['M',12],
        'table_area': [3,36],
        'data': array_values.map((a, i)=> {return [a.trade__name || 'N/A',null, a.count]}).slice(37),
     },
    },
    // {
    // 'sheet_name': '1',
    // 'insert': {
    //     'start_position': ['M',38],
    //     'table_area': [3,1],
    //     'data': [['Total',null,total]],
    //  },
    // },
  ]
}

export function SiteSheet1TemplatePlant(array_values, total){


  return [
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['Q',12],
        'table_area': [6,37],
        'data': array_values.map((a, i)=> {return [a.plant__type || 'N/A',null, null, a.working_count, a.idling_count]}),
     },
    },
    {
    'sheet_name': '1',
    'insert': {
        'start_position': ['W',12],
        'table_area': [6,36],
        'data': array_values.map((a, i)=> {return [a.plant__type || 'N/A',null, null, a.working_count, a.idling_count]}).slice(37),
     },
    },
    // {
    // 'sheet_name': '1',
    // 'insert': {
    //     'start_position': ['M',38],
    //     'table_area': [3,1],
    //     'data': [['Total',null,total]],
    //  },
    // },
  ]
}

export function SiteSheet1TemplateSupervisor(array_values, total) {
  return [
    {
      'sheet_name': '1',
      'insert': {
        'start_position': ['E', 42],
        'table_area': [3, 6],
        'data': array_values.map((a, i) => {
          return [a.staff__position__name || 'N/A', null, a.count]
        }),
      },
    },
  ]
}

//export function SiteSheet1TemplatePlant(array_values, total){
//  return [
//    {
//    'sheet_name': '1',
//    'insert': {
//        'start_position': ['Q',12],
//        'table_area': [5,37],
//        'data': array_values,
//     },
//    },
//    {
//    'sheet_name': '1',
//    'insert': {
//        'start_position': ['W',12],
//        'table_area': [5,36],
//        'data': array_values.slice(37),
//     },
//    },
//    {
//    'sheet_name': '1',
//    'insert': {
//        'start_position': ['M',38],
//        'table_area': [3,1],
//        'data': [['Total',null,total]],
//     },
//    },
//  ]
//}
