import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { WrapperBox } from "../../../components/layout-components/Content";
import {
    Grid,
    Dialog,
    TextField,
    Paper,
    Select,
    MenuItem,
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Box, FormControl, InputLabel, Card
} from '@material-ui/core';
import ReportTable from 'Page/UI_Design/DailyReport/ReportTable';
import { makeStyles } from "@material-ui/core/styles";
import TwoBoxesDateRangePicker from "../../../components/date-components/TwoBoxesDateRangePicker";
import { APP_URL } from "../../../static";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import { FrameSelectBox } from 'components/layout-components/FrameSelectMenu'
import { GetReportList } from "../../../actions/Report";
const useStyles = makeStyles(theme => ({
    //   title: theme.styles.font.primary,
    //   frame: theme.styles.page.frame,
    //   content: theme.styles.page.content,
    selectbox: {}
}))


function Index() {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navDate = useSelector((state) => state.project.selectDateTo);
    const classes = useStyles();
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [reportList, setList] = useState([]);
    const [refreshList, setRefresh] = useState(false);
    const borderSelectClasses = useBorderSelectStyles();
    const today = new Date();
    const [selectYear, setSelectYearOption] = useState((navDate.getFullYear().toString()))
    const [selectMonth, setSelectMonthOption] = useState(navDate.getMonth())
    let year_array = []
    for (let i = 2019; i <= today.getFullYear(); i++) {
        year_array.push(i);
    }
    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };
    //
    useEffect(() => {
        if (selectedProject.id) {
            const query_string = '?page_size=1000&project=' + selectedProject.id + '&year=' + selectYear + '&month=' + (selectMonth + 1)
            GetReportList(query_string).then((resp) => setList(resp.data.results))
        }
    }, [selectedProject, selectYear, selectMonth, refreshList]);//[id]);

    function refresh() {
        setRefresh(!refreshList)
    }

    return (
        <Grid container spacing={3} justify={'space-between'}>
            <Grid item>
                <span className='font-size-lg font-weight-bold'>Daily Report</span>
            </Grid>
            <Grid item lg={6} className='text-right'>
                <Grid container item xs={12} justify='flex-end'>
                    <Grid item lg={4} xs={6} className={'pr-3'}>
                        <FrameSelectBox labelId='SelectMonth' label={'Month'} onChange={(e) => setSelectMonthOption(e.target.value)} value={selectMonth}>
                            {monthNames.map((year, i) => (
                                <MenuItem
                                    key={year.toString()}
                                    value={i}>{year.toString()}
                                </MenuItem>
                            ))}
                        </FrameSelectBox>
                    </Grid>
                    <Grid item lg={4} xs={6}>
                        <FrameSelectBox labelId='SelectYear' label={'Year'} onChange={(e) => setSelectYearOption(e.target.value)} value={selectYear}>
                            {year_array.map((year, i) => (
                                <MenuItem
                                    key={year.toString()}
                                    value={year.toString()}>{year.toString()}
                                </MenuItem>
                            ))}
                        </FrameSelectBox>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Card><ReportTable reportList={reportList} refresh={refresh} /></Card>
            </Grid>
        </Grid>
    );
}
export default Index;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
