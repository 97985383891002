import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import {
  Box, InputLabel, Tabs, Tab, Grid, Card
} from '@material-ui/core';
import {useParams} from "react-router";
import ActivityViewTable from 'Page/UI_Design/Activity/ActivityList/ActivityViewTable';
import {APP_URL} from "../../../static.js";
import {GetActivityList} from "../../../actions/Activity";
import {GetReport, LoadReport} from "../../../actions/Report";
import {Document, Page} from "react-pdf";
import {downloadReport} from "./Reports_NGA/ReportDownload";


function ReportViewPage3() {
    const selectedReport = useSelector((state) => state.report.selectedReport);
    // const [signature, setSignature] = useSchtate(null)
    const [pdfBlob, setBlob] = useState(null)
    const dispatch = useDispatch();
    const [numPages, setNumPages] = useState(null);
    let width = window.innerWidth - 362;
    if (width < 800){
      width = 800;
    }

    useEffect(() => {
      if(selectedReport.id) {
        downloadReport(selectedReport, 'pdf').then((resp) => {
          console.log(resp.data)
          setBlob(resp.data)
          // downloadFile(resp.data, 'application/pdf', 'file.pdf')
        })
      }
      // downloadFile(resp.data, "application/vnd.ms-excel", 'file.xlsx')
    },[selectedReport])

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
      setNumPages(nextNumPages);
    }

  return (
      <Grid item xs={12} container justify={'center'}>
          <Grid item>
            <Card>
              {/*<div className={`${classes.arrow} ${classes.left}`} onClick={()=>{setNumPages(numPages-1)}}></div>
              <div className={`${classes.arrow} ${classes.right}`} onClick={()=>{setNumPages(numPages+1)}}></div>*/}
            <div className="Example__container__document">
              <Document
                  file={pdfBlob}
                  onLoadSuccess={onDocumentLoadSuccess}
              >
                {
                  Array.from(
                    new Array(numPages),
                    (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={width}
                        scale={1}
                      />
                    ),
                  )
                }
              </Document>
            </div>
            </Card>
          </Grid>
      </Grid>
  );
}
export default ReportViewPage3;
