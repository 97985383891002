import React, { useState, useRef, useEffect, createRef } from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { parseDate } from 'react-day-picker/moment';
import CustomDatePickerOverlay from 'components/date-components/CustomDatePickerOverlay';
import { FormControl, Grid, InputAdornment, InputLabel, TextField } from "@material-ui/core";
import dateFnsFormat from 'date-fns/format';
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    input: {
        backgroundColor: '#f8f9ff',
        fontSize: '1rem',
        border: '1px #e0e0e0 solid',
        color: '#616161',
        padding: '12px',
        '&:focus': {
            outline: 'none',
        }
    },
    day_picker_input: {
        '& .DayPicker-NavButton--prev': {
            width: '1.3rem',
            height: '1.3rem',
            transform: 'translate(-2rem, 0)',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS4xMTUiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMS4xMTUgMTgiPgogIDxwYXRoIGlkPSJJY29uX21hdGVyaWFsLWtleWJvYXJkLWFycm93LWxlZnQiIGRhdGEtbmFtZT0iSWNvbiBtYXRlcmlhbC1rZXlib2FyZC1hcnJvdy1sZWZ0IiBkPSJNMjMuMTE1LDI0LjEzNWwtNi44Ny02Ljg4NSw2Ljg3LTYuODg1TDIxLDguMjVsLTksOSw5LDlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIgLTguMjUpIiBmaWxsPSIjMzkzNDRhIi8+Cjwvc3ZnPgo=) !important'
        },
        '& .DayPicker-NavButton--next': {
            width: '1.3rem',
            height: '1.3rem',
            transform: 'translate(0, 0)',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS4xMTUiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMS4xMTUgMTgiPgogIDxwYXRoIGlkPSJJY29uX21hdGVyaWFsLWtleWJvYXJkLWFycm93LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gbWF0ZXJpYWwta2V5Ym9hcmQtYXJyb3ctcmlnaHQiIGQ9Ik0xMi44ODUsMjQuNTFsNi44Ny02Ljg4NS02Ljg3LTYuODg1TDE1LDguNjI1bDksOS05LDlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTIuODg1IC04LjYyNSkiIGZpbGw9IiMzOTM0NGEiLz4KPC9zdmc+Cg==) !important'
        },
    }
}))

function Weekday({ weekday, className, localeUtils, locale }) {
    const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
    return (
        <div className={className} title={weekdayName}>
            {weekday} {weekdayName.slice(0, 1)}
        </div>
    );
}

function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
}

export default function DateRangePicker(props) {
    const { handleFromChange, handleToChange, to, from } = props
    const refTo = createRef();
    const refFrom = createRef();
    const classes = useStyles();

    const [showOverlayFrom, setShowOverlayFrom] = useState(false);
    const [showOverlayTo, setShowOverlayTo] = useState(false);

    function showFromMonth() {
        // const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            refTo.current.getDayPicker().showMonth(from);
        }
    }
    //
    function handleChangeTo(to) {
        if (to && moment(to).isValid()) {
            handleToChange(to);
            showFromMonth();
        }
    }

    const handleChangeFrom = (from) => {
        if (from && moment(from).isValid()) {
            handleFromChange(from);
        }
    }

    const modifiers = { start: from, end: to };
    // 1 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
    // 2 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    //minWidth: 235px

    return (
        <>
            <Grid container xs={12} spacing={0} className='InputFromTo'>
                <Grid item xs={6}>
                    <DayPickerInput
                        // inputProps={{className:classes.input, style:{border:'none', width:'110px', textAlign:'right', marginRight:'0px', borderRight:'none', paddingRight:'0px', borderRadius: '3px 0 0 3px'}}}
                        overlayComponent={CustomDatePickerOverlay}
                        component={(props) => <TextField
                            variant="outlined"
                            label='From'
                            className='w-100'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }} disablePointerEvents>
                                        <KeyboardArrowDown />
                                    </InputAdornment>
                                ),
                                style: { paddingRight: 7 },
                            }}
                            {...props}
                        />}
                        ref={refFrom}
                        value={from}
                        placeholder="From"
                        // format="LL"
                        // formatDate={formatDate}
                        // format=' dd/MM/yyyy'
                        // parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { after: to },
                            toMonth: to,
                            modifiers,
                            numberOfMonths: 2,
                            className: classes.day_picker_input,
                            // onDayClick: () => refTo.current.getInput().focus(),
                        }}
                        onDayChange={handleChangeFrom}
                        style={{ width: '100%' }}
                        keepFocus={false}
                    />
                </Grid>
                {/* <span className="InputFromTo-to"> */}
                <Grid item xs={6} className="InputFromTo-to pl-4">
                    <DayPickerInput
                        // inputProps={{onClick:()=>refFrom.current.getInput().focus(), className:classes.input, style:{border:'none',width:'125px', borderLeft:'none', paddingLeft:'0px', borderRadius: '0 3px 3px 0'}}}
                        overlayComponent={CustomDatePickerOverlay}
                        component={(props) => <TextField
                            {...props}
                            variant="outlined"
                            label='To'
                            className='w-100'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }} disablePointerEvents>
                                        <KeyboardArrowDown />
                                    </InputAdornment>
                                ),
                                style: { paddingRight: 7 },
                            }}
                        />}
                        ref={refTo}
                        value={to}
                        placeholder="To"
                        // format="LL"
                        // formatDate={formatDate}
                        // format=' dd/MM/yyyy'
                        // parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: from,
                            fromMonth: from,
                            numberOfMonths: 2,
                            className: classes.day_picker_input
                        }}
                        onDayChange={handleChangeTo}
                        style={{ width: '100%' }}
                    />
                </Grid>
                {/* </span> */}
                <Helmet>
                    <style>{`
                        .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                            background-color: #f0f8ff !important;
                            color: #4a90e2;
                        }
                        .InputFromTo .DayPicker-Day {
                            border-radius: 0 !important;
                            width: 46px !important;
                            height: 46px !important;
                        }
                        .InputFromTo .DayPicker-Day--start {
                            border-top-left-radius: 50% !important;
                            border-bottom-left-radius: 50% !important;
                        }
                        .InputFromTo .DayPicker-Day--end {
                            border-top-right-radius: 50% !important;
                            border-bottom-right-radius: 50% !important;
                        }
                    `}</style>
                </Helmet>
            </Grid>
        </>
        // <span className="InputFromTo" style={{width:'235px', position:'relative'}}>
        //     <InputLabel style={{
        //         color: 'gray',
        //         padding: '0',
        //         fontSize: '0.8rem',
        //         fontFamily: "Roboto",
        //         fontWeight: '500',
        //         letterSpacing: '0.00938em',
        //         transform: 'translate(10px, -5px)',
        //         backgroundColor:'#f8f9ff',
        //         position:'absolute',
        //         zIndex:'101'
        //     }}>
        //         &nbsp;Date&nbsp;
        //     </InputLabel>
        //     <div style={{
        //         position:'absolute', width:'235px', zIndex:'100', height:'50px', borderRadius:'3px',
        //         boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        //         border: '1px #e0e0e0 solid',
        //     }} onClick={()=>refFrom.current.getInput().focus()}></div>
        //     <ExpandMoreIcon
        //         style={{
        //             transform: 'translate(205px, 13px)',
        //             position:'absolute',
        //             color:'gray',
        //         }}
        //         onClick={()=>refFrom.current.getInput().focus()}
        //     />
        //     <DayPickerInput
        //         inputProps={{className:classes.input, style:{border:'none', width:'110px', textAlign:'right', marginRight:'0px', borderRight:'none', paddingRight:'0px', borderRadius: '3px 0 0 3px'}}}
        //         overlayComponent={CustomDatePickerOverlay}
        //         value={from}
        //         ref={refFrom}
        //         placeholder="From"
        //         format="LL"
        //         formatDate={formatDate}
        //         format='dd/MM/yyyy -'
        //         parseDate={parseDate}
        //         dayPickerProps={{
        //             selectedDays: [from, { from, to }],
        //             disabledDays: { after: to },
        //             toMonth: to,
        //             modifiers,
        //             numberOfMonths: 2,
        //             onDayClick: () => refTo.current.getInput().focus(),
        //         }}
        //         onDayChange={handleFromChange}
        //     />

        //     <span className="InputFromTo-to">
        //         <DayPickerInput
        //             inputProps={{onClick:()=>refFrom.current.getInput().focus(), className:classes.input, style:{border:'none',width:'125px', borderLeft:'none', paddingLeft:'0px', borderRadius: '0 3px 3px 0'}}}
        //             overlayComponent={CustomDatePickerOverlay}
        //             ref={refTo}
        //             value={to}
        //             placeholder="To"
        //             format="LL"
        //             formatDate={formatDate}
        //             format=' dd/MM/yyyy'
        //             parseDate={parseDate}
        //             dayPickerProps={{
        //                 selectedDays: [from, { from, to }],
        //                 disabledDays: { before: from },
        //                 modifiers,
        //                 month: from,
        //                 fromMonth: from,
        //                 numberOfMonths: 2,
        //             }}
        //             onDayChange={handleChangeTo}
        //         />
        //     </span>
        //     <Helmet>
        //         <style>{`
        //           .InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        //             background-color: #f0f8ff !important;
        //             color: #4a90e2;
        //           }
        //           .InputFromTo .DayPicker-Day {
        //             border-radius: 0 !important;
        //             padding: 0.5rem;
        //           }
        //           .InputFromTo .DayPicker-Day--start {
        //             border-top-left-radius: 50% !important;
        //             border-bottom-left-radius: 50% !important;
        //           }
        //           .InputFromTo .DayPicker-Day--end {
        //             border-top-right-radius: 50% !important;
        //             border-bottom-right-radius: 50% !important;
        //           }
        //           .InputFromTo .DayPickerInput-Overlay {
        //             width: 550px;
        //           }
        //       `}</style>
        //     </Helmet>
        // </span>
    );
    // }
}

