import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { Field, FieldArray, reduxForm } from "redux-form";
import { useParams } from "react-router";
import CustomDatePickerOverlay from "../../../../components/date-components/CustomDatePickerOverlay";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { IconButton, Box, Grid, Typography, Divider, Card, CardContent } from "@material-ui/core";
import { ContentBox } from 'components/layout-components/Content';
import { AddCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { APP_URL } from 'static';
import DateInputPicker from 'components/date-components/DatePicker';
import {GetActivityList} from "../../../../actions/Activity";

const useStyles = makeStyles(theme => ({
    // sideTitles: theme.styles.font.tertiary,
    // addIconColor: { color: theme.palette.highlights.main }
}))

function ActivityHistory() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //  const [selectedDate, setDate] = useState('');
    const [activity_data, setActivity] = useState([])
    const classes = useStyles();
    const [selectedDate, handleDateChange] = useState(new Date());

    function dateToString(date) {
        return (date.getFullYear()) +
            "-" + (date.getMonth() + 1) +
            "-" + date.getDate();
    }
    let dateArray = []
    let month = new Date().getMonth();
    let year = new Date().getFullYear();

  useEffect(() => {
    GetActivityList('?date='+dateToString(selectedDate)).then((resp)=>{
      setActivity(resp.data.results);
    })
  }, [selectedDate]);

    function Activities() {
        return (
            <Grid item xs={12} container spacing={2}>
                <Grid container item xs={12} direction='row' spacing={1}>
                    <Grid item xs={12}>
                        <Typography className='mb-2 font-weight-bold' color='primary' >Activities</Typography>
                    </Grid>
                    {activity_data.map((activity, index) =>
                        activity.details.map((detail, i) =>
                            <Grid container item direction='row' key={index + 'details' + i} justify='center' spacing={1}>
                                <Grid item xs={3} lg={2}>
                                    <IconButton
                                        onClick={() => dispatch({ type: 'COPYACTIVITY', payload: detail })}
                                        // style={{ width: '20px', padding: '0px' }}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                    >
                                        <AddCircle color='primary' />
                                    </IconButton>
                                </Grid>
                                <Grid item container xs={9} lg={10} alignItems='center'>
                                    <Typography>{detail.activity_category ? detail.activity_category.name : null} {detail.activity_remarks}</Typography>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
                <Grid item xs={12}>
                    {/* <div style={{ border: '1px #cccccc solid', borderRadius: '3px' }}></div> */}
                    <Divider style={{ backgroundColor: '#AFA4C8', padding: 0 }} />
                </Grid>
            </Grid>
        )
    }

    function Labours() {
        return (
            <Grid item xs={12} container spacing={2}>
                <Grid container item xs={12} direction='row' spacing={1}>
                    <Grid item xs={12}>
                        <Typography className='mb-2 font-weight-bold' color='primary'>Labours</Typography>
                    </Grid>
                    {activity_data.map((activity, index) =>
                        activity.labours.map((labour, i) =>
                            <Grid container item direction={'row'} key={index + 'labour' + i} justify='center' spacing={1}>
                                <Grid item xs={3} lg={2}>
                                    <IconButton
                                        onClick={() => dispatch({ type: 'COPYLABOUR', payload: labour })}
                                        // style={{ width: '20px', padding: '0px' }}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                    >
                                        <AddCircle color='primary' />
                                    </IconButton>
                                </Grid>
                                <Grid container item direction='row' xs={9} lg={10}>
                                    <Grid className='ml-1' container item direction='row' spacing={1}>
                                        <Grid item xs={8}>
                                            {labour.trade ? labour.trade.name : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {labour.no}
                                        </Grid>
                                    </Grid>
                                    <Grid className='ml-1' container item direction='row' spacing={1}>
                                        <Grid item xs={8}>
                                            {labour.mc_sc ? labour.mc_sc.name : ''}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
                <Grid item xs={12}>
                    {/* <div style={{ border: '1px #cccccc solid', borderRadius: '3px' }}></div> */}
                    <Divider style={{ backgroundColor: '#AFA4C8', padding: 0 }} />
                </Grid>
            </Grid>
        )
    }

    function Plants() {
        return (
            <Grid item xs={12} container spacing={2}>
                <Grid container item xs={12} direction='row' spacing={1}>
                    <Grid item xs={12}>
                        <Typography className='mb-2 font-weight-bold' color='primary'>Plants</Typography>
                    </Grid>
                    {activity_data.map((activity, index) =>
                        activity.plants.map((plant, i) =>
                            <Grid item xs={12} container direction='row' key={index + 'plant' + i} justify='center' spacing={1}>
                                <Grid item xs={3} lg={2}>
                                    <IconButton
                                        onClick={() => dispatch({ type: 'COPYPLANT', payload: plant })}
                                        // style={{ width: '20px', padding: '0px' }}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                    >
                                        <AddCircle color='primary' />
                                    </IconButton>
                                </Grid>
                                <Grid container item direction='row' xs={9} lg={10}>
                                    <Grid container item direction='row' spacing={1}>
                                        <Grid item xs={8}>
                                            {plant.plant ? plant.plant.type : null}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {plant.unit_id}
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction='row' spacing={1}>
                                        <Grid item xs={8}>
                                            {plant.mc_sc ? plant.mc_sc.name : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
                {/* <Grid item xs={12}>
                    <div style={{ border: '1px #cccccc solid', borderRadius: '3px' }}></div>
                </Grid> */}
            </Grid>
        )
    }

    return (
        <Card className='h-100'>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className='font-weight-bold' color='primary'>History</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                            <DateInputPicker label={'Date'} value={selectedDate} onChange={(e) => handleDateChange(e)} />
                        </Grid>
                    </Grid>
                    <Activities />
                    <Labours />
                    <Plants />
                </Grid>
            </CardContent>
        </Card>
    );
}
export default reduxForm({
    form: 'report', // a unique identifier for this form
})(ActivityHistory);


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)
