import {APP_URL} from '../../static';
import axios from "axios";
import {BackEndRequest, defaultHeaders} from "../APICalls";

// const getActivityList = async () => {

export async function getPresetField(id, field){
  const resp = await BackEndRequest('get','/site/presets/all/'+id+'/?fields='+field, defaultHeaders, {})
  return resp;
}

export async function loadAllPresets(dispatch, project_id){
  await BackEndRequest('get','/site/presets/all/'+project_id+'/', defaultHeaders, {})
  .then((resp)=>
    dispatch({type: 'STOREPRESETS', payload: resp.data})
  )
};

export async function loadLocations(dispatch, project_id){
  await BackEndRequest('get',`/site/presets/all/`+project_id+'/?fields=locations', defaultHeaders, {})
    .then((resp) =>
      dispatch({type: 'STORELOCATION', payload: resp.data})
    )
};

export async function updateLocation(id, data){
  const resp = await BackEndRequest('patch','/site/presets/location/'+id+'/', defaultHeaders, data)
  return resp;
}

export async function deleteLocation(id){
  const resp = await BackEndRequest('put','/site/presets/location/'+id+'/', defaultHeaders, {})
  return resp;
}

export async function getSubLocation(location_id){
  const resp = await BackEndRequest('get','/site/presets/sublocation/?location=' + location_id, defaultHeaders, {})
  return resp;
}

export async function getSubTradeList(project_id, params){
  const resp = await BackEndRequest('get','/site/presets/sub_labour_trade/?trade__project=' + project_id + params, defaultHeaders, {})
  return resp;
}

export function loadSubLocation(dispatch, location_data){
  dispatch({'type':'LOADSUBLOCATIONS', 'payload':location_data});
}

export async function loadLabourTrades(dispatch, project_id){
  await BackEndRequest('get','/site/presets/all/'+project_id+'/?fields=labour_trades', defaultHeaders, {})
  .then((resp)=>
    {
      dispatch({type: 'STORELABOUR', payload: resp.data})
    }
  )
}

export async function getSubTrade(trade_id){
  const resp = await BackEndRequest('get','/site/presets/sub_labour_trade/?trade=' + trade_id, defaultHeaders, {})
  return resp;
}

export function loadSubTrade(dispatch, location_data){
  dispatch({'type':'LOADSUBLOCATIONS', 'payload':location_data});
}

export async function updateLabourTrade(trade_id, data){
  const resp = await BackEndRequest('patch','/site/presets/labour_trade/'+trade_id+'/', defaultHeaders, data)
  return resp;
}

export async function loadAllStaff(dispatch, project_id){
  await BackEndRequest('get',`/site/staffselect/?project_id=`+project_id+`&page_size=10000`, defaultHeaders, {})
  .then((resp)=>
    dispatch({type: 'GETSTAFFLIST', payload: resp.data.results})
  )
}

export const selectPlant = (id) => async (dispatch, getState) => {
  dispatch({type: 'SELECTPLANT', payload: id})
}

export const selectLabour = (id) => async (dispatch, getState) => {
  dispatch({type: 'SELECTLABOUR', payload: id})
}


//
// export const loadPositions = (project_id) => async (dispatch, getState) => {
//     axios.get(`${APP_URL}/site/presets/all/`+project_id+'/?fields=positions').then((resp) =>
//     dispatch({type: 'STOREPOSITIONS', payload: resp.data}))
// };

// export const getWorkCategories = (project_id) => async (dispatch, getState) => {
//     await axios.get(`${APP_URL}/site/presets/all/`+project_id+'/?fields=labourTrades').then((resp) =>
//     dispatch({type: 'STOREWORKCAT', payload: resp.data}))
// };
//
// export const loadContractors = (project_id) => async (dispatch, getState) => {
//     axios.get(`${APP_URL}/site/presets/all/`+project_id+'/?fields=contractors').then((resp) =>
//     dispatch({type: 'STORECONTRACTORS', payload: resp.data}))
// };
