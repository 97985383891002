import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import {InlineStyles, generalSpacing} from 'stylesCss';
import {
    InputLabel,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    Typography
} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { dateToString } from 'Page/UI_Design/js_utils/general';
import { makeStyles } from "@material-ui/core/styles";
import { APP_URL } from 'static';
import { area_configs } from 'Page/UI_Design/Dashboard/Highcharts/area_configs';
import { column_configs } from 'Page/UI_Design/Dashboard/Highcharts/column_configs';
import { pie_options } from "./Highcharts/pie_configs";
import { process_line_data } from "./Highcharts/get_chart_data";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HighchartsComponent } from 'components/other-components/HighchartsComponent';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {BackEndRequest, defaultHeaders} from "../../../actions/APICalls";
import {ReportURL} from "../../../actions/Dashboard";

const useStyles = makeStyles(theme => ({
    //   title: theme.styles.font.primary,
    //   frame: theme.styles.page.frame,
    //   content: theme.styles.page.content,
    //   generalSpacing: theme.styles.page.generalSpacing,
}))



function MainGraph(props) {
    const classes = useStyles();
    const { from, to, selectedActivity } = props;
    const selectedProject = useSelector((state) => state.project.selectedProject);
    const [ChartData, setChartData] = useState(column_configs([], [], '', '', ''))
    const [selectValue, setSelectOption] = useState('staff__eng_name')
    const [chartType, setChartType] = useState('bar')
    const borderSelectClasses = useBorderSelectStyles();
    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };
    //
    useEffect(() => {
        if (selectedActivity && selectValue != 'staff__eng_name') {
            setSelectOption('staff__eng_name');
        }
        else {
            refresh();
        }
    }, [selectedActivity]);

    useEffect(() => {
        if (dateToString(to, '-') != dateToString(from, '-')) {
            if (selectValue != 'activity__date') {
                setSelectOption('activity__date');
            } else {
                refresh()
            }
        }
        else {
            if (selectValue == 'activity__date') {
                setSelectOption('staff__eng_name');
            }
        }
    }, [from, to]);

    useEffect(() => {
        refresh();
    }, [selectedProject, selectValue]);

  function refresh(){
      let url = '/site/labour_data/?date__lte=' + dateToString(to, '-') + '&date__gte=' + dateToString(from, '-') + '&activity__project=' + selectedProject.id;
      let type = 'bar';

      if (selectedActivity) {
        url += '&activity=' + selectedActivity;
      } else if (dateToString(to, '-') != dateToString(from, '-')) {
        type = 'line'
      }
      BackEndRequest('post', url, defaultHeaders, {
        groupValue: selectValue,
        countSum: 'staff__daily_wage'
      }).then((resp) => {
        if (selectValue == 'activity__date') {
          const series_data = [{
            'name': 'Total', 'data': resp.data.map(function (x) {
              return {'x': new Date(x[selectValue]), 'y': x.staff__daily_wage__sum}
            }),
            'fillColor': {
              linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            }
          }]
          console.log(area_configs(series_data, '', '', ''))
          setChartData(area_configs(series_data, '', '', ''))
        } else {
          const categories = resp.data.map(function (x) {
            return x[selectValue]
          })
          // const labourData = resp.data.map(function(x) {return {'y':x.staff__daily_wage__sum, 'color':(Highcharts.color('#24CBE5').brighten((Math.random()*10 - 6) / 7).get())}})
          const labourData = resp.data.map(function (x) {
            return {'y': x.staff__daily_wage__sum, 'color': '#DAD0EC'}
          })
          const series = [{name: 'Total Labour Costs', data: labourData}]
          const chartData = column_configs(categories, series, '', '', ''); //Labour Plants Count, Date, Count
          setChartData(chartData)
        }
      });
      setChartType(type);
  }


    return (
        <Card className='card-box w-100 h-100 border-0'>
            <div className='card-header border-0'>
                <div className="card-header--title">
                    <Typography className='pl-1 pt-1' color='primary' style={{fontWeight: 500}}>Staff Cost Assumption</Typography>
                </div>
                <div className='card-header--actions'>
                    <FormControl size={'small'} variant={'outlined'}>
                        <Select
                            IconComponent={iconComponent}
                            className='text-left ml-3'
                            style={{ minWidth: '200px' }}
                            labelId={'SelectOpt'}
                            onChange={(e) => setSelectOption(e.target.value)}
                            value={selectValue}
                            disableUnderline
                        >
                            {chartType != 'line' ? null :
                                <MenuItem
                                    key={'date_opt'}
                                    value='activity__date'>Date
                                </MenuItem>
                            }
                            <MenuItem
                                key={'staff_opt'}
                                value='staff__eng_name'>Staff
                            </MenuItem>
                            {!selectedActivity ?
                                <MenuItem
                                    key={'loc_opt'}
                                    value='activity__location__name'>Location
                                </MenuItem>
                                : null}
                            {!selectedActivity ?
                                <MenuItem
                                    key={'subloc_opt'}
                                    value='activity__sub_location__name'>Sub Location
                                </MenuItem>
                                : null}
                            {!selectedActivity ?
                                <MenuItem
                                    key={'created_by_opt'}
                                    value='activity__created_by__email'>System User
                                </MenuItem>
                                : null}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className='p-3'>
                <HighchartsComponent options={ChartData} />
            </div>
        </Card >
    );
}
export default MainGraph;
