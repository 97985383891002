// export const APP_URL = 'http://127.0.0.1:8000';

export const APP_URL = 'http://sitediary2.appspot.com';
// export const APP_URL = 'http://sitediary-sitediary-dot-sitediary2.appspot.com';

// export const APP_URL = 'http://sitediary.cerebrohk.com/';

// export const APP_URL = 'http://uch-demo-sitediary-dot-sitediary2.appspot.com';
// few@#$df%Fsf
// few@#$df%Fsfy

// sophitia.ma@buildking.hk
// YpL5a7
// nic.hung@buildking.hk
// Koe7ly
// brian.leung@buildking.hk
// Gc1pu2
//


// http://uch-demo-sitediary-dot-sitediary2.appspot.com
// mankit.lam@buildking.hk
// 3DcdcK5MMPyS3xBN
// wilson.kwan@buildking.hk
// r24a8N3XE23QkxYD
// ripple.chow@buildking.hk
// uQm4vbJhhujbNR6S


