export function UpdateField(value) {
  return {
    'type': 'UPDATEFIELD',
    'payload': value
  };
}
export function PreviewPDF() {
  return {
    'type': 'PREVIEWPDF',
  };
}
export function ClosePreview() {
  return {
    'type': 'CLOSEPREVIEW',
  };
}
export function AddAttachment(value) {
  return {
    'type': 'ADDATTACHMENT',
    'payload': value
  }
}
