export function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

//seperator e.g. - or /
export function dateToString(date, seperator){
  return (date.getFullYear()) +
  seperator +  (date.getMonth()+1) +
  seperator +  date.getDate();
}

export function dateTimeToString(date){
    const seperator = '-'
    return (date.getFullYear()) +
    seperator +  (date.getMonth()+1) +
    seperator +  date.getDate() +
    ':' + date.getTime()

}

export function downloadFile(blobData, fileType, fileName){
    var newBlob = new Blob([blobData], {type: fileType});
    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}