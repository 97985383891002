import React, {useEffect, useState, useRef} from "react";
import CanvasDraw from "react-canvas-draw";
import {GF527A} from 'icons/GF527A';
import {Page, Text, Document, Image as PDFImage, BlobProvider} from '@react-pdf/renderer';
import {Document as VDoc, Page as VPage} from "react-pdf";

function GF527APDF(props) {
  // const isMobOrTab = useIsMobileOrTablet();
  // write a text
  const {setBlob, formData, signature} = props;
  const canvas = useRef();
  let ctx = null;
  const [isReady, setIsReady] = useState(false);
  const [signatureReady, setSignatureReady] = useState(false);
  const [image,setImage] = useState('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAAAAAA6fptVAAAACklEQVR4nGP6DwABBQECz6AuzQAAAABJRU5ErkJggg==')
  const writeText = (info, style = {}) => {
    const { text, x, y } = info;
    const {fontSize = 20, fontFamily = 'Arial', color = 'black', textAlign = 'left', textBaseline = 'top'} = style;
    if(text) {
        ctx.beginPath();
        ctx.font = fontSize + 'px ' + fontFamily;
        ctx.textAlign = textAlign;
        ctx.textBaseline = textBaseline;
        ctx.fillStyle = color;
        ctx.fillText(text, x, y);
        ctx.stroke();
    }
  }

  const imageDoc = () => (<Document>
    <Page size="A4">
      <PDFImage src={image}></PDFImage>
      <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'438', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.manual_workers}</Text>
      <Text style={{position:'absolute', zIndex:'1000', marginLeft:'363', marginTop:'438', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.manual_workers_fem}</Text>
      <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'456', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.professionals}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'474', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.technicians}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'492', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.other_personnel}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'510', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.total_engaged}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'528', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.employers}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'300', marginTop:'546', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.vacancies}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'363', marginTop:'622', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.number_of_vacancies1}</Text>
        <Text style={{position:'absolute', zIndex:'1000', marginLeft:'363', marginTop:'640', width:'59px', fontSize:'11', color:'black', textAlign:'center'}}>{formData.number_of_vacancies2}</Text>
    </Page>
  </Document>)

  // initialize the canvas context
  useEffect(() => {
    const canvasEle = canvas.current
  canvasEle.width = '1150'
  canvasEle.height = '1600'
  ctx = canvasEle.getContext("2d");

  var imageObj1 = new Image();
  imageObj1.src = GF527A
  imageObj1.onload = function () {
    ctx.drawImage(imageObj1, 50, 50, 210 * 5, 297 * 5)
    const scale = 1
    const line = (38.5) * scale
    const startLine = 112 * scale + 50
    writeText({ text: formData.contract_no, x: 410* scale, y: startLine });
    writeText({ text: formData.contract_title, x: 320* scale, y: startLine + line });
    writeText({ text: formData.contractor_name, x: 240* scale, y: startLine + line * 2});
    writeText({ text: formData.contractor_tel, x: 900* scale, y: startLine + line * 2});
    writeText({ text: formData.address, x: 230* scale, y: startLine + line * 4});
    writeText({ text: formData.authorized_person_1, x: 450* scale, y: startLine + line * 5});
    writeText({ text: formData.authorized_tel_1, x: 900* scale, y: startLine + line * 5});
    writeText({ text: formData.authorized_person_2, x: 490* scale, y: startLine + line * 6});
    writeText({ text: formData.authorized_tel_2, x: 900* scale, y: startLine + line * 6});
    writeText({ text: formData.work_code, x: 410* scale, y: startLine + line * 7});
    writeText({ text: formData.work_stage, x: 190* scale, y: startLine + line * 8});
    writeText({ text: formData.completion, x: 975* scale, y: startLine + line * 8});

    writeText({ text: formData.report_month_year, x: 323* scale, y: 678* scale});
    writeText({ text: formData.last_day, x: 745* scale, y: 678* scale});
    const line2 = (34.5) * scale
    const section3StartLine = 1155 * scale + 50
    writeText({ text: formData.job_title1, x: 100* scale, y: section3StartLine});
    writeText({ text: formData.job_title2, x: 100* scale, y: section3StartLine + line2});

  // writeText({ text: 'Loading', x: 0, y: 0 });
    setImage(canvas.current.toDataURL('data:image/png'))
    setIsReady(true);
  }
    if (signature) {
      var signatureObj = new Image();
      signatureObj.src = signature
      signatureObj.onload = function () {
        ctx.drawImage(signatureObj, 500, 1380, 120, 40)
        setImage(canvas.current.toDataURL('data:image/png'))
        setSignatureReady(true);
      }
    }else{
      setSignatureReady(true);
    }

  }, [signature]);

  return (
      <div style={{width:'600px'}}>
        <canvas ref={canvas} style={{visibility:'hidden', position:'absolute'}}></canvas>
        {isReady && signatureReady
        ?
        <BlobProvider document={imageDoc()}>
          {({ blob, url, loading, error }) => {
            if (blob) {
              setBlob(blob);
            }
            return(
                <div>
                  <VDoc
                      file={blob}
                  >
                    <VPage pageNumber={1}/>
                  </VDoc>
                </div>
            )
          }}
        </BlobProvider>
        :
        null}
      </div>
  );
}

export default GF527APDF;
