import { Button, Typography } from '@material-ui/core';
import React from 'react';
import Add from "@material-ui/icons/Add";

export function GeneralButton(props) {
    return (
        <Button
            className='px-3 py-2'
            fullWidth={props.fullWidth}
            color='primary'
            variant='contained'
            type={props.type}
            style={props.style}
            onClick={props.onClick}
        >
            <Typography variant='body1'>{props.label}</Typography>
        </Button>
    )
}

export function GeneralAddMore(props) {
    return (
        <Button
            onClick={props.onClick}
            color='primary'
            startIcon={<Add />}
            className='transition-none'
        >
            <Typography variant='body2'>ADD MORE</Typography>
        </Button>
    )
}