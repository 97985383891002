import React, { useEffect, useLayoutEffect, useRef, useState, createRef } from "react";
import { Field, FieldArray, reduxForm } from 'redux-form';
import { IconButton, Button, Grid, Paper, TextField, Select, MenuItem, Input, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Divider } from '@material-ui/core'
import 'antd/dist/antd.css';
import { useSelector, useDispatch } from "react-redux";
import Add from "@material-ui/icons/Add";
import { isEmpty } from 'Page/UI_Design/js_utils/general';
import { makeStyles } from "@material-ui/core/styles";
import ActivityFormLaboursRow from 'Page/UI_Design/Activity/ActivityForm/ActivityFormLaboursRow';
import { APP_URL } from 'static';
import { GeneralAddMore } from "components/other-components/Icons";
const required = value => value ? undefined : 'Required'

const useStyles = makeStyles(theme => ({
    // add: theme.styles.miniAddMore.add,
    // addButton: theme.styles.miniAddMore.addButton,
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: 800,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 3),
    },
}));

function getModalStyle() {
    const top = 30;
    const left = 30;

    return {
        top: `${top}%`,
        left: `${left}%`,
        // transform: `translate(-${top}%, -${left}%)`,
    };
}

function ActivityFormLabours({ fields }) {
    const dispatch = useDispatch();
    const labours = useSelector((state) => state.project.labours);
    const labourTrades = useSelector((state) => state.presets.labourTrades);
    const activityCategories = useSelector((state) => state.presets.activityCategories);
    const companies = useSelector((state) => state.presets.companies);
    const report = useSelector((state) => state.project.report);
    const copyLabour = useSelector((state) => state.project.copyLabour);
    const formData = useSelector((state) => state.form);
    const [staffSelectList, setSelect] = useState([]);
    // const [testData, setTest] = useState([{'eng_name':'asd', 'reg_no':'asd', 'id':'21'}])
    // value={formData.report.values.labours[index].staff
    const classes = useStyles();
    useEffect(() => {
        if (!isEmpty(copyLabour)) {
            let staff = []
            // console.log(copyLabour.staff)
            // copyLabour.staff.map((a)=>staff.push(a.id));
            // console.log(staff);
            fields.push({
                no: copyLabour.no,
                mc_sc: copyLabour.mc_sc ? copyLabour.mc_sc.id : null,
                trade: copyLabour.trade ? copyLabour.trade.id : null,
                staff: copyLabour.staff
            });
            dispatch({ type: 'COPYLABOUR', payload: {} });
        }
    }, [copyLabour]);

    return (
        <Grid item xs={9} container spacing={4}>
            {fields.map((labour, index) =>
                <ActivityFormLaboursRow fields={fields} labour={labour} index={index} key={index + 'asd'} />
            )}
            {/* <Button onClick={() => fields.push({ staff: [] })} className={classes.addButton}><Add className={classes.add} />ADD MORE</Button> */}
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={10}>
                    <GeneralAddMore onClick={() => fields.push({ staff: [] })} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ActivityFormLabours;