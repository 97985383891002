import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Grid, MenuItem, Paper, Select,
    Typography
} from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel } from "@material-ui/core";
import { Edit } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { APP_URL } from "../../../static";
import { useBorderSelectStyles } from '@mui-treasury/styles/select/border';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FrameSelectBox } from "../../../components/layout-components/FrameSelectMenu";
import { WrapperBox } from "../../../components/layout-components/Content";
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import {BackEndRequestArrayBuffer, defaultHeaders} from "../../../actions/APICalls";
import {GetMonthlyReportList} from "../../../actions/MonthlyReport";

const useStyles = makeStyles(theme => ({
    // title: theme.styles.font.primary,
    // frame: theme.styles.page.frame,
    // content: theme.styles.page.content,
}))

function Index() {
    const classes = useStyles();
    const navigate = useNavigate();
    const navDate = useSelector((state) => state.project.selectDateTo);
    const selectedProject = useSelector((state) => state.project.selectedProject);
    // const monthlyReports = [{'date':'Oct 2020'}, {'date':'Sept 2020'}, {'date':'Aug 2020'}]
    const [monthlyReports, setReports] = useState([]);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const borderSelectClasses = useBorderSelectStyles();
    const today = new Date();
    let year_array = []
    for (let i = 2019; i <= today.getFullYear(); i++) {
        year_array.push(i);
    }
    const [selectValue, setSelectOption] = useState((navDate.getFullYear().toString()))

    const iconComponent = (props) => {
        return (
            <ExpandMoreIcon className={props.className + " " + borderSelectClasses.icon} />
        )
    };
    //
    function download(report, version) {
        // if (report.GF527A_file != null){
        //     const url = window.URL.createObjectURL(report.GF527A_file);
        //     const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'Form527.xlsx');
        //         document.body.appendChild(link);
        //         link.click();
        // }
        // else {
        const data = {'id': report.id}
        BackEndRequestArrayBuffer('post', '/site/gen_monthly_report/GF527'+version+'/', defaultHeaders, data).then((response)=>{
                var newBlob = new Blob([response.data], {type: "application/vnd.ms-excel"});
                // axios({url:'https://v2.convertapi.com/convert/xls/to/pdf?Secret=LBaZKkDHmZA3sVKV&StoreFile=true', method:'post', data:newBlob, 'responseType': 'arraybuffer'}).then((response)=>{console.log(response.data)})
                const url = window.URL.createObjectURL(newBlob);
                const link = document.createElement('a');
                link.href = url;
                if (version == 'A') {
                    link.setAttribute('download', 'Form527' + version + '.pdf');
                }
                else {
                    link.setAttribute('download', 'Form527' + version + '.xlsx');
                }
                document.body.appendChild(link);
                link.click();
            })
        // }
    }

    useEffect(() => {
        let params = '?project=' + selectedProject.id + '&year=' + selectValue;
        console.log(params)
        GetMonthlyReportList(params).then((resp) => {
            console.log(resp)
            setReports(resp.data.results)
        })
    }, [selectedProject, selectValue])

    return (
        <Grid container spacing={3}>
            <Grid item xs={10}>
                <span className='font-size-lg font-weight-bold'>Monthly Report</span>
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
                <FrameSelectBox labelId='SelectOpt' label={'Year'} onChange={(e) => setSelectOption(e.target.value)} value={selectValue}>
                    {year_array.map((year, i) => (
                        <MenuItem
                            key={year.toString()}
                            value={year.toString()}>{year.toString()}
                        </MenuItem>
                    ))}
                </FrameSelectBox>
            </Grid>

            <Grid container xs={12}>
                <Paper className='w-100'>
                    <Grid container className='p-4'>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: '500' }}>
                                Monthly Return of Site Labour Deployment and Wage Rates <br /> for Construction Works <span className='text-primary'>(GF527)</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: 'center' }}>
                            <Typography style={{ fontWeight: '500' }}>
                                Return on Construction Site Employment <span className='text-primary'>(GF527A)</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    {monthlyReports.map((report, i) => (
                        <>
                            <Divider />
                            <Grid container>
                                <Grid container className='p-4'>
                                    <Grid item xs={2}>
                                        <Typography>{monthNames[report.month - 1]} {selectValue}</Typography>
                                    </Grid>
                                    <Grid item xs={5} className='text-center'>
                                        <GetAppIcon onClick={() => download(report, '')} color='primary' fontSize='24'/>
                                    </Grid>
                                    <Grid item xs={5} className='text-center'>
                                        {report.GF527A_file ?
                                            <svg onClick={() => download(report, 'A')} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" /></svg>
                                            :
                                            <EditIcon onClick={() => navigate('/app/construction/form/' + report.id)} color='primary' fontSize='24' />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </Paper>
            </Grid>

        </Grid>
    );
}
export default Index;


//
//class ProjectReport(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE)
//    date = models.DateTimeField()
//    warning_signal = models.CharField(max_length=100)
//    rainfall = models.CharField(max_length=100)
//    weather_am = models.CharField(max_length=100)
//    weather_pm = models.CharField(max_length=100)
//
//class Plant(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='plants') #May not be dependent on Project
//    type = models.CharField(max_length=100)
//    type_id = models.CharField(max_length=100)
//
//class Location(models.Model):
//    project = models.ForeignKey(Project, on_delete=models.CASCADE, related_name='locations')
//    name = models.CharField(max_length=100)
//
//class SubLocation(models.Model):
//    location = models.ForeignKey(Location, on_delete=models.CASCADE, related_name='sub_locations')
//    name = models.CharField(max_length=100)