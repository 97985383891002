import React, { Fragment } from 'react';
import Avatar from "@material-ui/core/Avatar";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import Grid from "@material-ui/core/Grid";
import { Box, Button, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { makeStyles } from "@material-ui/core/styles";
// import {logout} from "../../../actions/auth";
import Switch from '@material-ui/core/Switch';
import clsx from "clsx";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    avatar: {
        marginLeft: '10px',
        cursor: 'pointer',
        width: 40,
        height: 40
    },
    dividers: {
        color: '#D3D3D3'
    },
    user_level: {
        textTransform: "uppercase",
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
        position: "absolute",
        left: '1rem',
        top: '1rem'
    },

    user_level_icon: {
        color: theme.palette.primary.contrastText,
    },
    user_profile: {
        position: 'relative',
        textAlign: 'center',
        padding: '1.5rem 1rem 1rem',
    },
    user_avatar: {
        textTransform: "uppercase",
        margin: '0 auto 1rem',
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
    avatar_control_btn: {
        color: theme.palette.primary.contrastText,
        '&:focus': {
            outline: "none",
            boxShadow: "none"
        }
    },
    user_info: {
        marginBottom: '1rem'
    },
    username: {
        color: theme.palette.primary.contrastText,
        textTransform: "uppercase"
    },
    user_title: {
        // color: theme.palette.primary.light
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
}));

export default function UserProfile(props) {
    const full_name = useSelector(state => state.auth.full_name);
    const user = {
        avatar: '/static/images/avatars/avatar_6.png',
        jobTitle: 'System Developer',
        name: full_name
    };
    const classes = useStyles()
    const dispatch = useDispatch();
    const adminMode = useSelector((state) => state.nav.adminMode);
    // const user = useSelector(state => state.auth);
    const logoutAction = () => {
        // dispatch(logout());
    };
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    return (
        <div className={classes.user_profile}>
            <Grid container justifiy='center' alignItems='center' spacing={1}>
                <Grid item xs={3}>
                    <Avatar
                        className={classes.avatar}
                        component={RouterLink}
                        src={user.avatar}
                        to="/app/account"
                    />
                </Grid>
                <Grid item xs={9}>
                    <Typography
                        style={{ textAlign: 'left', color: '#fff', fontWeight: '600' }}
                    >
                        {user.name}
                    </Typography>
                    <Typography
                        style={{ textAlign: 'left', color: '#fff' }}
                    >
                        {user.jobTitle}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={6}>
                    <Typography
                        style={{ color: '#AFA4C8', textAlign: 'left', marginTop: '5px' }}
                        color="textSecondary"
                        variant="body2"
                    >
                        Admin Mode
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Switch
                        color="primary"
                        checked={adminMode}
                        onChange={(e) => { dispatch({ 'type': 'SETMODE', 'payload': e.target.checked }) }}
                        inputProps={{ 'aria-label': 'admin mode' }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}