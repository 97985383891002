import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import ActivityList from 'Page/UI_Design/Activity/ActivityList';
import ReportForm from 'Page/UI_Design/DailyReport/ReportForm';
import ReportView from 'Page/UI_Design/DailyReport/ReportView';
import Report from 'Page/UI_Design/DailyReport';
import Dashboard from 'Page/UI_Design/Dashboard';
import Admin from 'Page/UI_Design/Presets';
import ActivityForm from "./Page/UI_Design/Activity/ActivityForm";
import MonthlyReport from "Page/UI_Design/MonthlyReport";
import Attendance from "./Page/UI_Design/Attendance";
import CSVReader from "./Page/UI_Design/Attendance/ImportAttendance";
import SiteStaff from "./Page/UI_Design/Staff";
import ConstructionForm from 'Page/UI_Design/MonthlyReport/ConstructionForm';
import PDF from 'Page/old/PDFDoc/PDF';
import ImageLab from 'Page/UI_Design/temp/ImageLab';
import Approve from 'Page/UI_Design/Activity/Approve';
import Endorsement from 'Page/UI_Design/DailyReport/Endorsement';
//
const routes = [
    {
        path: 'app',
        element: <DashboardLayout children={<span></span>} />,
        children: [
            { path: 'lab', element: <CSVReader /> },
            { path: 'imageLab', element: <ImageLab /> },
            { path: 'pdf', element: <PDF /> },
            //      { path: 'staff', element: <StaffList /> },
            //      { path: 'staff/:id', element: <Staff />},
            //      { path: 'staff/add', element: <AddStaffFormik />},
            //      { path: 'attendance/add', element: <AddAttendance />},
            //      { path: 'pdf', element: <PDFDoc />},
            //      { path: 'excelrender', element: <RnDRenderer />},
            //      { path: 'excelview', element: <ExcelView />},
            //      { path: 'excelexport', element: <RnDExport />},
            //      { path: 'excelsheet', element: <RnDSpreadSheet />},
            //      { path: 'jexcel', element: <Jexcel />},
            //      { path: 'grid', element: <RnDSpreadSheetGrid />},
            //      { path: 'download', element: <Latest />},
            //      { path: 'diary1', element: <Diary1 />},
            //      { path: 'diary2', element: <Diary2 />},
            //      { path: 'activity/reports/:id', element: <ActivityList />},
            //       { path: 'activity/list', element: <ActivityList />},
            //      { path: 'diary3editor', element: <Activity />},
            //       { path: 'activity/create', element: <CreateActivity />},
            //       { path: 'activity/create/:id', element: <CreateActivity />},
            //       { path: 'project/list', element: <Project />},
            //       { path: 'project/create', element: <CreateProject />},
            //       { path: 'report/list/:id', element: <ListReport />},
            //       { path: 'report/create/:id', element: <CreateReport />},
            { path: 'report_view/:id', element: <ReportView /> },
            { path: 'report_form/:id', element: <ReportForm /> },
            { path: 'dailyReport', element: <Dashboard /> },
            { path: 'endorsement/daily/:id', element: <Endorsement /> },
            { path: 'user/attendance', element: <Attendance /> },
            { path: 'construction/form/:id', element: <ConstructionForm /> },
            { path: 'act', element: <ActivityList /> },
            { path: 'approve', element: <Approve /> },
            { path: 'admin', element: <Admin /> },
            // { path: 'admin/presets/location', element: <CreatePresetsLocationForm />},
            // { path: 'admin/presets/material', element: <CreatePresetsMaterialForm />},
            // { path: 'admin/presets/plant', element: <CreatePresetsPlantForm />},
            // { path: 'admin/presets/activitycategory', element: <CreatePresetsActivityCategoryForm />},
            // { path: 'admin/presets/company', element: <CreatePresetsCompanyForm />},
            // { path: 'admin/presets/labourtrade', element: <CreatePresetsLabourTradeForm />},
            { path: 'graph', element: <Dashboard /> },
            { path: 'report', element: <Report /> },
            { path: 'monthly_report', element: <MonthlyReport /> },
            { path: 'site_staff', element: <SiteStaff /> },
            { path: 'activity_form/:id', element: <ActivityForm /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '/',
        //    element: <MainLayout />,
        children: [
            // { path: 'login', element: <LoginView /> },
            // { path: 'register', element: <RegisterView /> },
            // { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/app/dailyReport" /> },
            // { path: '*', element: <Navigate to="/404" /> }
        ]
    },
];

export default routes;
